import * as React from 'react';
import styles from './FormText.module.css';

type Props = {
  children: React.ReactNode;
  isTitle?: boolean;
};

const FormText = ({ children, isTitle = false }: Props) => (
  <p className={`${styles.root} ${isTitle ? styles.title : ''}`}>{children}</p>
);

export default FormText;
