import * as React from 'react';
import styles from './NotificationHandlingLog.module.css';
import { DateChip } from '../Tables/Cells';
import Tooltip from '../Tooltip';

type Props = {
  log: any;
  value: React.ReactNode;
  priorityLevel: string;
  isFirstLog: boolean;
  dismissedAt?: string;
};

function NotificationHandlingLog(props: Props) {
  const { log, value, priorityLevel, isFirstLog, dismissedAt } = props;

  return (
    <div className={styles.log}>
      <div>
        <h4
          className={`${styles.title} ${
            isFirstLog ? styles[priorityLevel] : ''
          }`}
        >
          {log.name}
        </h4>
        {log.message && <div className={styles.message}>{log.message}</div>}
      </div>
      <div>{value}</div>
      <div>
        <Tooltip
          arrowDirection="top"
          content={<DateChip value={log.at} isTimeFrom={false} />}
        >
          <DateChip
            extraClassNames={[
              styles.timeTextColor,
              dismissedAt !== null ? styles.timeTextColorFaded : '',
            ]}
            value={log.at}
            isTimeFrom
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default NotificationHandlingLog;
