import * as React from 'react';
import styles from './Badge.module.css';

const Badge: React.FunctionComponent = props => (
  <div className={styles.root}>
    <span className={styles.text}>{props.children}</span>
  </div>
);

export default Badge;
