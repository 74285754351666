import * as React from 'react';
import { UiIcon } from '../../../types/icon';

const ChevronDownTiny = ({ classes = {}, size = 8 }: UiIcon) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={classes.icon}
    width={size}
    height={size}
    viewBox="0 0 8 8"
  >
    <path
      d="M1 2.5c0-.3.2-.5.5-.5.1 0 .3 0 .4.1L4 4.3l2.1-2.1c.2-.2.5-.2.7 0s.2.5 0 .7L4.4 5.3c-.2.2-.5.2-.7 0L1.1 2.8S1 2.6 1 2.5z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronDownTiny;
