import * as React from 'react';
import { Link } from '@reach/router';

import IconUIFactory from '../icons/IconUiFactory';
import classNames from '../../utils/classNames';
import styles from './MenuSidebarItem.module.css';
import * as routes from '../../routes';

const isActive = (to: string, classes: any) => ({
  isCurrent,
  isPartiallyCurrent,
}: any) => {
  if (to === routes.HOME) {
    if (isCurrent) {
      return { className: classNames([classes.navItemLink, classes.active]) };
    }

    return '';
  }

  return isPartiallyCurrent
    ? { className: classNames(classes.navItemLink, classes.active) }
    : '';
};

type Props = {
  classes?: string[];
  to?: string;
  icon?: string;
  onClick?: () => any;
  custom?: any;
};

export const MenuSidebarItem: React.FunctionComponent<Props> = ({
  classes = [],
  to = '',
  icon,
  onClick,
  custom,
}) => (
  <li className={classNames(styles.navItem, ...classes)}>
    {to ? (
      <Link
        to={to}
        className={styles.navItemLink}
        getProps={isActive(to, styles)}
      >
        {custom || <IconUIFactory size={25} id={icon} />}
      </Link>
    ) : (
      <div onClick={onClick} className={styles.navItemLink}>
        {custom || <IconUIFactory size={25} id={icon} />}
      </div>
    )}
  </li>
);

export default MenuSidebarItem;
