import * as React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from './Loader.module.css';

type Props = {
  isLoading?: boolean;
};

const Loader = ({ isLoading = false }: Props) =>
  isLoading ? (
    <div className={styles.root}>
      <LinearProgress className={styles.progress} />
    </div>
  ) : null;

export default Loader;
