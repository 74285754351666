import { Site } from '../types/site';
import { Actions } from '../actions/sites';

const initialState: Site[] = [];

export default (
  state: Site[] = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case Actions.SITES_SUCCESS:
      return [...action.payload];

    case Actions.SITE_SUCCESS:
      return [...state.filter(s => s.id !== action.payload.id), action.payload];

    default: {
      return state;
    }
  }
};
