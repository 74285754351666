import * as React from 'react';
import styles from './NativeSelectField.module.css';
import ChevronUpTiny from '../../icons/ui/ChevronUpTiny';
import ChevronDownTiny from '../../icons/ui/ChevronDownTiny';

type Props = {
  children: React.ReactNode;
  onChange: any;
  value: string;
  name?: string;
  ariaLabel?: string;
};

function NativeSelectField(props: Props) {
  const { children, onChange, value, ariaLabel = '', ...rest } = props;

  return (
    <div>
      <div className={styles.selectorIcon}>
        <ChevronUpTiny />
        <ChevronDownTiny />
      </div>

      <select
        onChange={onChange}
        value={value}
        className={styles.select}
        aria-label={ariaLabel}
        {...rest}
      >
        {children}
      </select>
    </div>
  );
}

export default NativeSelectField;
