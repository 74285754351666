import * as React from 'react';
import { RouteComponentProps } from '@reach/router';

import styles from './MenuNotificationFilterBar.module.css';
import NotificationStatusFilter from './FilterMenus/NotificationTypeFilter';
import {
  NotificationStatus,
  FnSetFilterTimePeriod,
  FilterTimePeriod,
} from '../../types/notification';
import DateFilter from './DateFilter';
import SiteFilter from './SiteFilter/SiteFilter';
import BaseFilterBar from './BaseFilterBar';

type Props = {
  setDateFilter: FnSetFilterTimePeriod;
  setSiteFilter: (filterIds: number[]) => void;
  timePeriodFilter: FilterTimePeriod;
  setStatusFilter: (notificationStatus: NotificationStatus) => void;
  notificationStatusFilters: NotificationStatus[];
  notificationSiteIdsFilters: number[];
  notificationTypeFilters: any[];
} & RouteComponentProps;

const MenuNotificationFilterBar = ({
  setStatusFilter,
  setDateFilter,
  setSiteFilter,
  timePeriodFilter,
  notificationStatusFilters,
  notificationSiteIdsFilters = [],
}: Props) => (
  <BaseFilterBar>
    <div className={styles.filterMenu}>
      <NotificationStatusFilter
        setFilter={setStatusFilter}
        notificationStatusFilters={notificationStatusFilters}
      />
      <DateFilter setFilter={setDateFilter} value={timePeriodFilter} />
      <SiteFilter
        initiallySelectedSites={notificationSiteIdsFilters}
        siteIds={notificationSiteIdsFilters}
        setFilter={setSiteFilter}
      />
    </div>
  </BaseFilterBar>
);

export default MenuNotificationFilterBar;
