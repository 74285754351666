import * as React from 'react';
import styles from './StepRollout.module.css';
import classNames from '../../../../utils/classNames';

import Checkbox from '../../../ui/Checkbox/Checkbox';
import { Site } from '../../../../types/site';
import { SentinelTarget, TargetType } from '../../../../types/sentinel';

import { SensorChip } from './TargetChips';
import { Gateway } from '../../../../types/gateway';
import { fetchGateways } from '../../../../actions/gateways';
import Path from '../../../../containers/Path';
import { ResourceType } from '../../../../types/resource';
import RolloutSelector from './RolloutSelector';

type Props = {
  site: Site;
  handleTargetSelect: (sentinelTarget: SentinelTarget) => void;
  handleTargetsSelect: (sentinelTarget: SentinelTarget[]) => void;
  handleTargetDelete: (sentinelTargetIds: number[]) => void;
  handleSetResourceRelation: (arg: number, arg2: number[]) => void;
  selected: number[];
  isCollapsed: boolean;
};

type State = {
  gateways: Gateway[];
  isLoading: boolean;
};

class StepRolloutGateways extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      gateways: [],
      isLoading: true,
    };
  }

  componentDidMount(): void {
    this.onLoad();
  }

  onLoad = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation } = this.props;

    try {
      const { site } = this.props;

      const gateways = await fetchGateways({
        siteIds: [site.id],
      });

      handleSetResourceRelation(site.id, gateways.map((g: Gateway) => g.id));

      this.setState({
        gateways,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  handleTargetSelectClick = (ev: any, item: any) => {
    const { handleTargetSelect } = this.props;
    ev.preventDefault();
    handleTargetSelect({
      targetId: item.id,
      targetType: TargetType.GATEWAY,
    });
  };

  render() {
    const { gateways = [], isLoading } = this.state;
    const {
      site,
      selected,
      isCollapsed,
      handleTargetsSelect,
      handleTargetDelete,
    } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    const selectedBySite = gateways
      ? gateways.reduce(
          (sum, item) => (selected.includes(item.id) ? sum + 1 : sum),
          0
        )
      : 0;
    const total = gateways.length;
    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '35%' }}>Gateways</th>
              <th style={{ width: '25%' }}>{isCollapsed && 'Attached to'}</th>
              <th
                style={{ width: '15%' }}
                className={classNames([styles.actionHeader, styles.action])}
              >
                <RolloutSelector
                  isCollapsed={isCollapsed}
                  selected={selectedBySite}
                  totalSelected={total}
                  onDelete={() =>
                    handleTargetDelete(gateways.map(item => item.id))
                  }
                  onSelect={() =>
                    handleTargetsSelect(
                      gateways.map(item => ({
                        targetId: item.id,
                        targetType: TargetType.GATEWAY,
                      }))
                    )
                  }
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {gateways.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <div>This site has no sensors.</div>
                </td>
              </tr>
            ) : (
              isCollapsed &&
              gateways.map((item: Gateway) => (
                <tr
                  key={`rolloutSensorRow${item.id}`}
                  onClick={ev => this.handleTargetSelectClick(ev, item)}
                  className={styles.filterRow}
                >
                  <td>
                    <SensorChip title={item.title} />
                  </td>
                  <td>
                    <Path
                      resourceId={item.id}
                      resourceType={ResourceType.SPACE}
                    />
                  </td>
                  <td className={styles.action}>
                    <Checkbox
                      checked={selected.includes(item.id)}
                      onChange={ev => this.handleTargetSelectClick(ev, item)}
                      id={`checkbox_${site.id}_${item.id}`}
                      size={16}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default StepRolloutGateways;
