import { Equipment } from '../types/equipment';
import { Actions } from '../actions/equipment';

const initialState: Equipment[] = [];

export default (
  state: Equipment[] = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case Actions.EQUIPMENT_SUCCESS: {
      return [...action.payload];
    }

    case Actions.EQUIPMENT_BY_ID_SUCCESS: {
      console.log(action.payload);

      const index = state.findIndex(e => e.id === action.payload.id);

      return [
        ...state.slice(0, index),
        { ...action.payload, hasFullPath: true },
        ...state.slice(index + 1),
      ];
    }

    default: {
      return state;
    }
  }
};
