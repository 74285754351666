import * as React from 'react';
import Loadable from 'react-loadable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconFactory } from '../../types/icon';

const Loading = () => <CircularProgress size={20} />;

const IconAccessTransportationDoor = Loadable({
  loader: () =>
    import('./equipment/AccessTransportation/IconAccessTransportationDoor'),
  loading: Loading,
});

const IconAccessTransportationElevator = Loadable({
  loader: () =>
    import('./equipment/AccessTransportation/IconAccessTransportationElevator'),
  loading: Loading,
});

const IconFoodHolding = Loadable({
  loader: () => import('./equipment/FoodHolding/IconFoodHolding'),
  loading: Loading,
});

const IconFoodProcessing = Loadable({
  loader: () => import('./equipment/FoodProcessing/IconFoodProcessing'),
  loading: Loading,
});

const IconGeneric = Loadable({
  loader: () => import('./equipment/Generic/IconGeneric'),
  loading: Loading,
});

const IconPlug = Loadable({
  loader: () => import('./equipment/Generic/IconPlug'),
  loading: Loading,
});

const IconHVAC = Loadable({
  loader: () => import('./equipment/HVAC/IconHVAC'),
  loading: Loading,
});

const IconAirCurtain = Loadable({
  loader: () => import('./equipment/HVAC/IconAirCurtain'),
  loading: Loading,
});

const IconAirHandler = Loadable({
  loader: () => import('./equipment/HVAC/IconAirHandler'),
  loading: Loading,
});

const IconBoiler = Loadable({
  loader: () => import('./equipment/HVAC/IconBoiler'),
  loading: Loading,
});

const IconCirculationFan = Loadable({
  loader: () => import('./equipment/HVAC/IconCirculationFan'),
  loading: Loading,
});

const IconExhaustAirUnit = Loadable({
  loader: () => import('./equipment/HVAC/IconExhaustAirUnit'),
  loading: Loading,
});

const IconHeater = Loadable({
  loader: () => import('./equipment/HVAC/IconHeater'),
  loading: Loading,
});

const IconHumidifier = Loadable({
  loader: () => import('./equipment/HVAC/IconHumidifier'),
  loading: Loading,
});

const IconRooftopUnit = Loadable({
  loader: () => import('./equipment/HVAC/IconRooftopUnit'),
  loading: Loading,
});

const IconSplitUnit = Loadable({
  loader: () => import('./equipment/HVAC/IconSplitUnit'),
  loading: Loading,
});

const IconVAV = Loadable({
  loader: () => import('./equipment/HVAC/IconVAV'),
  loading: Loading,
});

const IconVentilationFan = Loadable({
  loader: () => import('./equipment/HVAC/IconVentilationFan'),
  loading: Loading,
});

const IconComputer = Loadable({
  loader: () => import('./equipment/ItOfficeAv/IconComputer'),
  loading: Loading,
});

const IconDisplay = Loadable({
  loader: () => import('./equipment/ItOfficeAv/IconDisplay'),
  loading: Loading,
});

const IconNetworkEq = Loadable({
  loader: () => import('./equipment/ItOfficeAv/IconNetworkEq'),
  loading: Loading,
});

const IconPhoneSystem = Loadable({
  loader: () => import('./equipment/ItOfficeAv/IconPhoneSystem'),
  loading: Loading,
});

const IconProjector = Loadable({
  loader: () => import('./equipment/ItOfficeAv/IconProjector'),
  loading: Loading,
});

const IconItOfficeAv = Loadable({
  loader: () => import('./equipment/ItOfficeAv/IconItOfficeAv'),
  loading: Loading,
});

const IconLighting = Loadable({
  loader: () => import('./equipment/Lighting/IconLighting'),
  loading: Loading,
});

const IconAmbLighting = Loadable({
  loader: () => import('./equipment/Lighting/IconAmbLighting'),
  loading: Loading,
});

const IconRefrigeration = Loadable({
  loader: () => import('./equipment/Refrigeration/IconRefrigeration'),
  loading: Loading,
});

const IconWalkInFreezer = Loadable({
  loader: () => import('./equipment/Refrigeration/IconWalkInFreezer'),
  loading: Loading,
});

const IconFreezer = Loadable({
  loader: () => import('./equipment/Refrigeration/IconFreezer'),
  loading: Loading,
});

const IconIceMachine = Loadable({
  loader: () => import('./equipment/Refrigeration/IconIceMachine'),
  loading: Loading,
});

const IconRefrigerator = Loadable({
  loader: () => import('./equipment/Refrigeration/IconRefrigerator'),
  loading: Loading,
});

const IconWalkInCooler = Loadable({
  loader: () => import('./equipment/Refrigeration/IconWalkInCooler'),
  loading: Loading,
});

const IconSelfService = Loadable({
  loader: () => import('./equipment/SelfService/IconSelfService'),
  loading: Loading,
});

const IconAtm = Loadable({
  loader: () => import('./equipment/SelfService/IconAtm'),
  loading: Loading,
});

const IconDrinkDispenser = Loadable({
  loader: () => import('./equipment/SelfService/IconDrinkDispenser'),
  loading: Loading,
});

const IconFuelDispenser = Loadable({
  loader: () => import('./equipment/SelfService/IconFuelDispenser'),
  loading: Loading,
});

const IconPaymentMachine = Loadable({
  loader: () => import('./equipment/SelfService/IconPaymentMachine'),
  loading: Loading,
});

const IconDoor = Loadable({
  loader: () => import('./sensors/IconDoorSensor'),
  loading: Loading,
});

export default ({ classes, size, id }: IconFactory) => {
  switch (id) {
    case 'equipmentAccessTransportation': // Group
    case 'equipmentAccessTransportationDoor':
      return <IconAccessTransportationDoor classes={classes} size={size} />;
    case 'equipmentAccessTransportationElevator':
      return <IconAccessTransportationElevator classes={classes} size={size} />;
    case 'equipmentLighting':
      return <IconLighting classes={classes} size={size} />;
    case 'equipmentItOfficeAv':
      return <IconItOfficeAv classes={classes} size={size} />;
    case 'equipmentAmbLighting':
      return <IconAmbLighting classes={classes} size={size} />;
    case 'equipmentFoodHolding':
      return <IconFoodHolding classes={classes} size={size} />;
    case 'equipmentFoodProcessing':
      return <IconFoodProcessing classes={classes} size={size} />;
    case 'equipmentCirculationFan':
      return <IconCirculationFan classes={classes} size={size} />;
    case 'equipmentVentilationFan':
      return <IconVentilationFan classes={classes} size={size} />;
    case 'equipmentPlug':
      return <IconPlug classes={classes} size={size} />;
    case 'equipmentHvac':
      return <IconHVAC classes={classes} size={size} />;
    case 'equipmentAirCurtain':
      return <IconAirCurtain classes={classes} size={size} />;
    case 'equipmentAirHandler':
      return <IconAirHandler classes={classes} size={size} />;
    case 'equipmentBoiler':
      return <IconBoiler classes={classes} size={size} />;
    case 'equipmentExhaustAirUnit':
      return <IconExhaustAirUnit classes={classes} size={size} />;
    case 'equipmentHeater':
      return <IconHeater classes={classes} size={size} />;
    case 'equipmentHumidifier':
      return <IconHumidifier classes={classes} size={size} />;
    case 'equipmentRooftopUnit':
      return <IconRooftopUnit classes={classes} size={size} />;
    case 'equipmentSplitUnit':
      return <IconSplitUnit classes={classes} size={size} />;
    case 'equipmentVAV':
      return <IconVAV classes={classes} size={size} />;
    case 'equipmentComputer':
      return <IconComputer classes={classes} size={size} />;
    case 'equipmentDisplay':
      return <IconDisplay classes={classes} size={size} />;
    case 'equipmentNetworkEq':
      return <IconNetworkEq classes={classes} size={size} />;
    case 'equipmentPhoneSystem':
      return <IconPhoneSystem classes={classes} size={size} />;
    case 'equipmentProjector':
      return <IconProjector classes={classes} size={size} />;
    case 'equipmentRefrigeration':
      return <IconRefrigeration classes={classes} size={size} />;
    case 'equipmentWalkInFreezer':
      return <IconWalkInFreezer classes={classes} size={size} />;
    case 'equipmentFreezer':
      return <IconFreezer classes={classes} size={size} />;
    case 'equipmentIceMachine':
      return <IconIceMachine classes={classes} size={size} />;
    case 'equipmentRefrigerator':
      return <IconRefrigerator classes={classes} size={size} />;
    case 'equipmentWalkInCooler':
      return <IconWalkInCooler classes={classes} size={size} />;
    case 'equipmentSelfService':
    case 'equipmentSelfServiceEquipment':
      return <IconSelfService classes={classes} size={size} />;
    case 'equipmentAtm':
      return <IconAtm classes={classes} size={size} />;
    case 'equipmentDrinkDispenser':
      return <IconDrinkDispenser classes={classes} size={size} />;
    case 'equipmentFuelDispenser':
      return <IconFuelDispenser classes={classes} size={size} />;
    case 'equipmentPaymentMachine':
      return <IconPaymentMachine classes={classes} size={size} />;
    case 'equipmentDoor':
      return <IconDoor classes={classes} size={size} />;
    default:
      return <IconGeneric classes={classes} size={size} />;
  }
};
