import React from 'react';

import classNames from '../../../utils/classNames';
import { deriveDateValue } from '../../../utils/dates';

type Props = {
  value?: string;
  isTimeFrom?: boolean;
  prefixText?: string;
  suffixText?: string;
  extraClassNames?: string[];
};

const DateChip = ({
  value,
  prefixText = '',
  suffixText = '',
  isTimeFrom = false,
  extraClassNames = [],
}: Props) => (
  <span className={classNames(...extraClassNames)}>
    {`${prefixText} ${deriveDateValue(value, isTimeFrom)} ${suffixText}`}
  </span>
);

export default DateChip;
