import * as React from 'react';
import { connect } from 'react-redux';
import { openDialog } from '../../actions/dialog';
import Button from '../../components/ui/Button';
import { Dialog } from '../../types/dialog';

type Props = {
  children: React.ReactNode;
  secondary?: boolean;
  dialog: Dialog;
  openDialog: (dialog: Dialog) => void;
  size?: any;
  extraClassName?: string;
};

const DialogButton: React.FunctionComponent<Props> = ({
  children,
  extraClassName,
  secondary,
  openDialog,
  ...props
}) => (
  <Button
    secondary={secondary}
    onClick={() => openDialog(props.dialog)}
    extraClassName={extraClassName}
  >
    {children}
  </Button>
);

const mapDispatchToProps = {
  openDialog,
};

export default connect(
  null,
  mapDispatchToProps
)(DialogButton);
