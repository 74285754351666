import * as React from 'react';
import styles from './StepRollout.module.css';
import StepRolloutEquipment from './StepRolloutEquipment';
import StepRolloutSpaces from './StepRolloutSpaces';
import StepRolloutSensors from './StepRolloutSensors';
import {
  SentinelTarget,
  SentinelType,
  sentinelTypeToSensorType,
  TargetType,
} from '../../../../types/sentinel';
import { SensorTarget } from '../../../../types/sensors';
import ResourceSelector from '../../ResourceSelector/ResourceSelector';
import { Site } from '../../../../types/site';
import StepTitle from '../StepTitle';
import RolloutSiteInfo from './RolloutSiteInfo';
import classNames from '../../../../utils/classNames';

import { getSitesByTargets } from '../../../../actions/sites';
import StepRolloutGateways from './StepRolloutGateways';
import Checkbox from '../../../ui/Checkbox';

type Props = {
  targets: SentinelTarget[];
  sentinelType: SentinelType;
  onTargetChange: (sentinelTarget: SentinelTarget) => void;
  onTargetsChange: (sentinelTargets: SentinelTarget[]) => void;
  onTargetsDelete: (targets: number[]) => void;
  targetTypes: TargetType[];
};

type State = {
  sites: Site[];
  sitesExpanded: number[];
  isLoading: boolean;
  siteGatewayRelation: Map<number, number[]>;
  siteSensorRelations: Map<number, number[]>;
  siteSpaceRelations: Map<number, number[]>;
  siteEquipmentRelations: Map<number, number[]>;
  selectedTargetType: TargetType;
};

class StepRolloutContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sites: [],
      sitesExpanded: [],
      isLoading: false,
      siteGatewayRelation: new Map(),
      siteSensorRelations: new Map(),
      siteSpaceRelations: new Map(),
      siteEquipmentRelations: new Map(),
      selectedTargetType: props.targetTypes[0],
    };
  }

  componentDidMount() {
    this.loadSites();
  }

  async loadSites() {
    const { targets } = this.props;

    if (targets.length === 0) {
      return;
    }

    this.setState({ isLoading: true });

    const sites = await getSitesByTargets(targets.map(t => t.targetId));

    this.setState({
      isLoading: false,
      sites,
    });
  }

  handleFilterTargetType: React.ChangeEventHandler<HTMLInputElement> = ev =>
    this.setState({ selectedTargetType: ev.currentTarget.name as TargetType });

  handleSelectSite = (site: Site) => {
    const { sites } = this.state;
    this.setState({
      sites: sites.find(s => s.id === site.id) ? sites : [...sites, site],
    });
  };

  handleCollapseSite = (siteId: number) =>
    this.setState({
      sitesExpanded: this.state.sitesExpanded.find(id => id === siteId)
        ? this.state.sitesExpanded.filter(id => id !== siteId)
        : [...this.state.sitesExpanded, siteId],
    });

  handleSetSiteEquipmentRelation = (siteId: number, resourceIds: number[]) =>
    this.setState(prevState => ({
      siteEquipmentRelations: prevState.siteEquipmentRelations.set(
        siteId,
        resourceIds
      ),
    }));

  handleSetSiteSpaceRelation = (siteId: number, resourceIds: number[]) =>
    this.setState(prevState => ({
      siteSpaceRelations: prevState.siteSpaceRelations.set(siteId, resourceIds),
    }));

  handleSetSiteSensorRelation = (siteId: number, resourceIds: number[]) =>
    this.setState(prevState => ({
      siteSensorRelations: prevState.siteSensorRelations.set(
        siteId,
        resourceIds
      ),
    }));

  handleSetSiteGatewayRelation = (siteId: number, resourceIds: number[]) =>
    this.setState(prevState => ({
      siteGatewayRelation: prevState.siteGatewayRelation.set(
        siteId,
        resourceIds
      ),
    }));

  handleRemoveSite = (siteId: number) => {
    this.setState({ isLoading: true });
    const relationIds = [
      ...(this.state.siteEquipmentRelations.get(siteId) || []),
      ...(this.state.siteSpaceRelations.get(siteId) || []),
      ...(this.state.siteSensorRelations.get(siteId) || []),
      ...(this.state.siteGatewayRelation.get(siteId) || []),
    ];

    // const targetIds = this.props.targets.map((t: SentinelTarget) => t.targetId);
    this.props.onTargetsDelete(relationIds);
    this.setState(prevState => ({
      sites: prevState.sites.filter(s => s.id !== siteId),
      isLoading: false,
    }));

    if (this.state.sitesExpanded.includes(siteId)) {
      this.handleCollapseSite(siteId);
    }
  };

  render() {
    const { targets, sentinelType, targetTypes } = this.props;
    const { sites, sitesExpanded, isLoading, selectedTargetType } = this.state;

    return (
      <div>
        <div className={styles.actionContainer}>
          <ResourceSelector
            sentinelType={sentinelType}
            sensorTargetTypes={Object.values(SensorTarget)}
            handleSelectSite={this.handleSelectSite}
            handleRemoveSite={this.handleRemoveSite}
            selectedSites={new Set(sites.map(s => s.id))}
          />

          <div className={styles.actionTargetType}>
            {targetTypes.map(t => (
              <Checkbox
                key={t}
                id={t}
                name={t}
                checked={selectedTargetType === t}
                onChange={this.handleFilterTargetType}
                label={t.toLowerCase()}
              />
            ))}
          </div>
        </div>

        {isLoading && (
          <div className={styles.messageContainer}>
            ...loading configuration
          </div>
        )}

        {isLoading === false && sites.length === 0 && (
          <div className={styles.messageContainer}>
            <StepTitle>
              Please search for sites to roll out their{' '}
              {targetTypes.map(t => t.toLowerCase()).join(', ')}
            </StepTitle>

            <div>
              <span>No sites selected</span>
            </div>
          </div>
        )}

        {sites.map((site: Site) => {
          const isCollapsed = sitesExpanded.includes(site.id);

          return (
            <div
              key={`StepRolloutContainerSite${site.id}`}
              className={classNames(
                styles.siteListItem,
                isCollapsed
                  ? styles.siteListItemNotCollapsed
                  : styles.siteListItemCollapsed
              )}
              style={{ gridTemplateColumns: `20rem 1fr` }}
            >
              <RolloutSiteInfo
                site={site}
                isCollapsed={isCollapsed}
                handleCollapseSite={this.handleCollapseSite}
                handleRemoveSite={this.handleRemoveSite}
              />

              {selectedTargetType === TargetType.EQUIPMENT && (
                <StepRolloutEquipment
                  sensorTypes={sentinelTypeToSensorType(sentinelType)}
                  isCollapsed={isCollapsed}
                  site={site}
                  selected={targets
                    .filter(t => t.targetType === TargetType.EQUIPMENT)
                    .map(t => t.targetId)}
                  handleTargetDelete={this.props.onTargetsDelete}
                  handleTargetSelect={this.props.onTargetChange}
                  handleTargetsSelect={this.props.onTargetsChange}
                  handleSetResourceRelation={
                    this.handleSetSiteEquipmentRelation
                  }
                />
              )}

              {selectedTargetType === TargetType.SPACE && (
                <StepRolloutSpaces
                  sensorTypes={sentinelTypeToSensorType(sentinelType)}
                  isCollapsed={isCollapsed}
                  site={site}
                  selected={targets
                    .filter(t => t.targetType === TargetType.SPACE)
                    .map(t => t.targetId)}
                  handleTargetDelete={this.props.onTargetsDelete}
                  handleTargetSelect={this.props.onTargetChange}
                  handleTargetsSelect={this.props.onTargetsChange}
                  handleSetResourceRelation={this.handleSetSiteSpaceRelation}
                />
              )}

              {selectedTargetType === TargetType.SENSOR && (
                <StepRolloutSensors
                  sensorTypes={sentinelTypeToSensorType(sentinelType)}
                  isCollapsed={isCollapsed}
                  site={site}
                  selected={targets
                    .filter(t => t.targetType === TargetType.SENSOR)
                    .map(t => t.targetId)}
                  handleTargetDelete={this.props.onTargetsDelete}
                  handleTargetSelect={this.props.onTargetChange}
                  handleTargetsSelect={this.props.onTargetsChange}
                  handleSetResourceRelation={this.handleSetSiteSensorRelation}
                />
              )}

              {selectedTargetType === TargetType.GATEWAY && (
                <StepRolloutGateways
                  isCollapsed={isCollapsed}
                  site={site}
                  selected={targets
                    .filter(t => t.targetType === TargetType.GATEWAY)
                    .map(t => t.targetId)}
                  handleTargetDelete={this.props.onTargetsDelete}
                  handleTargetSelect={this.props.onTargetChange}
                  handleTargetsSelect={this.props.onTargetsChange}
                  handleSetResourceRelation={this.handleSetSiteGatewayRelation}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default StepRolloutContainer;
