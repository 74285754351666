export enum Service {
  iam = 'SERVICE_IAM',
  sentinel = 'SERVICE_SENTINELS',
  tsd = 'SERVICE_TSD_ROOT',
}

export function getConfig() {
  const serviceUrls = {
    [Service.iam]:
      process.env.REACT_APP_SERVICE_IAM_ROOT ||
      'https://iam.staging.energybox.com',
    [Service.sentinel]:
      process.env.REACT_APP_SERVICE_SENTINEL_ROOT ||
      'https://sentinel.staging.energybox.com',
    [Service.tsd]:
      process.env.REACT_APP_SERVICE_TSD_ROOT ||
      'https://eb-api-staging.meso.net',
  };

  if (
    process.env.ENVIRONMENT === 'production' &&
    Object.values(serviceUrls).includes('')
  ) {
    throw new Error('env vars incomplete');
  }

  return { serviceUrls };
}

const config = getConfig();

export function getServiceUrl(service): string {
  switch (service) {
    case Service.sentinel:
      return config.serviceUrls.SERVICE_SENTINELS;
    case Service.tsd:
      return config.serviceUrls.SERVICE_TSD_ROOT;
    default:
      return config.serviceUrls.SERVICE_IAM;
  }
}
