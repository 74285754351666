import React from 'react';
import PropTypes from 'prop-types';
import MenuSidebar from '../containers/MenuSidebar/MenuSidebar';

const Navigation = ({ isAuthenticated, classes }) => (
  <nav className={classes.nav}>{isAuthenticated && <MenuSidebar />}</nav>
);

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Navigation;
