import React, { Component } from 'react';
import TextField from '../ui/TextField';
import styles from './MuteSentinelModal.module.css';
import Button from '../ui/Button';
import BaseModal from './BaseModal';
import { Sentinel } from '../../types/sentinel';
import Checkbox from '../ui/Checkbox';
import MuteDurationDropDown from '../MuteDurationDropDown';
import isAfter from 'date-fns/isAfter';

type Props = {
  sentinel: Sentinel;
  onSubmit: any;
  onClose: any;
};

type State = {
  selectedOption: string;
  comment: string;
  view: 'initial' | 'confirm';
  muteDurationOption: 'Mute until' | 'Indefinitely';
  dateInput: string;
  hourInput: string;
  minuteInput: string;
  muteDurationError: boolean;
};

const labels = ['Maintenance Work'];

// TODO THIS IS STILL MISSING THE DATE RANGE COMPONENT
class MuteSentinelModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedOption: '',
      comment: '',
      view: 'initial',
      muteDurationOption: 'Indefinitely', //should be 'Mute until' once muteDuration bug is fixed
      dateInput: '',
      hourInput: '',
      minuteInput: '',
      muteDurationError: false,
    };
  }

  handleSubmit = () => {
    const { selectedOption, comment } = this.state;
    this.props.onSubmit({
      reason: selectedOption === 'comment' ? comment : selectedOption,
      sentinelId: this.props.sentinel.id,
      mutedUntil: this.sanitizedMuteDuration(),
    });
  };

  //TODO: WILL LIKELY NEED TO FORMAT REFERENCE TIME ZONE IN FUTURE
  sanitizedMuteDuration = () => {
    const { muteDurationOption, hourInput, minuteInput } = this.state;
    const sanitizedDate = this.sanitizeDateInput();

    if (muteDurationOption === 'Mute until') {
      // return new Date(sanitizedDate[0], sanitizedDate[1] - 1, sanitizedDate[2], parseInt(hourInput), parseInt(minuteInput)).toISOString()
      return Math.floor(
        new Date(
          sanitizedDate[0],
          sanitizedDate[1] - 1,
          sanitizedDate[2],
          parseInt(hourInput),
          parseInt(minuteInput)
        ).getTime() / 1000.0
      ); //API post request works with epoch format, but not ISO
    } else if (muteDurationOption === 'Indefinitely') {
      return undefined;
    }
    return undefined;
  };

  handleCommentChange = (e: any) => {
    const comment = e.currentTarget.value;
    this.setState({ comment });
  };

  selectOption = (selectedOption: string) => this.setState({ selectedOption });

  switchView = () => {
    this.setState({ view: 'confirm' });
  };

  renderCheckBoxes = (): any =>
    labels.map((label: string) => (
      <Checkbox
        className={styles.borderBottom}
        onChange={() => this.selectOption(label)}
        checked={label === this.state.selectedOption}
        size={20}
        label={label}
        name={label}
        key={label}
      />
    ));

  handleDropDownChange = (e: any) => {
    this.setState({ muteDurationOption: e.target.value } as State);
  };

  handleDateAndTimeInput = (e: any) => {
    this.setState({ [e.target.name]: e.target.value } as State);
  };

  sanitizeDateInput = () => {
    return this.state.dateInput.split('-').map(num => parseInt(num));
  };

  isDateFuture = () => {
    const { hourInput, minuteInput } = this.state;
    let sanitizedDate = this.sanitizeDateInput();

    sanitizedDate.push(parseInt(hourInput), parseInt(minuteInput));

    if (
      isAfter(
        new Date(
          sanitizedDate[0],
          sanitizedDate[1] - 1,
          sanitizedDate[2],
          sanitizedDate[3],
          sanitizedDate[4]
        ),
        new Date()
      )
    ) {
      return true;
    }
    return false;
  };

  isDateValid = () => {
    const { dateInput } = this.state;
    const dateRegex: RegExp = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

    if (dateRegex.test(dateInput)) {
      return true;
    }
    return false;
  };

  isHourValid = () => {
    const { hourInput } = this.state;
    const hourRegex: RegExp = /^([01][0-9]|2[0-3])$/;
    if (hourRegex.test(hourInput)) {
      return true;
    }
    return false;
  };

  isMinuteValid = () => {
    const { minuteInput } = this.state;
    const minuteRegex: RegExp = /^([0-5][0-9])$/;
    if (minuteRegex.test(minuteInput)) {
      return true;
    }
    return false;
  };

  isMuteDurationValid = () => {
    const { muteDurationOption } = this.state;
    if (muteDurationOption === 'Mute until') {
      if (
        this.isDateValid() &&
        this.isDateFuture() &&
        this.isHourValid() &&
        this.isMinuteValid()
      ) {
        this.switchView();
      } else {
        this.setState({ muteDurationError: true });
      }
    } else if (muteDurationOption === 'Indefinitely') {
      this.switchView();
    }
  };

  render() {
    // sentinel, comment, onSubmit, onClose
    const { sentinel, onClose } = this.props;
    const {
      view,
      selectedOption,
      comment,
      muteDurationOption,
      dateInput,
      hourInput,
      minuteInput,
      muteDurationError,
    } = this.state;

    const commentSelected = selectedOption === 'comment';
    const onInitialView = view === 'initial';

    const formIsValid =
      (selectedOption === 'comment' && comment !== '') ||
      labels.includes(selectedOption);

    return (
      <BaseModal
        onClose={onClose}
        title={
          onInitialView
            ? `Mute Notifications for ${sentinel.title}`
            : `The notifications for ${sentinel.title} will be paused.`
        }
      >
        {view !== 'confirm' ? (
          <div>
            <div className={styles.actionGroup}>
              <div className={styles.reasonText}> The reason: </div>
              <div className={styles.radioGroup}>
                {this.renderCheckBoxes()}
                <Checkbox
                  className={styles.checkboxWithComment}
                  onChange={() => this.selectOption('comment')}
                  checked={commentSelected}
                  size={20}
                  name="comment"
                  fullWidthLabel
                  label={
                    <TextField
                      placeholder="Other"
                      value={comment}
                      disabled={!commentSelected}
                      onChange={this.handleCommentChange}
                      fullWidth
                    />
                  }
                />
              </div>
            </div>
            {/* <div>
              <MuteDurationDropDown
                muteDurationOption={muteDurationOption}
                dateInput={dateInput}
                hourInput={hourInput}
                minuteInput={minuteInput}
                handleDropDownChange={this.handleDropDownChange}
                handleDateAndTimeInput={this.handleDateAndTimeInput}
                isDateValid={this.isDateValid}
                isHourValid={this.isHourValid}
                isMinuteValid={this.isMinuteValid}
              />
            </div>
            {muteDurationError ? (
              <div className={styles.muteDurationError}>
                Invalid date and/or time
              </div>
            ) : null} */}
            <div className={styles.buttonGroup}>
              <Button
                disabled={!formIsValid}
                onClick={this.isMuteDurationValid}
              >
                Mute
              </Button>
              <Button onClick={onClose} secondary>
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.buttonGroup}>
              <Button onClick={this.handleSubmit}>OK</Button>
              <Button onClick={onClose} secondary>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </BaseModal>
    );
  }
}

export default MuteSentinelModal;
