import * as React from 'react';
import styles from './StepRollout.module.css';
import IconSensorTypeFactory from '../../../icons/IconSensorTypeFactory';
import IconEquipmentFactory from '../../../icons/IconEquipmentFactory';
import IconSpacesFactory from '../../../icons/IconSpacesFactory';

type Props = {
  id?: string;
  size?: number;
  title: string;
};

export function EquipmentChip(props: Props) {
  const { id = '', size = 16, title } = props;
  return (
    <div className={styles.iconContainer}>
      <IconEquipmentFactory
        id={id} // $FlowFixMe
        size={size}
        classes={{ icon: styles.icon }}
      />
      {title}
    </div>
  );
}

export function SensorChip(props: Props) {
  const { id, size = 16, title } = props;

  return (
    <div className={styles.iconContainer}>
      <IconSensorTypeFactory
        id={id} // $FlowFixMe
        size={size}
        classes={{ icon: styles.icon }}
      />
      {title}
    </div>
  );
}

export function SpaceChip(props: Props) {
  const { id, size = 16, title } = props;

  return (
    <div className={styles.iconContainer}>
      <IconSpacesFactory
        id={id} // $FlowFixMe
        size={size}
        classes={{ icon: styles.icon }}
      />
      {title}
    </div>
  );
}
