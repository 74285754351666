import * as React from 'react';
import styles from './StepScheduleItem.module.css';
import MenuDropdown from '@energybox/react-ui/core/MenuDropdown';
import MenuDropdownItem from '@energybox/react-ui/core/MenuDropdownItem';
import FormText from '../../../ui/FormText';
import Button from '../../../ui/Button/Button';
import PlusIcon from '../../../icons/ui/Plus';
import {
  ScheduleTimeRange,
  SentinelSchedule,
} from '../../../../types/sentinel';
import {
  initialSentinelTimeRange,
  SentinelScheduleType,
  SentinelScheduleWeekDay,
} from '../../../../types/sentinel';
import ScheduleWeekDayButton from './ScheduleWeekDayButton';
import InputField from '../../../ui/InputField';
import NativeSelectField from '../../../ui/NativeSelectField';
import { isWorldTime, isUsTime } from '../../../../utils/time';
import TrashIcon from '../../../icons/ui/Trash';
import FormGroup from '../../../FormGroup';

type Props = {
  schedule: SentinelSchedule;
  handleUpdateSchedule: (schedule: SentinelSchedule) => void;
  handleRemoveSchedule: () => void;
};

export function validateField(from: string, to: string): boolean {
  if (isWorldTime.test(from) && isWorldTime.test(to)) {
    const [fHours, fMinutes] = from.split(':').map(t => parseInt(t, 10));
    const [tHours, tMinutes] = to.split(':').map(t => parseInt(t, 10));

    return fHours * 24 + fMinutes >= tHours * 24 + tMinutes;
  }

  if (isUsTime.test(from) && isUsTime.test(to)) {
    const [fromTime, fAa] = from.split(' ');
    const [toTime, tAa] = to.split(' ');

    const [fHours, fMinutes] = fromTime.split(':').map(t => parseInt(t, 10));
    const [tHours, tMinutes] = toTime.split(':').map(t => parseInt(t, 10));

    return (
      (fAa === 'pm' ? fHours + 12 : fHours) * 24 + fMinutes >=
      (tAa === 'pm' ? tHours + 12 : tHours) * 24 + tMinutes
    );
  }

  return true;
}

function StepScheduleItem(props: Props) {
  const { schedule, handleUpdateSchedule, handleRemoveSchedule } = props;
  const { scheduleType, weekDays } = schedule;

  return (
    <div className={styles.root}>
      <div>
        <FormGroup>
          <NativeSelectField
            value={scheduleType}
            onChange={(ev: any) =>
              handleUpdateSchedule({
                ...schedule,
                scheduleType: ev.target.value,
              })
            }
          >
            <option value={SentinelScheduleType.DAILY}>Daily</option>
            <option value={SentinelScheduleType.WEEKLY}>Weekly</option>
          </NativeSelectField>
        </FormGroup>
        <FormGroup>
          <MenuDropdown>
            <MenuDropdownItem onSelect={() => handleRemoveSchedule()}>
              <span className={styles.iconWithText}>
                <TrashIcon className={styles.deleteIcon} /> Delete time schedule
              </span>
            </MenuDropdownItem>
          </MenuDropdown>
        </FormGroup>
      </div>
      <div>
        {scheduleType === SentinelScheduleType.WEEKLY && (
          <FormGroup>
            {Object.keys(SentinelScheduleWeekDay).map((day: any) => (
              <ScheduleWeekDayButton
                key={`weeklyButton${day}`}
                onClick={() =>
                  handleUpdateSchedule({
                    ...schedule,
                    weekDays: weekDays.includes(day)
                      ? weekDays.filter(d => d !== day)
                      : [...weekDays, day],
                  })
                }
                isActive={weekDays.includes(day)}
                day={day}
              />
            ))}
          </FormGroup>
        )}

        <div className={styles.timeRanges}>
          {schedule.timeRanges.map((range: ScheduleTimeRange, index) => (
            <FormGroup key={`SentinelScheduleItemTimeRange${index.toString()}`}>
              <div className={styles.timeSelect}>
                <div>
                  <InputField
                    type="text"
                    value={range.begin}
                    placeholder="HH:mm"
                    onChange={(ev: any) =>
                      handleUpdateSchedule({
                        ...schedule,
                        timeRanges: schedule.timeRanges.map((r, i) =>
                          i === index
                            ? {
                                begin: ev.target.value,
                                end: range.end,
                              }
                            : r
                        ),
                      })
                    }
                    error={validateField(range.begin, range.end)}
                  />
                </div>
                <div className={styles.timeSelectSeparator}>
                  <FormText>-</FormText>
                </div>
                <div>
                  <InputField
                    type="text"
                    value={range.end}
                    placeholder="HH:mm"
                    onChange={(ev: any) =>
                      handleUpdateSchedule({
                        ...schedule,
                        timeRanges: schedule.timeRanges.map((r, i) =>
                          i === index
                            ? {
                                begin: range.begin,
                                end: ev.target.value,
                              }
                            : r
                        ),
                      })
                    }
                    error={validateField(range.begin, range.end)}
                  />
                </div>
                <div className={styles.timeSelectDelete}>
                  <MenuDropdown>
                    <MenuDropdownItem
                      onSelect={() =>
                        handleUpdateSchedule({
                          ...schedule,
                          timeRanges: schedule.timeRanges.filter(
                            (t, i) => index !== i
                          ),
                        })
                      }
                    >
                      <span className={styles.iconWithText}>
                        <TrashIcon className={styles.deleteIcon} /> Delete time
                        range
                      </span>
                    </MenuDropdownItem>
                  </MenuDropdown>
                </div>
              </div>
            </FormGroup>
          ))}
        </div>
        <FormGroup>
          {schedule.timeRanges.length < 5 && (
            <Button
              size="small"
              secondary
              onClick={() =>
                handleUpdateSchedule({
                  ...schedule,
                  timeRanges: [
                    ...schedule.timeRanges,
                    initialSentinelTimeRange,
                  ],
                })
              }
            >
              <PlusIcon className={styles.addIcon} />
              Add new time range
            </Button>
          )}
        </FormGroup>
      </div>
    </div>
  );
}

export default StepScheduleItem;
