import * as React from 'react';
import { SpaceIcon } from '../../../types/icon';

const IconSize16 = () => (
  <g>
    <path
      fill="currentColor"
      d="M11 6V0H7v1h3v5H8v1h7v8h-4v-2h-1v2H1V7h1V6H1V1h2V0H0v16h16V6z"
    />
    <path fill="currentColor" d="M4 6h2v1H4zM10 9h1v2h-1z" />
  </g>
);

const IconSize32 = () => (
  <g>
    <path
      fill="currentColor"
      d="M32 31H0h32zM17 14h15v1H17zM1 14h2v1H1zM5 14h2v1H5zM9 14h2v1H9zM13 14h2v1h-2z"
    />
    <path
      fill="currentColor"
      d="M31 15h1v17h-1zM21 0h1v14h-1zM0 1h1v31H0zM19 16h1v2h-1zM19 20h1v2h-1zM19 24h1v2h-1zM19 28h1v2h-1z"
    />
    <path
      fill="currentColor"
      d="M0 31h31v1H0zM0 0h8v1H0zM14 0h8v1h-8zM.9 16.9l-.3-.3 2.3-2.4.1.7zM.8 21.8l-.3-.3L7 15h.7zM.6 26.9l-.4-.3L11.8 15h.7z"
    />
    <path
      fill="currentColor"
      d="M.7 31.7l-.4-.4L16.6 15h.7zM5.3 31.9h-.8L19 17.4v.7zM10.5 31.5l-.4-.4 8.9-8.8v.7zM14.9 31.9l-.3-.3 4.4-4.5v.7z"
    />
  </g>
);

const IconSize40 = () => (
  <g>
    <path
      fill="currentColor"
      d="M36 35H4h32zM21 18h15v1H21zM5 18h2v1H5zM9 18h2v1H9zM13 18h2v1h-2zM17 18h2v1h-2z"
    />
    <path
      fill="currentColor"
      d="M35 19h1v17h-1zM25 4h1v14h-1zM4 5h1v31H4zM23 20h1v2h-1zM23 24h1v2h-1zM23 28h1v2h-1zM23 32h1v2h-1z"
    />
    <path
      fill="currentColor"
      d="M4 35h31v1H4zM4 4h8v1H4zM18 4h8v1h-8zM4.9 20.9l-.3-.3 2.3-2.4.1.7zM4.8 25.8l-.3-.3L11 19h.7zM4.6 30.9l-.4-.3L15.8 19h.7z"
    />
    <path
      fill="currentColor"
      d="M4.7 35.7l-.4-.4L20.6 19h.7zM9.3 35.9h-.8L23 21.4v.7zM14.5 35.5l-.4-.4 8.9-8.8v.7zM18.9 35.9l-.3-.3 4.4-4.5v.7z"
    />
    <path fill="#45555F" d="M7 21h22v18H7z" opacity=".1" />
  </g>
);

const getIcon = (size: number) => {
  switch (true) {
    case size < 32:
      return { viewBox: '0 0 16 16', icon: <IconSize16 /> };

    case size < 40:
      return { viewBox: '0 0 32 32', icon: <IconSize32 /> };

    case size === 40:
      return { viewBox: '0 0 40 40', icon: <IconSize40 /> };

    default:
      return { viewBox: '0 0 40 40', icon: <IconSize40 /> };
  }
};

export default ({ classes = {}, size = 40 }: SpaceIcon) => {
  const { viewBox, icon } = getIcon(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
