import * as React from 'react';
import {
  ComparisonType,
  defaultTemperatureParams,
  ParametersTemperature,
} from '../../../../types/sentinel';
import styles from './ConditionTemperature.module.css';
import { toCelsius, toFahrenheit } from '../../../../utils/temperature';
import Radio from '../../../ui/Radio';
import StepConditionItem from './StepConditionItem';
import FormText from '../../../ui/FormText/FormText';
import TextField from '../../../ui/TextField/TextField';
import StepConditionItemInput from './StepConditionItemInput';
import RangeField from '../../../ui/RangeField';
import NativeSelectField from '../../../ui/NativeSelectField';
import { MeasurementSystem } from '../../../../types/shared';

type Props = {
  onValueChange: (name: string, value: number | string | undefined) => void;
  onComparisonTypeChange: (
    comparisonType: string,
    comparisonTypeValue: string
  ) => void;
  parameters?: ParametersTemperature;
  measurementSystem: MeasurementSystem;
};

type State = {
  measurementSystem: MeasurementSystem;
};

const RANGE_MIN = -100;
const RANGE_MAX = 100;

function round(n: number): number {
  return Math.round(n);
}

function enforceBounds(value: number): number {
  if (value > RANGE_MAX) {
    return RANGE_MAX;
  }

  if (value < RANGE_MIN) {
    return RANGE_MIN;
  }

  return value;
}

export function parseInput(fn: (v: number) => number, value: any): any {
  if (value === '' || value === '-') {
    return value;
  }

  if (isNaN(value)) {
    return '';
  }

  return fn(parseFloat(value));
}

function getValueFormatter(type: MeasurementSystem): (value: number) => string {
  switch (type) {
    case MeasurementSystem.metric:
      return v => `${round(v)} °C`;
    case MeasurementSystem.imperial:
      return v => `${round(v)} °F`;
    default:
      return v => v.toString();
  }
}

class ConditionTemperature extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      measurementSystem: MeasurementSystem.metric,
    };
  }

  componentDidMount() {
    const { measurementSystem } = this.props;
    this.setState({ measurementSystem });
  }

  onUnitChange = (ev: React.ChangeEvent<HTMLSelectElement>) =>
    this.setState({ measurementSystem: ev.target.value as MeasurementSystem });

  handleOnValueChange = (name: string) => (
    ev: React.FormEvent<HTMLInputElement>
  ) => {
    const { value } = ev.currentTarget;

    return this.props.onValueChange(
      name,
      enforceBounds(
        this.state.measurementSystem === MeasurementSystem.metric
          ? isNaN(parseInt(value))
            ? value
            : parseInt(value, 10)
          : parseInput(toCelsius, value)
      )
    );
  };

  render() {
    const { measurementSystem } = this.state;
    const {
      onValueChange,
      onComparisonTypeChange,
      parameters = defaultTemperatureParams,
    } = this.props;

    return (
      <>
        <StepConditionItem
          condition={
            <RangeField
              min={
                measurementSystem === MeasurementSystem.metric
                  ? RANGE_MIN
                  : toFahrenheit(RANGE_MIN)
              }
              max={
                measurementSystem === MeasurementSystem.metric
                  ? RANGE_MAX
                  : toFahrenheit(RANGE_MAX)
              }
              step={1}
              value={
                measurementSystem === MeasurementSystem.metric
                  ? parameters.threshold
                  : parseInput(toFahrenheit, parameters.threshold)
              }
              rangeLowerBound={
                measurementSystem === MeasurementSystem.metric
                  ? parameters.rangeLowerBound
                  : parseInput(toFahrenheit, parameters.rangeLowerBound)
              }
              rangeUpperBound={
                measurementSystem === MeasurementSystem.metric
                  ? parameters.rangeUpperBound
                  : parseInput(toFahrenheit, parameters.rangeUpperBound)
              }
              condition={parameters.comparisonType}
              valueFormatterFn={getValueFormatter(measurementSystem)}
              onChange={(name, value) =>
                onValueChange(
                  name,
                  enforceBounds(
                    measurementSystem === MeasurementSystem.metric
                      ? value
                      : toCelsius(value)
                  )
                )
              }
            />
          }
        />

        <StepConditionItem
          condition={
            <div className={styles.checkboxWrapper}>
              <Radio
                id="condition_less_then"
                checked={parameters.comparisonType === ComparisonType.lt}
                onChange={() =>
                  onComparisonTypeChange('comparisonType', ComparisonType.lt)
                }
                label="Less then"
              />
              <Radio
                id="condition_equals"
                checked={parameters.comparisonType === ComparisonType.eq}
                onChange={() =>
                  onComparisonTypeChange('comparisonType', ComparisonType.eq)
                }
                label="Exact"
              />
              <Radio
                id="condition_greater"
                checked={parameters.comparisonType === ComparisonType.gt}
                onChange={() =>
                  onComparisonTypeChange('comparisonType', ComparisonType.gt)
                }
                label="Greater then"
              />
              <Radio
                id="condition_range_inner"
                checked={parameters.comparisonType === ComparisonType.inner}
                onChange={() =>
                  onComparisonTypeChange('comparisonType', ComparisonType.inner)
                }
                label="Range"
              />
              <Radio
                id="condition_range_outer"
                checked={parameters.comparisonType === ComparisonType.outer}
                onChange={() =>
                  onComparisonTypeChange('comparisonType', ComparisonType.outer)
                }
                label="Inverse Range"
              />
            </div>
          }
        />

        <StepConditionItem
          label={<FormText>Scale:</FormText>}
          condition={
            <NativeSelectField
              onChange={this.onUnitChange}
              value={this.state.measurementSystem}
            >
              <option value={MeasurementSystem.imperial}>° F</option>
              <option value={MeasurementSystem.metric}>° C</option>
            </NativeSelectField>
          }
        />

        {parameters.comparisonType === ComparisonType.inner ||
        parameters.comparisonType === ComparisonType.outer ? (
          <>
            <StepConditionItem
              label={<FormText>Temperature lower bound:</FormText>}
              condition={
                <StepConditionItemInput
                  value={
                    <TextField
                      type="number"
                      value={
                        measurementSystem === MeasurementSystem.metric
                          ? parameters.rangeLowerBound
                          : parseInput(toFahrenheit, parameters.rangeLowerBound)
                      }
                      onChange={this.handleOnValueChange('rangeLowerBound')}
                    />
                  }
                  unit={
                    <FormText>
                      {measurementSystem === MeasurementSystem.imperial
                        ? '° F'
                        : '° C'}
                    </FormText>
                  }
                />
              }
            />
            <StepConditionItem
              label={<FormText>Temperature upper bound:</FormText>}
              condition={
                <StepConditionItemInput
                  value={
                    <TextField
                      type="number"
                      value={
                        measurementSystem === MeasurementSystem.metric
                          ? parameters.rangeUpperBound
                          : parseInput(toFahrenheit, parameters.rangeUpperBound)
                      }
                      onChange={this.handleOnValueChange('rangeUpperBound')}
                    />
                  }
                  unit={
                    <FormText>
                      {measurementSystem === MeasurementSystem.imperial
                        ? '° F'
                        : '° C'}
                    </FormText>
                  }
                />
              }
            />
          </>
        ) : (
          <StepConditionItem
            label={<FormText>Temperature:</FormText>}
            condition={
              <StepConditionItemInput
                value={
                  <TextField
                    type="number"
                    value={
                      measurementSystem === MeasurementSystem.metric
                        ? parameters.threshold
                        : parseInput(toFahrenheit, parameters.threshold)
                    }
                    onChange={this.handleOnValueChange('threshold')}
                  />
                }
                unit={
                  <FormText>
                    {measurementSystem === MeasurementSystem.imperial
                      ? '° F'
                      : '° C'}
                  </FormText>
                }
              />
            }
          />
        )}
      </>
    );
  }
}

export default ConditionTemperature;
