import * as React from 'react';
import { connect } from 'react-redux';
import { Router, Redirect, RouteComponentProps, navigate } from '@reach/router';
import ConnectedHeader from './ConnectedHeader';
import Navigation from '../../components/Navigation';

import styles from './DefaultLayout.module.css';

import { closeDialog } from '../../actions/dialog';
import Dialog from '../../components/Dialog';
import ViewNotifications from '../../components/views/ViewNotifications';

const ViewEquipment: React.FunctionComponent<RouteComponentProps> = React.lazy(
  () => import('../../components/views/ViewEquipment')
);

const ViewSites: React.FunctionComponent<RouteComponentProps> = React.lazy(() =>
  import('../../components/views/ViewSites')
);

const ViewUsers: React.FunctionComponent<RouteComponentProps> = React.lazy(() =>
  import('../../components/views/ViewUsers')
);

type Props = {
  isInitialised: boolean;
  dialog: any;
  closeDialog: any;
  currentUser: any;
} & RouteComponentProps;

function DefaultLayout({
  isInitialised,
  dialog,
  closeDialog,
  currentUser,
}: Props) {
  if (!isInitialised) {
    return null;
  }

  if (!currentUser) {
    return null;
  }

  return (
    <div className={styles.root}>
      <ConnectedHeader classes={styles} isAuthenticated />
      <Navigation classes={styles} isAuthenticated />
      <main className={styles.content}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Router primary={false}>
            <ViewNotifications path="notifications/*" />

            <Redirect from="/" to="/notifications" noThrow />

            <ViewEquipment path="monitoring/equipment/*" />
            <ViewSites path="monitoring/sites/*" />
            <ViewUsers path="users" />
          </Router>
        </React.Suspense>
      </main>
      <Dialog dialog={dialog} onClose={closeDialog} />
    </div>
  );
}

const mapStateToProps = state => ({
  currentUser: state.app.currentUser,
  isInitialised: state.app.isInitialised,
  dialog: state.dialog,
});

const mapDispatchToProps = { closeDialog };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayout);
