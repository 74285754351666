import * as React from 'react';
import { Link } from '@reach/router';
import classNames from '../../utils/classNames';
import styles from './TabLink.module.css';

type Props = {
  to: string;
  disabled?: boolean;
  secondary?: boolean;
  activeOnNested?: boolean;
  children: React.ReactNode;
};

const TabLink = ({
  disabled,
  activeOnNested,
  secondary = false,
  ...props
}: Props) => {
  const secondarySuffix = secondary ? '-secondary' : '';

  return (
    <Link
      {...props}
      getProps={({ isCurrent, isPartiallyCurrent }) => ({
        className: classNames(
          styles[`root${secondarySuffix}`],
          isCurrent || (activeOnNested && isPartiallyCurrent)
            ? styles[`active${secondarySuffix}`]
            : undefined,
          disabled ? styles[`disabled${secondarySuffix}`] : undefined
        ),
      })}
    />
  );
};

export default TabLink;
