import { connect } from 'react-redux';
import BaseActivityChip from '../../../components/ActivityChip/BaseActivityChip';
import { getAllUnseenNotificationCount } from '../../../actions/notifications';

const mapStateToProps = state => ({ count: state.notificationsCount });

const mapDispatchToProps = { getNotifications: getAllUnseenNotificationCount };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseActivityChip);
