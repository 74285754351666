import * as React from 'react';
import { UiIcon } from '../../../types/icon';

const ChevronRightTiny = ({ classes = {}, size = 8 }: UiIcon) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={classes.icon}
    width={size}
    height={size}
    viewBox="0 0 8 8"
  >
    <path
      d="M2.5 7c-.3 0-.5-.2-.5-.5 0-.1.1-.3.1-.3L4.3 4 2.2 1.9c-.2-.2-.2-.6 0-.8.2-.2.5-.2.7 0l2.5 2.5c.2.2.2.5 0 .7L2.9 6.8c-.1.1-.3.2-.4.2z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronRightTiny;
