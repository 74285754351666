import * as React from 'react';
import styles from './SentinelWizard.module.css';
import WizardStepper from './WizardStepper';
import StepSentinelType from './steps/StepSentinelType';
import StepCondition from './steps/StepCondition/StepCondition';
import StepSchedule from './steps/StepSchedule/StepSchedule';
import SentinelWizardNavigation from './SentinelWizardNavigation';
import StepHandling from './steps/StepHandling/StepHandling';
import SentinelTypeFlag from './SentinelTypeFlag';
import SubTabs from '../Tabs/SubTabs';
import SubTab from '../Tabs/SubTabs/SubTab';
import Button from '../ui/Button';
import CloseButton from '../CloseButton/CloseButton';
import SentinelMetaInputs from './SentinelMetaInput';
import CancelConfigurationModal from '../Modals/CancelConfigurationModal';
import SaveConfigurationModal from '../Modals/SaveConfigurationModal';
import { sentinelSteps, StepType } from '../../types/sentinelWizard';
import { appMeasurementSystem, MeasurementSystem } from '../../types/shared';
import { validateField as validateScheduleItems } from './steps/StepSchedule/StepScheduleItem';

import {
  ComparisonType,
  customDelaySentinels,
  defaultBatteryCheckParameters,
  defaultBinaryCounterParameters,
  defaultDoorParameters,
  defaultTemperatureParams,
  initialSentinelEscalation,
  IntervalUnit,
  Parameters,
  Sentinel,
  SentinelEscalation,
  SentinelInterval,
  SentinelSchedule,
  SentinelTarget,
  SentinelType,
  sentinelTypesList,
  sentinelTypeToTargetType,
  defaultCustomerComfortParameters,
} from '../../types/sentinel';

import { fetchSentinelsCreate, getSentinelById } from '../../actions/sentinels';

import { determineWizardStep } from '../../utils/sentinel';
import classNames from '../../utils/classNames';

import TrashIcon from '../icons/ui/Trash';
import StepRolloutContainer from './steps/StepRollout/StepRolloutContainer';
import { openDialog } from '../../actions/dialog';
import { UserRole } from '../../types/user';

type State = {
  id: string;
  title: string;
  description: string;
  sentinelType?: SentinelType;
  targets: any;
  escalations: SentinelEscalation[];
  parameters: Parameters;
  schedules: SentinelSchedule[];
  muted?: boolean;
  muteReason?: string;
  reason?: string;
  mutedUntil?: string;

  sentinelConditionValue: any;
  sentinelSchedule: {
    from: string;
    to: string;
  };

  delay: SentinelInterval;

  availableSteps: number[];
  currentStep: StepType;

  error: string | boolean;
  loading: boolean;
  valid?: boolean;
  nextIsClicked: boolean;
  cancelConfigurationModalOpen: boolean;
  saveConfigurationModalOpen: boolean;
};

const initialState: State = {
  id: '',
  title: '',
  description: '',
  sentinelType: SentinelType.NONE,
  targets: [],
  parameters: {},
  escalations: [initialSentinelEscalation],
  delay: {
    unit: IntervalUnit.MILLISECOND,
    value: 0,
  },
  schedules: [],
  sentinelConditionValue: '',
  sentinelSchedule: {
    from: '',
    to: '',
  },

  availableSteps: [],
  currentStep: 0,

  error: false,
  loading: false,
  valid: false,
  nextIsClicked: false,

  cancelConfigurationModalOpen: false,
  saveConfigurationModalOpen: false,
};

type Props = {
  sentinelId?: string;
  wizardIsActive?: boolean;
  sentinel?: Sentinel;
  onUpdateOrCreate: (any: void) => Promise<void>;
  onExit: (sentinel?: Sentinel) => void;
  onDelete?: any;
  extraClassNames?: string[];
  measurementSystem?: MeasurementSystem;
  openDialog: typeof openDialog;
  userRole: UserRole;
};

class SentinelWizard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  componentDidMount() {
    const { sentinel, sentinelId } = this.props;
    if (sentinel || sentinelId) {
      this.handleLoadSentinel(sentinelId, sentinel)
        .then(s => s && this.handleLoadSentinelData(s))
        .catch(err => this.setState({ error: err.toString() }));
    } else {
      this.setState({ currentStep: StepType.type });
    }
  }

  componentDidUpdate = (prevProps: Props) => {
    const nextIdDoesNotEqualThisId =
      (prevProps.sentinel ? prevProps.sentinel.id : '') !==
      (this.props.sentinel ? this.props.sentinel.id : '');
    if (nextIdDoesNotEqualThisId) {
      if (this.props.sentinel) {
        this.handleLoadSentinelData(this.props.sentinel).catch(err =>
          this.setState({ error: err.toString() })
        );
      }
    }
  };

  handleLoadSentinel = async (sentinelId?: string, sentinel?: Sentinel) => {
    if (sentinelId) {
      return getSentinelById(sentinelId);
    }

    return sentinel;
  };

  handleLoadSentinelData = async (sentinel: Sentinel) => {
    try {
      const escalations =
        sentinel.escalations && sentinel.escalations.length > 0
          ? sentinel.escalations
          : [initialSentinelEscalation];

      this.setState({
        ...sentinel,
        escalations,
        currentStep: !sentinel.valid
          ? determineWizardStep(sentinel)
          : StepType.condition,
        loading: false,
      });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  handleSetStep = (nextStep: StepType) =>
    this.setState({ currentStep: nextStep });

  handleNameChange = (name: string) => (ev: any) =>
    // @ts-ignore
    this.setState({ [name]: ev.target.value });

  handleSetStepType = (sentinelType: SentinelType) => {
    switch (sentinelType) {
      case SentinelType.TEMPERATURE:
        this.setState({
          sentinelType,
          parameters: { ...defaultTemperatureParams },
        });
        break;

      case SentinelType.BINARY_COUNTER:
        this.setState({
          sentinelType,
          parameters: { ...defaultBinaryCounterParameters },
        });
        break;

      case SentinelType.DOOR:
        this.setState({
          sentinelType,
          parameters: { ...defaultDoorParameters },
        });
        break;

      case SentinelType.CONNECTIVITY:
      case SentinelType.BATTERY_LEVEL_CHECK:
        this.setState({
          sentinelType,
          parameters: { ...defaultBatteryCheckParameters },
        });
        break;

      case SentinelType.CAR_WASH:
        return this.setState({
          sentinelType,
          parameters: { ...defaultTemperatureParams },
        });

      case SentinelType.CUSTOMER_COMFORT:
        return this.setState({
          sentinelType,
          parameters: { ...defaultCustomerComfortParameters },
        });

      default:
        throw new Error('Unknown sentinel type');
    }
  };

  handleSetStepConditionUnit = (unit: IntervalUnit) =>
    this.setState({
      delay: {
        value: 0,
        unit,
      },
    });

  handleSetStepConditionDelay = (intervalUnit: IntervalUnit, value: string) => {
    if (intervalUnit === IntervalUnit.MILLISECOND) {
      const isNumber = /^[0-9]*$/.test(value);
      const number = isNumber ? parseInt(value, 10) * 60 * 1000 : 0;
      // smaller than 120 minutes (120*60*1000)
      if (number > 7200000) {
        return;
      }

      this.setState({
        delay: {
          ...this.state.delay,
          value: number,
        },
      });
    }

    if (intervalUnit === IntervalUnit.DEVICEINTERVAL) {
      const isNumber = /^[0-9]*$/.test(value);
      const number = isNumber ? parseInt(value, 10) : 0;
      // smaller then 15
      if (number > 15) {
        return;
      }

      this.setState({
        delay: {
          ...this.state.delay,
          value: number,
        },
      });
    }
  };

  handleSetStepCondition = (name: string, value: string | number): void => {
    const extraArgs: Parameters = {};

    if (name === 'comparisonType') {
      if (value === ComparisonType.inner || value === ComparisonType.outer) {
        extraArgs.threshold = undefined;
        extraArgs.rangeLowerBound = 8;
        extraArgs.rangeUpperBound = 32;
      }

      if (
        value === ComparisonType.lt ||
        value === ComparisonType.eq ||
        value === ComparisonType.gt
      ) {
        extraArgs.rangeLowerBound = undefined;
        extraArgs.rangeUpperBound = undefined;
        extraArgs.threshold = 8;
      }
    }

    this.setState({
      parameters: {
        ...this.state.parameters,
        ...extraArgs,
        [name]: value,
      },
    });
  };

  handleAddSchedule = (schedule: SentinelSchedule) =>
    this.setState(prevState => ({
      schedules: [...prevState.schedules, schedule],
    }));

  handleUpdateSchedule = (scheduleIndex: number) => (
    updatedSchedule: SentinelSchedule
  ) =>
    this.setState(prevState => ({
      schedules: prevState.schedules.map((schedule, index) =>
        index === scheduleIndex ? { ...updatedSchedule } : schedule
      ),
    }));

  handleRemoveSchedule = (scheduleIndex: number) =>
    this.setState(prevState => ({
      schedules: prevState.schedules.filter(
        (_, index) => index !== scheduleIndex
      ),
    }));

  handleAddStepHandling = (escalation: SentinelEscalation) =>
    this.setState({
      escalations: [
        ...this.state.escalations,
        { ...escalation, ix: this.state.escalations.length },
      ],
    });

  handleRemoveStepHandling = (escalationIndex: number) => () =>
    this.setState({
      escalations: this.state.escalations
        .filter((_, index) => index !== escalationIndex)
        .map((handling, index) => ({ ...handling, ix: index })),
    });

  handleUpdateStepHandling = (escalationIndex: number) => (
    sentinelEscalation: SentinelEscalation
  ) =>
    this.setState({
      escalations: this.state.escalations.map((escalation, index) =>
        index === escalationIndex
          ? { ...sentinelEscalation, ix: index }
          : { ...escalation, ix: index }
      ),
    });

  handleTargetChange = (sentinelTarget: SentinelTarget) =>
    this.setState({
      targets: this.state.targets.find(
        (t: any) => t.targetId === sentinelTarget.targetId
      )
        ? this.state.targets.filter(
            (t: any) => t.targetId !== sentinelTarget.targetId
          )
        : [...this.state.targets, sentinelTarget],
    });

  handleTargetsDelete = (targetIds: number[] = []) =>
    this.setState(prevState => ({
      targets: prevState.targets.filter(
        (t: SentinelTarget) => targetIds.includes(t.targetId) === false
      ),
    }));

  handleTargetsAdd = (sentinelTargets: SentinelTarget[]) => {
    const newTargets = sentinelTargets.filter(
      t =>
        this.state.targets.find(target => target.targetId === t.targetId) ===
        undefined
    );

    this.setState(({ targets }) => ({
      targets: [...targets, ...newTargets],
    }));
  };

  handleDelete = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  };

  handleSentinelSubmit = async () => {
    const {
      id,
      title,
      description,
      sentinelType = SentinelType.NONE,
      parameters,
      escalations,
      targets,
      schedules,
      delay,
    } = this.state;

    this.setState({ error: false, loading: true });

    const sentinel: Sentinel = {
      id,
      title,
      description,
      sentinelType,
      targets,
      parameters,
      escalations,
      schedules,
      delay,
    };

    fetchSentinelsCreate(sentinel)
      .then(() => this.props.onUpdateOrCreate())
      .catch(err =>
        this.setState({ error: err.message || true, loading: false })
      );
  };

  handleCancelConfiguration = () =>
    this.setState({
      cancelConfigurationModalOpen: true,
    });

  handleSaveConfiguration = () =>
    this.setState({
      nextIsClicked: true,
      saveConfigurationModalOpen: true,
    });

  stateChanged = () => {
    const { sentinel } = this.props;

    if (!sentinel) {
      return;
    }

    const {
      title,
      description,
      sentinelType,
      targets,
      parameters,
      escalations,
      schedules,
      delay,
    } = this.state;

    const diff =
      sentinel.title === title &&
      sentinel.description === description &&
      sentinel.delay === delay &&
      sentinel.sentinelType === sentinelType &&
      JSON.stringify(sentinel.parameters) === JSON.stringify(parameters) &&
      JSON.stringify(sentinel.escalations) === JSON.stringify(escalations) &&
      JSON.stringify(sentinel.schedules) === JSON.stringify(schedules) &&
      JSON.stringify(sentinel.targets) === JSON.stringify(targets);

    return diff === false;
  };

  validateStep = (step: StepType): boolean => {
    const {
      title,
      sentinelType,
      parameters,
      schedules,
      escalations,
      targets,
    } = this.state;

    switch (step) {
      case StepType.type:
        return (sentinelType !== SentinelType.NONE) === true && title !== '';

      case StepType.condition:
        if (this.validateStep(StepType.type)) {
          if (
            sentinelType === SentinelType.BATTERY_LEVEL_CHECK ||
            sentinelType === SentinelType.CONNECTIVITY ||
            sentinelType === SentinelType.CUSTOMER_COMFORT
          ) {
            return true;
          }

          if (sentinelType === SentinelType.BINARY_COUNTER) {
            return (
              !!parameters.maxOpeningCount && parameters.maxOpeningCount > 0
            );
          }

          if (sentinelType === SentinelType.DOOR) {
            return (
              parameters.openClosed === 'OPEN' ||
              parameters.openClosed === 'CLOSED'
            );
          }

          if (
            sentinelType === SentinelType.TEMPERATURE ||
            sentinelType === SentinelType.CAR_WASH
          ) {
            if (
              parameters.comparisonType === ComparisonType.inner ||
              parameters.comparisonType === ComparisonType.outer
            ) {
              return (
                typeof parameters.rangeLowerBound === 'number' &&
                typeof parameters.rangeUpperBound === 'number'
              );
            }

            return typeof parameters.threshold === 'number';
          }
        }
        return false;

      case StepType.schedule:
        if (
          this.validateStep(StepType.type) &&
          this.validateStep(StepType.condition)
        ) {
          if (schedules.length !== 0) {
            for (const schedule of schedules) {
              for (const timeRange of schedule['timeRanges']) {
                if (validateScheduleItems(timeRange.begin, timeRange.end)) {
                  return false; //if there is schedule error, don't allow to go to next step
                }
              }
            }
          }
          return true; //otherwise, can move to next step
        }
        return false;

      case StepType.handling:
        if (
          this.validateStep(StepType.type) &&
          this.validateStep(StepType.condition) &&
          this.validateStep(StepType.schedule)
        ) {
          return escalations.length > 0 && escalations[0].recipients.length > 0;
        }
        return false;

      case StepType.rollout:
        if (
          this.validateStep(StepType.type) &&
          this.validateStep(StepType.condition) &&
          this.validateStep(StepType.schedule) &&
          this.validateStep(StepType.handling)
        ) {
          return targets.length > 0;
        }
        return false;

      default:
        return false;
    }
  };

  handleValidation = (nextStep: any) => {
    const { currentStep, availableSteps } = this.state;

    this.setState({ nextIsClicked: true });
    if (!this.state.availableSteps.includes(currentStep)) {
      this.setState({ availableSteps: [...availableSteps, currentStep] });
    }

    if (this.state.title === '') {
      return;
    }
    /* eslint-disable */
    nextStep ? this.handleSetStep(nextStep.key) : this.handleSentinelSubmit();
  };

  render() {
    const {
      availableSteps,
      currentStep,

      id,
      title,
      description,
      sentinelType = SentinelType.NONE,
      parameters,
      escalations,
      targets,
      schedules,
      delay,

      valid,
      loading,
      nextIsClicked,
      error,
    } = this.state;

    const {
      extraClassNames = [],
      measurementSystem = appMeasurementSystem.metric,
      userRole,
    } = this.props;

    const stepsCompleted = {
      [StepType.type]: this.validateStep(StepType.type),
      [StepType.condition]: this.validateStep(StepType.condition),
      [StepType.schedule]: this.validateStep(StepType.schedule),
      [StepType.handling]: this.validateStep(StepType.handling),
      [StepType.rollout]: this.validateStep(StepType.rollout),
    };

    const wizardIsActive =
      id === '' || valid === false || this.props.wizardIsActive;
    const stepIsSet = currentStep !== StepType.NONE;

    return (
      <div className={classNames(styles.root, ...extraClassNames)}>
        <div className={styles.formWrap}>
          {stepIsSet && (
            <SentinelMetaInputs
              handleNameChange={this.handleNameChange}
              title={title}
              description={description}
              nextIsClicked={nextIsClicked}
            />
          )}

          {!this.props.sentinel && <CloseButton onClick={this.props.onExit} />}

          <SentinelTypeFlag sentinelType={sentinelType} />

          {stepIsSet && wizardIsActive && (
            <WizardStepper
              steps={Object.values(sentinelSteps)}
              currentStep={currentStep}
              setStep={this.handleSetStep}
              stepsCompleted={stepsCompleted}
              availableSteps={availableSteps}
            />
          )}

          {wizardIsActive === false && (
            <div className={styles.tabsContainer}>
              <SubTabs>
                <SubTab
                  onClick={() => this.handleSetStep(StepType.condition)}
                  label="Conditions"
                  isActive={currentStep === StepType.condition}
                />
                <SubTab
                  onClick={() => this.handleSetStep(StepType.schedule)}
                  label="Schedule"
                  isActive={currentStep === StepType.schedule}
                />
                <SubTab
                  onClick={() => this.handleSetStep(StepType.handling)}
                  label="Handling"
                  isActive={currentStep === StepType.handling}
                />
                <SubTab
                  onClick={() => this.handleSetStep(StepType.rollout)}
                  label={`Rollout (${targets.length})`}
                  isActive={currentStep === StepType.rollout}
                />
              </SubTabs>
            </div>
          )}

          <div className={styles.steps}>
            {currentStep === StepType.type && wizardIsActive && (
              <StepSentinelType
                sentinelTypesList={sentinelTypesList}
                onChange={this.handleSetStepType}
                sentinelType={sentinelType}
                userRole={userRole}
              />
            )}

            {currentStep === StepType.condition && (
              <StepCondition
                onChange={this.handleSetStepCondition}
                onDelayChange={this.handleSetStepConditionDelay}
                onUnitChange={this.handleSetStepConditionUnit}
                parameters={parameters}
                sentinelType={sentinelType}
                delay={delay}
                measurementSystem={measurementSystem}
                customDelaySentinels={customDelaySentinels}
              />
            )}

            {currentStep === StepType.schedule && (
              <StepSchedule
                sentinelType={sentinelType}
                schedules={schedules}
                handleAddSchedule={this.handleAddSchedule}
                handleRemoveSchedule={this.handleRemoveSchedule}
                handleUpdateSchedule={this.handleUpdateSchedule}
              />
            )}

            {currentStep === StepType.handling && (
              <StepHandling
                handleAddStepHandling={this.handleAddStepHandling}
                handleRemoveStepHandling={this.handleRemoveStepHandling}
                handleUpdateStepHandling={this.handleUpdateStepHandling}
                escalations={escalations}
              />
            )}

            {currentStep === StepType.rollout && (
              <StepRolloutContainer
                targets={targets}
                sentinelType={sentinelType}
                onTargetChange={this.handleTargetChange}
                onTargetsChange={this.handleTargetsAdd}
                onTargetsDelete={this.handleTargetsDelete}
                targetTypes={sentinelTypeToTargetType(sentinelType)}
              />
            )}
          </div>
        </div>

        {stepIsSet && wizardIsActive && (
          <SentinelWizardNavigation
            setStep={this.handleSetStep}
            currentStep={currentStep}
            stepsCompleted={stepsCompleted}
            handleSubmit={this.handleSentinelSubmit}
            handleValidation={this.handleValidation}
            loading={loading}
            handleFinishLater={this.handleSentinelSubmit}
          />
        )}

        {wizardIsActive === false && (
          <>
            <div className={styles.actions}>
              <div>
                <Button
                  secondary
                  onClick={this.handleDelete}
                  extraClassName={styles.deleteButton}
                >
                  <TrashIcon className={styles.deleteIcon} />
                  Delete configuration
                </Button>
              </div>

              <div className={styles.controls}>
                <Button
                  fullWidth
                  secondary
                  onClick={
                    this.stateChanged()
                      ? this.handleCancelConfiguration
                      : () => this.props.onExit()
                  }
                  isLoading={loading}
                >
                  Cancel
                </Button>
                <Button
                  fullWidth
                  onClick={this.handleSaveConfiguration}
                  isLoading={loading}
                  disabled={stepsCompleted[currentStep] === false}
                >
                  Save
                </Button>
              </div>

              <div />
            </div>

            {error && error}

            {this.state.cancelConfigurationModalOpen && (
              <CancelConfigurationModal
                onClose={() =>
                  this.setState({ cancelConfigurationModalOpen: false })
                }
                onSubmit={this.props.onExit}
              />
            )}

            {this.state.saveConfigurationModalOpen && (
              <SaveConfigurationModal
                onClose={() =>
                  this.setState({ saveConfigurationModalOpen: false })
                }
                onSubmit={this.handleSentinelSubmit}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default SentinelWizard;
