import * as React from 'react';
import styles from './BaseList.module.css';
import classNames from '../../utils/classNames';

const BaseList: React.FunctionComponent<{ extraClassNames?: string[] }> = ({
  children,
  extraClassNames = [],
}) => (
  <div className={classNames(styles.list, ...extraClassNames)}>{children}</div>
);

export default BaseList;
