import { fetchApi } from '../middleware/apiMiddleware';
import { Service } from '../config';
import { NotificationCountType } from '../types/notification';

export enum Actions {
  NOTIFICATIONS_SUCCESS = '@@notifications/NOTIFICATIONS_SUCCESS',
  NOTIFICATIONS_LOADING = '@@notifications/NOTIFICATIONS_LOADING',
  NOTIFICATIONS_ERROR = '@@notifications/NOTIFICATIONS_ERROR',
  NOTIFICATIONS_CLEAR = '@@notifications/NOTIFICATIONS_CLEAR',
  DISMISS_SUCCESS = '@@notifications/DISMISS_SUCCESS',
  DISMISS_LOADING = '@@notifications/DISMISS_LOADING',
  DISMISS_ERROR = '@@notifications/DISMISS_ERROR',
  COUNT_SUCCESS = '@@notifications/COUNT_SUCCESS',
  COUNT_LOADING = '@@notifications/COUNT_LOADING',
  COUNT_ERROR = '@@notifications/COUNT_ERROR',
  ORDER_UNSEEN = '@@notifications/ORDER_UNSEEN',
  ORDER_DATE = '@@notifications/ORDER_DATE',
}

export const fetchNotificationsList = (queryParams: string) =>
  fetchApi({
    endpoint: `/api/v1/notifications?${queryParams}`,
    service: Service.sentinel,
  });

export const getNotifications = (queryParams: string) => ({
  type: 'API_GET',
  path: `/api/v1/notifications?${queryParams}`,
  service: Service.sentinel,
  loading: Actions.NOTIFICATIONS_LOADING,
  success: Actions.NOTIFICATIONS_SUCCESS,
  error: Actions.NOTIFICATIONS_ERROR,
});

export interface ClearNotifications {
  (): void;
}
export const clearNotifications = () => ({
  type: Actions.NOTIFICATIONS_CLEAR,
});

export const dismissNotificationById = (id: string, comment: string) => ({
  type: 'API_PUT',
  path: `/api/v1/notifications/${id}/dismiss`,
  payload: {
    dismissedComment: comment,
  },
  service: Service.sentinel,
  loading: Actions.DISMISS_LOADING,
  success: Actions.DISMISS_SUCCESS,
  error: Actions.DISMISS_ERROR,
});

export interface OrderNotificationsByUnseen {
  (): void;
}

export const orderNotificationsByUnseen: OrderNotificationsByUnseen = () => ({
  type: Actions.ORDER_UNSEEN,
});

export interface OrderNotificationsByDate {
  (): void;
}

export const orderNotificationsByDate = () => ({
  type: Actions.ORDER_DATE,
});

export const fetchUnseenNotificationCountByResourceId = (
  resourceId: number,
  type: NotificationCountType
) =>
  fetchApi({
    endpoint: `/api/v1/count/notifications?state=unseen&${type}Ids=${resourceId}`,
    service: Service.sentinel,
    method: 'GET',
  });

export const fetchAllUnseenNotificationCount = () =>
  fetchApi({
    endpoint: `/api/v1/count/notifications?state=unseen`,
    service: Service.sentinel,
    method: 'GET',
  });

export type GetAllUnseenNotificationCount = (
  resourceId?: number,
  type?: NotificationCountType
) => Promise<void>;

export const getAllUnseenNotificationCount = (
  resourceId?: number,
  type?: NotificationCountType
) => async (dispatch: any): Promise<any> => {
  dispatch({ type: Actions.COUNT_LOADING });

  try {
    const { count } =
      resourceId && type
        ? await fetchUnseenNotificationCountByResourceId(resourceId, type)
        : await fetchAllUnseenNotificationCount();
    dispatch({ type: Actions.COUNT_SUCCESS, payload: { count } });
  } catch (e) {
    dispatch({ type: Actions.COUNT_ERROR });
  }
};
