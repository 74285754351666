import * as React from 'react';

import IconControlFactory from '../icons/IconUiFactory';
import IconButton from '../IconButton/IconButton';
import styles from './CloseButton.module.css';

type Props = {
  onClick: any;
};

export default ({ onClick }: Props) => (
  <div className={styles.close}>
    <IconButton onClick={onClick}>
      {IconControlFactory({ id: 'close' })}
    </IconButton>
  </div>
);
