import * as React from 'react';
import styles from './PriorityLevel.module.css';
import { SentinelPriorityLevel } from '../../types/sentinel';

type Props = {
  priority?: SentinelPriorityLevel;
};

const PriorityLevel: React.FunctionComponent<Props> = ({
  children,
  priority = 'normal',
}) => (
  <span className={`${styles.root} ${styles[priority.toLowerCase()]}`}>
    {children}
    <span className={styles.circle} />
  </span>
);

export default PriorityLevel;
