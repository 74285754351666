import { NotificationsParams } from '../types/notification';
import { toFahrenheit } from './temperature';
import { OperationalSample } from '../types/samples';
import { SensorType } from '../types/sensors';
import { MeasurementSystem } from '../types/shared';

export function transformSensorParam(
  sensorParams: NotificationsParams[],
  measurementSystem: MeasurementSystem = MeasurementSystem.metric
): string | number | boolean {
  if (sensorParams.length === 0) {
    return '';
  }

  const param = sensorParams[0];

  switch (param.type) {
    case SensorType.BINARY:
      return param.value === 'OPEN' ? 1 : 0;
    case SensorType.HUMIDITY:
      // @ts-ignore todo: ts-fix
      return parseFloat(param.value);
    case SensorType.TEMPERATURE: {
      // @ts-ignore todo: ts-fix
      const v = parseFloat(param.value);
      return measurementSystem === MeasurementSystem.metric
        ? v
        : toFahrenheit(v);
    }
    default:
      return param.value;
  }
}

export function transformSensorApiParam(
  sample: OperationalSample,
  measurementSystem: MeasurementSystem = MeasurementSystem.metric
): string | number {
  switch (sample.type) {
    case SensorType.BINARY:
      return sample.state.includes(true) ? 1 : 0;
    case SensorType.HUMIDITY:
      return sample.humidity;
    case SensorType.TEMPERATURE: {
      const v = sample.temperature;
      return measurementSystem === MeasurementSystem.metric
        ? v
        : toFahrenheit(v);
    }
    default:
      return '';
  }
}

export function getLastSampleBySensorType(
  samples: OperationalSample[],
  type: SensorType
): OperationalSample | void {
  if (samples.length === 0) {
    return;
  }

  return [...samples].reverse().find(d => d.type === type);
}
