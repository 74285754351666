import * as React from 'react';
import classNames from '../../utils/classNames';
import styles from './BaseMenuContainer.module.css';
import Loader from '../Loader';

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  hasFilterBar?: boolean;
};

const BaseContentContainer = ({
  children,
  isLoading = false,
  hasFilterBar = false,
}: Props) => (
  <div
    className={classNames([
      styles.root,
      hasFilterBar ? styles.hasFilterBar : styles.margin,
    ])}
  >
    {children}

    <Loader isLoading={isLoading} />
  </div>
);

export default BaseContentContainer;
