import * as React from 'react';
import Loadable from 'react-loadable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconFactory } from '../../types/icon';

const Loading: any = () => <CircularProgress size={40} />;

const IconClose = Loadable({
  loader: () => import('./ui/Close'),
  loading: Loading,
});

const IconChevronBreadCrumb = Loadable({
  loader: () => import('./ui/ChevronBreadCrumb'),
  loading: Loading,
});

const IconChevronRight = Loadable({
  loader: () => import('./ui/ChevronRight'),
  loading: Loading,
});

const IconChevronDown = Loadable({
  loader: () => import('./ui/ChevronDown'),
  loading: Loading,
});

const IconChevronUpTiny = Loadable({
  loader: () => import('./ui/ChevronUpTiny'),
  loading: Loading,
});

const IconChevronRightTiny = Loadable({
  loader: () => import('./ui/ChevronRightTiny'),
  loading: Loading,
});

const IconChevronDownTiny = Loadable({
  loader: () => import('./ui/ChevronDownTiny'),
  loading: Loading,
});

const IconChevronLeftTiny = Loadable({
  loader: () => import('./ui/ChevronLeftTiny'),
  loading: Loading,
});

const Check = Loadable({
  loader: () => import('./ui/Check'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./ui/Dashboard'),
  loading: Loading,
});

const Notification = Loadable({
  loader: () => import('./ui/Notification'),
  loading: Loading,
});

const Site = Loadable({
  loader: () => import('./ui/Site'),
  loading: Loading,
});

const Equipment = Loadable({
  loader: () => import('./ui/Equipment'),
  loading: Loading,
});

const User = Loadable({
  loader: () => import('./ui/User'),
  loading: Loading,
});

const Trash = Loadable({
  loader: () => import('./ui/Trash'),
  loading: Loading,
});

const Info = Loadable({
  loader: () => import('./ui/Info'),
  loading: Loading,
});

const BellConfig = Loadable({
  loader: () => import('./ui/BellConfig'),
  loading: Loading,
});

const Globe = Loadable({
  loader: () => import('./ui/Globe'),
  loading: Loading,
});

const Location = Loadable({
  loader: () => import('./ui/Location'),
  loading: Loading,
});

const Mail = Loadable({
  loader: () => import('./ui/Mail'),
  loading: Loading,
});

const Mobile = Loadable({
  loader: () => import('./ui/Mobile'),
  loading: Loading,
});

const Logout = Loadable({
  loader: () => import('./ui/Logout'),
  loading: Loading,
});

const Search = Loadable({
  loader: () => import('./ui/Search'),
  loading: Loading,
});

const Offline = Loadable({
  loader: () => import('./ui/Offline'),
  loading: Loading,
});

const Logo = Loadable({
  loader: () => import('./ui/Logo'),
  loading: Loading,
});

const Ethernet = Loadable({
  loader: () => import('./ui/Ethernet'),
  loading: Loading,
});

const Plus = Loadable({
  loader: () => import('./ui/Plus'),
  loading: Loading,
});

export default ({ id, ...props }: IconFactory) => {
  switch (id) {
    case 'bell-config':
      return <BellConfig {...props} />;
    case 'check':
      return <Check {...props} />;
    case 'close':
      return <IconClose {...props} />;
    case 'chevronBreadCrumb':
      return <IconChevronBreadCrumb {...props} />;
    case 'chevronDown':
      return <IconChevronDown {...props} />;
    case 'chevronRight':
      return <IconChevronRight {...props} />;
    case 'chevronLeft':
      return <IconChevronRight {...props} />;
    case 'chevronTopTiny':
      return <IconChevronUpTiny {...props} />;
    case 'chevronRightTiny':
      return <IconChevronRightTiny {...props} />;
    case 'chevronBottomTiny':
      return <IconChevronDownTiny {...props} />;
    case 'chevronLeftTiny':
      return <IconChevronLeftTiny {...props} />;
    case 'dashboard':
      return <Dashboard {...props} />;
    case 'equipment':
      return <Equipment {...props} />;
    case 'ethernet':
      // @ts-ignore
      return <Ethernet {...props} />;
    case 'globe':
      // @ts-ignore
      return <Globe {...props} />;
    case 'info':
      return <Info {...props} />;
    case 'location':
      // @ts-ignore
      return <Location {...props} />;
    case 'logo':
      return <Logo {...props} />;
    case 'logout':
      return <Logout {...props} />;
    case 'mobile':
      // @ts-ignore
      return <Mobile {...props} />;
    case 'mail':
      // @ts-ignore
      return <Mail {...props} />;
    case 'notification':
      return <Notification {...props} />;
    case 'offline':
      // @ts-ignore
      return <Offline {...props} />;
    case 'plus':
      return <Plus {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'site':
      return <Site {...props} />;
    case 'trash':
      return <Trash {...props} />;
    case 'user':
      return <User {...props} />;
    default:
      return null;
  }
};
