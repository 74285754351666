import * as React from 'react';
import styles from './IconButton.module.css';

type Props = {
  onClick: (any: any) => void;
  rest?: any;
};

const IconButton: React.FunctionComponent<Props> = ({
  children,
  onClick,
  ...rest
}) => (
  <button className={styles.root} onClick={onClick} {...rest}>
    {children}
  </button>
);

export default IconButton;
