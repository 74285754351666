import { fetchApi } from '../middleware/apiMiddleware';
import { Site, SitesApiFilter } from '../types/site';

export enum Actions {
  SITES_SUCCESS = '@@app/SITES_SUCCESS',
  SITES_LOADING = '@@app/SITES_LOADING',
  SITES_ERROR = '@@app/SITES_ERROR',

  SITE_SUCCESS = '@@app/SITES_SUCCESS',
  SITE_LOADING = '@@app/SITES_LOADING',
  SITE_ERROR = '@@app/SITES_ERROR',
}

export const setSitesFilter = (filter?: SitesApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.join(','));
  }

  if (filter && filter.sensorIds) {
    queryParams.set('sensorIds', filter.sensorIds.join(','));
  }

  if (filter && filter.spaceIds) {
    queryParams.set('spaceIds', filter.spaceIds.join(','));
  }

  if (filter && filter.sensorTypes && filter.sensorTypes.length > 0) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.sensorTarget) {
    queryParams.set('sensorTarget', filter.sensorTarget);
  }

  if (filter && filter.sensorTypesAnd) {
    queryParams.set('sensorTypesAnd', 'true');
  }

  return `/api/v1/sites?${queryParams.toString()}`;
};

export const fetchSites = (filter?: SitesApiFilter): Promise<Site[]> =>
  fetchApi({ endpoint: setSitesFilter(filter) });

export const getSitesByTargets = (targetIds: number[] = []): Promise<Site[]> =>
  fetchApi({
    endpoint: `/api/v1/sites/by-targets?targetIds=${targetIds.join(',')}`,
  });

export const getSites = (filter?: SitesApiFilter) => ({
  type: 'API_GET',
  path: setSitesFilter(filter),
  loading: Actions.SITES_LOADING,
  success: Actions.SITES_SUCCESS,
  error: Actions.SITES_ERROR,
});

export const fetchSiteById = (siteId: string | number): Promise<Site> =>
  fetchApi({ endpoint: `/api/v1/sites/${siteId}` }).catch(err => err);

export const fetchSiteByResourceId = (resourceId: number): Promise<Site> =>
  fetchApi({
    endpoint: `/api/v1/resources/${resourceId.toString()}/site`,
  });

export const sortSites = (sites: Site[]) => {
  return sites.sort((a: Site, b: Site) => {
    const x = String(a.title).toLowerCase();
    const y = String(b.title).toLowerCase();

    if (x > y) {
      return 1;
    } else if (x < y) {
      return -1;
    } else if (x === y) {
      return 0;
    } else {
      return 0;
    }
  });
};
