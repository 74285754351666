import React from 'react';
import IconUiFactory from '../icons/IconUiFactory';
import styles from './ActivityChip.module.css';
import { NotificationCountType } from '../../types/notification';
import { GetAllUnseenNotificationCount } from '../../actions/notifications';

type Props = {
  size?: number;
  type?: NotificationCountType;
  resourceId?: number;
  count?: number;
  getNotifications?: GetAllUnseenNotificationCount;
};

type State = {
  isLoading: boolean;
};

class BaseActivityChip extends React.Component<Props, State> {
  componentDidMount() {
    if (typeof this.props.getNotifications === 'function') {
      this.props.getNotifications(this.props.resourceId, this.props.type);
    }
  }

  render() {
    const { size = 25, count = 0 } = this.props;

    return (
      <div className={styles.iconAndTextGrouping}>
        <span className={styles.iconStyle}>
          <IconUiFactory size={size} id="notification" />
          {count > 0 && <div className={styles.numberCircle}>{count}</div>}
        </span>
      </div>
    );
  }
}

export default BaseActivityChip;
