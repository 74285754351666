import * as React from 'react';
import styles from './FilterDropdownTextItem.module.css';

type Props = {
  title: string;
  isActive?: boolean;
  onClick: any;
};

const FilterDropdownTextItem: React.FunctionComponent<Props> = ({
  title,
  isActive = false,
  onClick,
}) => (
  <button
    className={`${styles.root} ${isActive ? styles.active : ''}`}
    onClick={onClick}
  >
    {title}
  </button>
);

export default FilterDropdownTextItem;
