import * as React from 'react';
import { EquipmentIcon } from '../../../types/icon';

const IconSize80 = () => (
  <g>
    <g opacity=".1">
      <path
        d="M37 69.1h-3.6v-1h.6c.8 0 1.5-.7 1.5-1.5v-5.5c0-.4-.2-.8-.4-1.1l-.7-.7c-.2-.2-.5-.2-.7 0s-.2.5 0 .7l.7.7c.1.1.1.2.1.3v5.5c0 .3-.2.5-.5.5h-.6v-9c0-1.1-.9-2-2-2h-4.9c-1.1 0-2 .9-2 2v11h-2V44h2v-2H47c.8 0 1.5-.7 1.5-1.5v-1c0-.8-.7-1.5-1.5-1.5H8.5c-.8 0-1.5.7-1.5 1.5v1c0 .8.7 1.5 1.5 1.5h10v2h2v25h-2V58c0-1.1-.9-2-2-2h-4.9c-1.1 0-2 .9-2 2v9H9c-.3 0-.5-.2-.5-.5V61c0-.1.1-.3.1-.3l.7-.7c.2-.2.2-.5 0-.7s-.5-.2-.7 0l-.7.7c-.3.3-.4.7-.4 1.1v5.5c0 .8.7 1.5 1.5 1.5h.6v1H9c-.3 0-.5.2-.5.5s.2.5.5.5h28c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM78 69.1h-1c-.3 0-.5.2-.5.5s.2.5.5.5h1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"
        fill="currentColor"
      />
      <path
        d="M76 63.1h-1.5v-14h3v-2h-7v-15h2v-3H76c1.9 0 3.5-1.6 3.5-3.5s-1.6-3.5-3.5-3.5H52c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5h3.5v3h2v15h-21v2h3v14H38c-.3 0-.5.2-.5.5s.2.5.5.5h19.5v5H55c-.3 0-.5.2-.5.5s.2.5.5.5h20c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-4.5v-5H76c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"
        fill="currentColor"
      />
    </g>
    <path
      d="M57 37.1h5v1h-5zM57 40.1h5v1h-5zM57 43.1h5v1h-5zM57 46.1h5v1h-5z"
      fill="currentColor"
    />
    <circle cx="59.5" cy="30.6" r="1.5" fill="currentColor" />
    <path
      d="M32.5 62.1h-3.6v-1h.6c.8 0 1.5-.7 1.5-1.5v-5.5c0-.4-.2-.8-.4-1.1l-.7-.7c-.2-.2-.5-.2-.7 0s-.2.5 0 .7l.7.7c.1.1.1.2.1.3v5.5c0 .3-.2.5-.5.5h-.6v-9c0-1.1-.9-2-2-2H22c-1.1 0-2 .9-2 2v11h-2V37h2v-2h22.5c.8 0 1.5-.7 1.5-1.5v-1c0-.8-.7-1.5-1.5-1.5H4c-.8 0-1.5.7-1.5 1.5v1c0 .8.7 1.5 1.5 1.5h10v2h2v25h-2V51c0-1.1-.9-2-2-2H7.1c-1.1 0-2 .9-2 2v9h-.6c-.3 0-.5-.2-.5-.5V54c0-.1.1-.3.1-.3l.7-.7c.2-.2.2-.5 0-.7s-.5-.2-.7 0l-.7.7c-.2.4-.4.7-.4 1.1v5.5c0 .8.7 1.5 1.5 1.5h.6v1h-.6c-.3 0-.5.2-.5.5s.2.5.5.5h28c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM15 36.1v-1h4v1h-4zm6 15c0-.6.4-1 1-1h4.9c.6 0 1 .4 1 1v11H21v-11zm-14.9 0c0-.6.4-1 1-1H12c.6 0 1 .4 1 1v11H6.1v-11zM73.5 62.1h-1c-.3 0-.5.2-.5.5s.2.5.5.5h1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"
      fill="currentColor"
    />
    <path
      d="M71.5 56.1H70v-14h3v-2h-7v-15h2v-3h3.5c1.9 0 3.5-1.6 3.5-3.5s-1.6-3.5-3.5-3.5h-24c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5H51v3h2v15H32v2h3v14h-1.5c-.3 0-.5.2-.5.5s.2.5.5.5H53v5h-2.5c-.3 0-.5.2-.5.5s.2.5.5.5h20c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H66v-5h5.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM46 18.6c0-.8.7-1.5 1.5-1.5h24c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5h-24c-.8 0-1.5-.6-1.5-1.5zm6 5.5v-2h15v2H52zm-16 32v-14h17v5H39v9h-3zm4 0v-8h4v8h-4zm5 0v-8h4v8h-4zm5 0v-8h3v8h-3zm5 6v-37h9v37h-9zm11-20h3v14h-3v-14zM22 51.1h5v2h-5zM7 51.1h5v2H7z"
      fill="currentColor"
    />
  </g>
);

const getIconBySize = () => ({ viewBox: '0 0 80 80', icon: <IconSize80 /> });

export default ({ classes = {}, size = 80 }: EquipmentIcon) => {
  const { viewBox, icon } = getIconBySize();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
