import React from 'react';
import SignalIconFactory from '../../icons/IconSignalFactory';
import styles from './SignalChip.module.css';

type Props = {
  level?: number;
  network?: boolean;
  size?: number;
  classes?: any;
};

export default ({ level, network, size, classes = {} }: Props) => (
  <div className={styles.iconAndTextGrouping}>
    <SignalIconFactory
      classes={classes}
      size={size}
      // @ts-ignore // todo: ts-fix
      level={network ? `signal4G${level}` : `signal${level}`}
    />
  </div>
);
