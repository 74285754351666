import * as React from 'react';
import { UiIcon } from '../../../types/icon';

const IconSize16 = () => (
  <path
    d="m8 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 8.5h-3v3c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-3h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3v-3c0-.3.2-.5.5-.5s.5.2.5.5v3h3c.3 0 .5.2.5.5s-.2.5-.5.5z"
    fill="currentColor"
  />
);

const IconSize32 = () => (
  <path
    d="m16 4c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.3-12-12-12zm5.5 12.5h-5v5c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-5h-5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5v-5c0-.3.2-.5.5-.5s.5.2.5.5v5h5c.3 0 .5.2.5.5s-.2.5-.5.5z"
    fill="currentColor"
  />
);

const getIconBySize = (size: number) => {
  switch (true) {
    case size <= 16:
      return { viewBox: '0 0 16 16', icon: <IconSize16 /> };

    default:
      return { viewBox: '0 0 32 32', icon: <IconSize32 /> };
  }
};

export default ({ size = 16, ...props }: UiIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      width={size}
      height={size}
      {...props}
    >
      {icon}
    </svg>
  );
};
