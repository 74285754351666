import * as React from 'react';
import { UiIcon } from '../../../types/icon';

const ChevronLeftTiny = ({ classes = {}, size = 8 }: UiIcon) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={classes.icon}
    width={size}
    height={size}
    viewBox="0 0 8 8"
  >
    <path
      d="M5.5 1c.3 0 .5.2.5.5 0 .1-.1.3-.1.3L3.7 4l2.1 2.2c.2.1.2.5 0 .7s-.5.2-.7 0L2.6 4.4c-.2-.2-.2-.5 0-.7l2.5-2.5c.1-.1.3-.2.4-.2z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronLeftTiny;
