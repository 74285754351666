import * as React from 'react';
import styles from './ResourceSelector.module.css';
import FilterMenuItem from '../../Filters/FilterItems/FilterMenuItem';
import SearchBox from '../../search/SearchBox';
import Checkbox from '../../ui/Checkbox';

import { Site } from '../../../types/site';
import { Space } from '../../../types/space';
import { Sensor, SensorTarget } from '../../../types/sensors';
import { Equipment } from '../../../types/equipment';
import {
  SentinelType,
  sentinelTypeToSensorType,
} from '../../../types/sentinel';

import { fetchSites } from '../../../actions/sites';
import { fetchEquipment } from '../../../actions/equipment';
import { fetchSpaces } from '../../../actions/spaces';

import { filterSites } from '../../../utils/textFilters';
import { fetchSensors } from '../../../actions/sensors';

type Props = {
  sentinelType: SentinelType;
  handleSelectSite: (site: Site) => void;
  handleRemoveSite: (siteId: number) => void;
  selectedSites: Set<number>;
  sensorTargetTypes: SensorTarget[];
};

type State = {
  sites: Site[];
  query: string;
  isLoading: boolean;
  hasError: boolean;
};

type FetchToFunctionNameMap = {
  EQUIPMENT: 'loadEquipment';
  SPACE: 'loadSpaces';
  SENSOR: 'loadSensors';
};

const fetchToFunctionNameMap: FetchToFunctionNameMap = {
  EQUIPMENT: 'loadEquipment',
  SPACE: 'loadSpaces',
  SENSOR: 'loadSensors',
};

class ResourceSelector extends React.Component<Props, State> {
  state = {
    sites: [],
    query: '',
    isLoading: false,
    hasError: false,
  };

  componentDidMount() {
    this.loadSites();
  }

  async loadEquipment(siteId: number): Promise<Equipment[]> {
    this.setState({ isLoading: true });
    try {
      const { sentinelType } = this.props;
      const sensorType = sentinelTypeToSensorType(sentinelType);
      const equipment = await fetchEquipment({
        siteIds: [siteId],
        sensorTypes: sensorType,
      });
      this.setState({ isLoading: false, hasError: false });
      return equipment;
    } catch (err) {
      this.setState({ isLoading: false, hasError: true });
      return [];
    }
  }

  async loadSpaces(siteId: number): Promise<Space[]> {
    this.setState({ isLoading: true });
    try {
      const { sentinelType } = this.props;
      const sensorType = sentinelTypeToSensorType(sentinelType);
      const spaces = await fetchSpaces({
        siteIds: [siteId],
        sensorTypes: sensorType,
      });
      this.setState({ isLoading: false, hasError: false });
      return spaces;
    } catch (err) {
      this.setState({ isLoading: false, hasError: true });
      return [];
    }
  }

  async loadSensors(siteId: number): Promise<Sensor[]> {
    this.setState({ isLoading: true });
    try {
      const sensors = await fetchSensors({ siteIds: [siteId] });
      this.setState({ isLoading: false, hasError: false });
      return sensors;
    } catch (err) {
      this.setState({ isLoading: false, hasError: true });
      return [];
    }
  }

  async loadSites() {
    try {
      const { sentinelType, sensorTargetTypes } = this.props;
      const sensorType = sentinelTypeToSensorType(sentinelType);
      let sites = await Promise.all(
        sensorTargetTypes.map(sensorTarget =>
          fetchSites({ sensorTypes: sensorType, sensorTarget })
        )
      );

      // flatten and filter unique by id.
      sites = sites
        .reduce((a, b) => [...a, ...b], [])
        .reduce((acc, cur) => {
          // @ts-ignore todo: ts-fix
          if (!acc.find(item => item.id === cur.id)) {
            // @ts-ignore todo: ts-fix
            return acc.concat(cur);
          }
          return acc;
        }, []);

      // @ts-ignore todo: ts-fix
      this.setState({ sites, hasError: false });
    } catch (err) {
      this.setState({ hasError: true });
    }
  }

  handleSearch = (value: string) =>
    this.setState({
      query: value,
    });

  handleSelectSite = (site: Site) => {
    const equipment: any = [];
    const spaces: any = [];
    const sensors: any = [];
    // assign resource to the correct array
    /* eslint-disable */
    Promise.all(
      // @ts-ignore todo: ts-fix
      this.props.sensorTargetTypes.map(target =>
        this[fetchToFunctionNameMap[target]](site.id)
      )
    ).then(res => {
      res
        .reduce((a, b) => [...a, ...b], [])
        .forEach(resource => {
          if (resource._entity === 'Space') {
            spaces.push(resource);
          }
          if (resource._entity === 'Equipment') {
            equipment.push(resource);
          }
          if (resource._entity === 'Sensor') {
            sensors.push(resource);
          }
        });

      this.props.handleSelectSite({
        ...site,
        equipment,
        spaces,
        sensors,
      });
    });
    /* eslint-enable */
  };

  handleSelectAllSites = () => {
    this.state.sites.forEach((site: Site) => {
      this.handleSelectSite(site);
    });
  };

  handleDeselectAllSites = () => {
    const { selectedSites, handleRemoveSite } = this.props;
    selectedSites.forEach((siteId: number) => {
      handleRemoveSite(siteId);
    });
  };

  areAllSitesSelected = (): boolean => {
    return this.props.selectedSites.size === this.state.sites.length;
  };

  isSiteSelected = (siteId: number): boolean => {
    return this.props.selectedSites.has(siteId);
  };

  render() {
    const { sites, query, isLoading, hasError } = this.state;
    const { handleRemoveSite } = this.props;
    const lowerCaseFilterValue = query.toLowerCase();
    const renderItems = sites
      .filter(filterSites.bind(this, lowerCaseFilterValue))
      .map((site: Site) => (
        <FilterMenuItem
          /* className={classNames(
            styles.listElement,
            isLoading ? styles.isLoading : undefined
          )} */
          key={site.id}
          id={site.id}
          title={site.title}
          description={site.address}
          onClick={() =>
            this.isSiteSelected(site.id)
              ? handleRemoveSite(site.id)
              : this.handleSelectSite(site)
          }
          checked={this.isSiteSelected(site.id)}
          highlight={query}
        >
          {site.title}
        </FilterMenuItem>
      ));

    if (hasError) {
      return <div>An error occurred</div>;
    }

    return (
      <div className={styles.root}>
        <Checkbox
          checked={this.areAllSitesSelected()}
          onChange={
            this.areAllSitesSelected()
              ? this.handleDeselectAllSites
              : this.handleSelectAllSites
          }
          label={
            this.areAllSitesSelected()
              ? 'Deselect all sites'
              : 'Select all sites'
          }
        />
        <SearchBox
          placeholder="site name, city, zip…"
          query={query}
          onChange={this.handleSearch}
          items={renderItems}
          width="18.75rem"
          widthActive="18.75rem"
          withDropdown
          error={renderItems.length === 0}
        />
      </div>
    );
  }
}

export default ResourceSelector;
