import { fetchApi } from '../middleware/apiMiddleware';
import { Sensor, SensorApiFilter } from '../types/sensors';

export enum Actions {
  BY_ID_LOADING = '@@sensors/BY_ID_LOADING',
  BY_ID_SUCCESS = '@@sensors/BY_ID_SUCCESS',
  BY_ID_ERROR = '@@sensors/BY_ID_ERRORS',
}

export const fetchSensors = (filter?: SensorApiFilter): Promise<Sensor[]> => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes && filter.sensorTypes.length > 0) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.withPath) {
    queryParams.set('withPath', 'true');
  }

  return fetchApi({
    endpoint: `/api/v1/sensors?${queryParams.toString()}`,
  });
};

export const fetchSensorsByResource = (
  resourceId: number,
  recursive: boolean = false
) =>
  fetchApi({
    endpoint: `/api/v1/sensors/resource/${resourceId.toString()}?recursive=${recursive.toString()}`,
  });

export const fetchSensorById = (sensorId: number): Promise<Sensor> =>
  fetchApi({
    endpoint: `/api/v1/sensors/${sensorId}`,
  });

export const getSensorById = (sensorId: number) => async (
  dispatch: any
): Promise<void> => {
  dispatch({ type: Actions.BY_ID_LOADING });

  try {
    const sensor = await fetchSensorById(sensorId);

    // sensor.hasFullPath = true;

    dispatch({ type: Actions.BY_ID_SUCCESS, payload: sensor });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: Actions.BY_ID_ERROR });
    return Promise.resolve();
  }
};
