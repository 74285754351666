import * as React from 'react';
import styles from './ListCell.module.css';
import classNames from '../../../utils/classNames';

type Props = {
  children?: React.ReactNode;
  extraClassNames?: string[];
  isControlCell?: boolean;
  allowOverFlow?: boolean;
  withIcon?: boolean;
  onClick?: any;
  centered?: boolean;
};

const ListCell = ({
  onClick,
  children,
  withIcon,
  centered,
  allowOverFlow,
  extraClassNames = [],
}: Props) => (
  <div
    className={classNames(
      styles.listCell,
      withIcon ? styles.withIcon : undefined,
      centered ? styles.centered : undefined,
      allowOverFlow ? styles.allowOverFlow : undefined,
      ...extraClassNames
    )}
    tabIndex={0}
    onClick={onClick}
    role="button"
    onKeyDown={onClick}
  >
    {children}
  </div>
);

export default ListCell;
