import * as React from 'react';
import { NotificationIcon } from '../../../types/icon';

const IconSize = () => (
  <g>
    <path
      d="M7.5 1C11.1 1 14 3.9 14 7.5S11.1 14 7.5 14 1 11.1 1 7.5 3.9 1 7.5 1m0-1C3.4 0 0 3.4 0 7.5S3.4 15 7.5 15 15 11.6 15 7.5 11.6 0 7.5 0z"
      fill="currentColor"
    />
    <path
      d="M7.5 3c-.3 0-.5.2-.5.5v4c0 .3.2.5.5.5s.5-.2.5-.5v-4c0-.3-.2-.5-.5-.5z"
      fill="currentColor"
    />
    <path
      d="M9.5 7h-2c-.3 0-.5.2-.5.5s.2.5.5.5h2c.3 0 .5-.2.5-.5S9.8 7 9.5 7z"
      fill="currentColor"
    />
  </g>
);

const getIconBySize = (size: number) => {
  switch (size) {
    default:
      return { viewBox: '0 0 16 16', icon: <IconSize /> };
  }
};
// TODO THIS IS A PLACE HOLDER FOR THE REAL ICON LOGIC

export default ({ classes = {}, size = 40 }: NotificationIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
