import { Actions as AppActions } from '../actions/app';
import { Actions as UserActions } from '../actions/user';
import { locale, Locale, localeUs, MeasurementSystem } from '../types/shared';
import { UserRole } from '../types/user';

export const datePickerWorldFormat = 'yyyy-MM-dd';
export const datePickerUsFormat = 'MM-dd-yyyy';

export const datePickerDateFormatMap = {
  IMPERIAL: datePickerUsFormat,
  METRIC: datePickerWorldFormat,
};

export interface CurrentUser {
  id: number;
  firstName: string;
  lastName: string;
  role: UserRole;
}

export interface App {
  isInitialised: boolean;
  accessToken?: string;
  currentOrganizationId?: number;
  currentUser?: CurrentUser;
  locale: Locale;
}

const initialState: App = {
  isInitialised: false,
  locale,
};

const currentUserFromApiResponse = (data: any) => ({
  id: data.id,
  firstName: data.firstName,
  lastName: data.lastName,
  role: data.role,
});

const tokenMeta = (token: string) => {
  if (!token) return;

  const tokenData = JSON.parse(atob(token.split('.')[1]));

  return {
    currentOrganizationId: tokenData.org,
  };
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case AppActions.INIT:
    case AppActions.INIT_WITH_TOKEN:
      return {
        ...state,
        isInitialised: true,
      };

    case AppActions.ACCESS_TOKEN_UPDATED:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        ...tokenMeta(action.payload.accessToken),
      };

    case AppActions.CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: currentUserFromApiResponse(action.payload),
      };

    case UserActions.USER_INFO_SUCCESS:
      return {
        ...state,
        currentUser: currentUserFromApiResponse(action.payload),
        locale:
          action.payload.measurementSystem === MeasurementSystem.metric
            ? locale
            : localeUs,
      };

    case AppActions.ACCESS_TOKEN_CLEARED:
      return {
        ...initialState,
        isInitialised: true,
      };

    default:
      return state;
  }
};

export const isSignedIn = (state: App) => {
  return !!state.accessToken;
};
