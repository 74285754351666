import * as React from 'react';
import styles from './WizardStepper.module.css';
import classNames from '../../utils/classNames';
import { StepType } from '../../types/sentinelWizard';

type Props = {
  steps: any[];
  currentStep: StepType;
  setStep: (stepType: StepType) => void;
  stepsCompleted: { [key: string]: boolean };
  availableSteps: number[];
};

const WizardStepper = ({
  steps = [],
  currentStep,
  setStep,
  stepsCompleted,
  availableSteps,
}: Props) => (
  <div className={styles.root}>
    {steps.map((node, index) => {
      const isLastStep = index !== steps.length - 1;
      const stepNumber = index + 1;
      const stepCompleted = stepsCompleted[node.key] === true;

      const Step = (
        <div
          key={`${node.key}_step`}
          className={classNames([
            styles.step,
            //stepCompleted
            availableSteps.includes(node.key)
              ? styles.stepCompleted
              : undefined,
          ])}
          onClick={stepCompleted ? () => setStep(node.key) : undefined}
          onKeyPress={stepCompleted ? () => setStep(node.key) : undefined}
          role="button"
          tabIndex={0}
        >
          <div
            className={classNames([
              styles.currentStepNumber,
              currentStep === node.key
                ? styles.currentStepNumberActive
                : undefined,
              //stepCompleted
              availableSteps.includes(node.key)
                ? styles.currentStepNumberCompleted
                : undefined,
            ])}
          >
            {stepNumber}
          </div>

          <div
            className={classNames([
              styles.currentStepText,
              currentStep === node.key
                ? styles.currentStepTextActive
                : undefined,
              //stepCompleted
              availableSteps.includes(node.key)
                ? styles.currentStepTextCompleted
                : undefined,
            ])}
          >
            {node.title}
          </div>
        </div>
      );

      const Connector = (
        <div className={styles.spacer} key={`${node.key}_connector`}>
          <div
            className={classNames([
              styles.dashes,
              stepCompleted ? styles.dashesCompleted : undefined,
            ])}
          />
        </div>
      );

      return [Step, isLastStep ? Connector : null];
    })}
  </div>
);

export default WizardStepper;
