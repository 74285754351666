import { fetchApi } from '../middleware/apiMiddleware';
import { Space, SpaceApiFilter } from '../types/space';

export enum Actions {
  SPACES_SUCCESS = '@@spaces/SPACES_SUCCESS',
  SPACES_ERROR = '@@spaces/SPACES_ERROR',
  SPACES_LOADING = '@@spaces/SPACES_LOADING',
}

export const fetchSpaces = (filter?: SpaceApiFilter): Promise<Space[]> => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes && filter.sensorTypes.length > 0) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.withPath) {
    queryParams.set('withPath', 'true');
  }

  return fetchApi({
    endpoint: `/api/v1/spaces?${queryParams.toString()}`,
  });
};

export const fetchSpaceById = (spaceId: number): Promise<Space> =>
  fetchApi({
    endpoint: `/api/v1/spaces/${spaceId}/?withPath=true`,
  });

export const fetchSpaceByParentId = (parentId: number): Promise<Space[]> =>
  fetchApi({
    endpoint: `/api/v1/spaces/parent/${parentId}`,
  });
