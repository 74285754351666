import { fetchApi } from '../middleware/apiMiddleware';
import { Service } from '../config';
import { Sentinel, SentinelsApiFilter } from '../types/sentinel';

export enum Actions {
  SENTINELS_SUCCESS = '@@sentinels/SENTINELS_SUCCESS',
  DELETE_SUCCESS = '@@sentinels/DELETE_SUCCESS',
  MUTE_SUCCESS = '@@sentinels/MUTE_SUCCESS',
  RESUME_SUCCESS = '@@sentinels/RESUME_SUCCESS',
}

export const fetchSentinels = (filter?: SentinelsApiFilter) => {
  const queryParams = new URLSearchParams();

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.toString());
  }

  if (filter && filter.siteIds) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.spaceIds) {
    queryParams.set('spaceIds', filter.spaceIds.join(','));
  }

  if (filter && filter.sensorIds) {
    queryParams.set('sensorIds', filter.sensorIds.join(','));
  }

  return fetchApi({
    endpoint: `/api/v1/sentinels/?${queryParams.toString()}`,
    method: 'GET',
    service: Service.sentinel,
  });
};

export const fetchSentinelsCreate = (payload: Sentinel): Promise<any> =>
  fetchApi({
    endpoint: payload.id
      ? `/api/v1/sentinels/${payload.id}`
      : `/api/v1/sentinels`,
    method: payload.id ? 'PUT' : 'POST',
    service: Service.sentinel,
    payload,
  });

export const deleteSentinel = (sentinelId: string): Promise<any> =>
  fetchApi({
    endpoint: `/api/v1/sentinels/${sentinelId}`,
    method: 'DELETE',
    service: Service.sentinel,
  });

export const muteSentinel = (
  sentinelId: string,
  reason: string,
  mutedUntil: string | undefined
): Promise<any> => {
  return fetchApi({
    endpoint: `/api/v1/sentinels/${sentinelId}/mute`,
    method: 'POST',
    service: Service.sentinel,
    payload: {
      reason,
      mutedUntil,
    },
  });
};

export const resumeSentinel = (sentinelId: string): Promise<any> =>
  fetchApi({
    endpoint: `/api/v1/sentinels/${sentinelId}/mute`,
    method: 'DELETE',
    service: Service.sentinel,
  });

export const getSentinelById = (sentinelId: string): Promise<any> =>
  fetchApi({
    endpoint: `/api/v1/sentinels/${sentinelId}`,
    method: 'GET',
    service: Service.sentinel,
  });
