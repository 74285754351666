import * as React from 'react';
import classNames from '../../utils/classNames';

import styles from './SearchWrapper.module.css';

type Props = {
  children: React.ReactNode;
  wrapperRef: any;
  active: boolean;
  width: string;
  widthActive: string;
};

const SearchWrapper = ({
  children,
  wrapperRef,
  active,
  width,
  widthActive,
}: Props) => {
  const searchWrapperRef = React.createRef();

  wrapperRef(searchWrapperRef);

  return (
    <div
      className={styles.root}
      style={{ width: active ? widthActive : width }}
    >
      <div
        // @ts-ignore todo: ts-fix
        ref={searchWrapperRef}
        className={classNames(
          styles.wrapper,
          active ? styles.wrapperOpen : null
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default SearchWrapper;
