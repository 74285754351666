import React, { Component } from 'react';
import { connect } from 'react-redux';

import NotificationList from '../../Tables/NotificationList';
import { dismissNotificationById } from '../../../actions/notifications';
import { Notification, SortOptionValues } from '../../../types/notification';
import { Sensor } from '../../../types/sensors';
import DropDown from '../../DropDown';
import styles from './ViewNotificationIndex.module.css';
import { RouteComponentProps } from '@reach/router';
import { closeDialog, openDialog } from '../../../actions/dialog';
import { Dialog } from '../../../types/dialog';

type State = {
  selectedNotification: string;
};

type Props = {
  hasError: boolean;
  isLoading: boolean;
  notifications: Notification[];
  dismissNotificationById: (notificationId: string, comment: string) => void;
  sensors: Sensor[];
  handleSort: (sortOptionValue: SortOptionValues) => void;
  openDialog: any;
  closeDialog: any;
  dialog: Dialog;
} & RouteComponentProps;

class ViewNotificationIndex extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedNotification: '',
    };
  }

  openDismissModal = (notificationId: string) =>
    this.props.openDialog({
      title: 'Dismiss',
      type: 'DISMISS_NOTIFICATION',
      targetId: notificationId,
      actionFn: ({ notificationId, comment }) =>
        this.props.dismissNotificationById(notificationId, comment),
    });

  renderList = () => {
    const { notifications, hasError = false } = this.props;

    // TODO pass isFetch and hasError to Notification list and display errors/loading from there
    if (hasError) return <p>Error during fetch</p>;
    return (
      <NotificationList
        items={notifications}
        onDismissClick={this.openDismissModal}
      />
    );
  };

  render() {
    const sortItems = [
      { text: 'Date', value: 'date' },
      { text: 'Unseen on Top', value: 'unseen' },
    ];

    return (
      <>
        <div className={styles.dropdownPaddingBottom}>
          <DropDown
            labelText="Sort by"
            onChange={this.props.handleSort}
            sortItems={sortItems}
          />
        </div>

        {this.renderList()}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  notificationFilter: state.notificationFilter,
  notifications: state.notifications,
  sensors: state.sensors,
  resources: state.resources,
  dialog: state.dialog,
});

const mapDispatchToProps = {
  dismissNotificationById,
  openDialog,
  closeDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewNotificationIndex);
