import * as React from 'react';
import MenuFilterButton from '../../ui/FilterButton/FilterButton';
import styles from './FilterDropdown.module.css';

type Props = {
  title: string;
  children: React.ReactNode;
  size?: 'regular' | 'large';
};

type State = {
  open: boolean;
};

class FilterDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleButtonClick = () =>
    this.setState(prevState => ({ open: !prevState.open }));

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef && this.wrapperRef.current) {
      if (this.wrapperRef.current.contains(event.target) === false) {
        this.close();
      }
    }
  };

  close = () => this.setState(() => ({ open: false }));

  wrapperRef: any;

  render() {
    const { open } = this.state;
    const { children, title, size = 'regular' } = this.props;
    return (
      <div className={styles.wrapper} ref={this.wrapperRef}>
        <MenuFilterButton onClick={this.handleButtonClick} open={open}>
          {title}
        </MenuFilterButton>

        {open && (
          <div className={`${styles.dropDownWrapper} ${styles[size]}`}>
            {children &&
              React.Children.map(children, (child: any) =>
                child !== null
                  ? React.cloneElement(child, { close: this.close })
                  : child
              )}
          </div>
        )}
      </div>
    );
  }
}

export default FilterDropdown;
