import { Dialog, DialogTypes } from '../types/dialog';
import { Actions as DialogActions } from '../actions/dialog';
import { Actions as NotificationActions } from '../actions/notifications';

const initialState: Dialog = {
  isOpen: false,
};

export default (state: Dialog = initialState, action: any) => {
  switch (action.type) {
    case DialogActions.DIALOG_OPEN: {
      return {
        isOpen: true,
        type: DialogTypes.DEFAULT,
        ...action.payload,
      };
    }

    case NotificationActions.DISMISS_SUCCESS:
    case DialogActions.DIALOG_CLOSE: {
      return initialState;
    }

    default:
      return state;
  }
};
