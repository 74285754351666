import * as React from 'react';
import { SensorIcon } from '../../../types/icon';
import { ParametersSwitch } from '../../../types/sentinel';

const IconSize16 = () => (
  <g>
    <path
      d="M15.5 13h-2c-.3 0-.5.2-.5.5s.2.5.5.5h2c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM2.5 14h-2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h2c.3 0 .5.2.5.5s-.2.5-.5.5z"
      fill="currentColor"
    />
    <path d="M13 3v10h-2V3h2m1-1h-4v12h4V2z" fill="currentColor" />
    <path
      d="M10 1.2v13.5l-7-1.6V2.8l7-1.6M11 0L2 2v12l9 2V0z"
      fill="currentColor"
    />
    <path
      d="M5.4 10c-.2 0-.4-.1-.5-.3-.2-.6-.3-1.1-.3-1.7 0-.6.1-1.1.3-1.7.1-.2.4-.4.7-.3.2.1.4.4.3.7-.2.4-.3.9-.3 1.3s.1.9.3 1.3c.1.3-.1.6-.3.7h-.2z"
      fill="currentColor"
    />
    <circle cx="8" cy="8" r="1" fill="currentColor" />
  </g>
);

const IconSize32 = () => (
  <g>
    <path
      d="M27.5 28h-4c-.3 0-.5.2-.5.5s.2.5.5.5h4c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM31.5 28h-2c-.3 0-.5.2-.5.5s.2.5.5.5h2c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM7.5 29h-7c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h7c.3 0 .5.2.5.5s-.2.5-.5.5z"
      fill="currentColor"
    />
    <path d="M24 4v24h-3V4h3m1-1h-5v26h5V3z" fill="currentColor" />
    <path
      d="M20 1.2v29.5L8 28.2V3.8l12-2.6M21 0L7 3v26l14 3V0z"
      fill="currentColor"
    />
    <path
      d="M17.5 16.5c.3 0 .5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5m0-1c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5S19 17.8 19 17s-.7-1.5-1.5-1.5zM14.5 19c-.2 0-.4-.1-.5-.4-.2-.5-.3-1.1-.3-1.6 0-.6.1-1.1.3-1.6.1-.3.4-.4.6-.3.3.1.4.4.3.7-.1.4-.2.8-.2 1.3 0 .4.1.9.2 1.3.1.3 0 .6-.3.7 0-.1-.1-.1-.1-.1zM11.9 20c-.2 0-.4-.1-.5-.3-.2-.9-.4-1.8-.4-2.7s.2-1.8.5-2.7c.1-.3.4-.4.6-.3.3.1.4.4.3.7-.3.7-.4 1.5-.4 2.3 0 .8.1 1.6.4 2.3.1.3 0 .6-.3.7h-.2z"
      fill="currentColor"
    />
  </g>
);

const IconSize40 = () => (
  <g>
    <g opacity=".1">
      <path
        d="M33.5 34.8H31v-25h-4v-3l-14 3v25H6.5c-.3 0-.5.2-.5.5s.2.5.5.5H13l14 3v-3h6.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM37.5 34.8h-2c-.3 0-.5.2-.5.5s.2.5.5.5h2c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"
        fill="currentColor"
      />
    </g>
    <path
      d="M30.5 31.8h-4c-.3 0-.5.2-.5.5s.2.5.5.5h4c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM34.5 31.8h-2c-.3 0-.5.2-.5.5s.2.5.5.5h2c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM10.5 32.8h-7c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h7c.3 0 .5.2.5.5s-.2.5-.5.5z"
      fill="currentColor"
    />
    <path d="M27 7.8v24h-3v-24h3m1-1h-5v26h5v-26z" fill="currentColor" />
    <path
      d="M23 5v29.5L11 32V7.6L23 5m1-1.2l-14 3v26l14 3v-32z"
      fill="currentColor"
    />
    <path
      d="M20.5 20.3c.3 0 .5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5m0-1c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zM17.5 22.8c-.2 0-.4-.1-.5-.4-.2-.5-.3-1.1-.3-1.6s.1-1.1.3-1.6c.1-.3.4-.4.6-.3.3.1.4.4.3.7-.1.4-.2.8-.2 1.3 0 .4.1.9.2 1.3.1.3 0 .6-.3.7 0-.1-.1-.1-.1-.1zM14.9 23.8c-.2 0-.4-.1-.5-.3-.3-.9-.5-1.8-.5-2.7s.2-1.8.5-2.7c.1-.3.4-.4.6-.3.3.1.4.4.3.7-.2.7-.3 1.5-.3 2.3 0 .8.1 1.6.4 2.3.1.3 0 .6-.3.7h-.2z"
      fill="currentColor"
    />
  </g>
);

const IconSize80Open = () => (
  <g>
    <path
      d="M76 66H59c-.6 0-1 .4-1 1s.4 1 1 1h17c.6 0 1-.4 1-1s-.4-1-1-1zM72.5 62h-10c-.3 0-.5.2-.5.5s.2.5.5.5h10c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM17.5 62h-10c-.3 0-.5.2-.5.5s.2.5.5.5h10c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM17 68H3c-.6 0-1-.4-1-1s.4-1 1-1h14c.6 0 1 .4 1 1s-.4 1-1 1z"
      fill="currentColor"
    />
    <path d="M57 10.8V66h-8V10.8h8m2-2H47V68h12V8.8z" fill="currentColor" />
    <path
      d="M47 2.5v70l-28-6.1V8.5l28-6M49 0L17 6.8V68l32 7V0z"
      fill="currentColor"
    />
    <path d="M62 6v61H48.5V6H62m1-1H47.5v63H63V5z" fill="currentColor" />
    <path
      d="M34.8 43c-.6-1.1-1-2.4-1-3.7s.3-2.6 1-3.7M31.9 44.6c-.9-1.6-1.4-3.5-1.4-5.3s.5-3.7 1.4-5.3M29 46.3c-1.2-2.1-1.9-4.5-1.9-7s.6-4.9 1.9-7"
      stroke="currentColor"
      fill="none"
    />
    <g>
      <path
        d="M40.6 37.3c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-1c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.4-3-3-3z"
        fill="currentColor"
      />
    </g>
    <path
      d="M79 72h-7v-3h5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-5V11H26v57h-9.5c-.3 0-.5.2-.5.5s.2.5.5.5H26v3H12c-.6 0-1 .4-1 1s.4 1 1 1h67c.6 0 1-.4 1-1s-.4-1-1-1z"
      opacity=".1"
      fill="currentColor"
    />
  </g>
);

const IconSize80Closed = () => (
  <g>
    <path
      d="M79 72h-7v-3h5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-5V11H26v57h-9.5c-.3 0-.5.2-.5.5s.2.5.5.5H26v3H12c-.6 0-1 .4-1 1s.4 1 1 1h67c.6 0 1-.4 1-1s-.4-1-1-1z"
      opacity=".1"
      fill="currentColor"
    />
    <path
      d="M76 66H59c-.6 0-1 .4-1 1s.4 1 1 1h17c.6 0 1-.4 1-1s-.4-1-1-1zM73.5 62h-10c-.3 0-.5.2-.5.5s.2.5.5.5h10c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"
      fill="currentColor"
    />
    <path d="M58 11v55H24V11h34m2-2H22v59h38V9z" fill="currentColor" />
    <path d="M63 6v61H19V6h44m1-1H18v63h46V5z" fill="currentColor" />
    <path
      d="M46.2 43c-.6-1.1-1-2.4-1-3.7s.3-2.6 1-3.7M43.3 44.6c-.9-1.6-1.4-3.5-1.4-5.3s.5-3.7 1.4-5.3M40.4 46.3c-1.2-2.1-1.9-4.5-1.9-7s.6-4.9 1.9-7"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M52 37.3c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-1c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zM18.5 62h-10c-.3 0-.5.2-.5.5s.2.5.5.5h10c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM18 68H4c-.6 0-1-.4-1-1s.4-1 1-1h14c.6 0 1 .4 1 1s-.4 1-1 1z"
      fill="currentColor"
    />
  </g>
);

const getIcon = (size: number, parameters: ParametersSwitch) => {
  if (parameters && parameters.openClosed === 'CLOSED') {
    return { viewBox: '0 0 80 80', icon: <IconSize80Closed /> };
  }

  switch (true) {
    case size < 32:
      return { viewBox: '0 0 16 16', icon: <IconSize16 /> };

    case size < 40:
      return { viewBox: '0 0 32 32', icon: <IconSize32 /> };

    case size === 40:
      return { viewBox: '0 0 40 40', icon: <IconSize40 /> };

    default:
      return { viewBox: '0 0 80 80', icon: <IconSize80Open /> };
  }
};

export default ({ classes = {}, size = 40, parameters = {} }: SensorIcon) => {
  const { viewBox, icon } = getIcon(size, parameters);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
