import { getPathByResourceId } from '../../actions/resources';
import { connect } from 'react-redux';
import SourceChip from '../../components/SourceChip';

const mapStateToProps = (state: any, ownProps: any) => ({
  path: state.resources[ownProps.resourceId] || [],
});

const mapDispatchToProps = {
  getPathByResourceId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourceChip);
