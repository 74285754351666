import * as React from 'react';
import { navigate, Router } from '@reach/router';

import { Sentinel, SentinelsApiFilter } from '../../../types/sentinel';
import {
  deleteSentinel,
  fetchSentinels,
  muteSentinel,
  resumeSentinel,
} from '../../../actions/sentinels';
import * as Routes from '../../../routes';
import { fetchSiteByResourceId } from '../../../actions/sites';
import styles from '../../views/notifications/ViewNotificationConfiguration.module.css';
import ExitWizardModal from '../../Modals/ExitWizardModal';
import backgroundPattern from '../../../static/patterns/pattern-notification-configuration.png';
import IconUiFactory from '../../icons/IconUiFactory';
import Button from '../../ui/Button/Button';
import SentinelWizard from '../../../containers/SentinelWizard/SentinelWizard';
import SentinelsConfigurationList from '../../../containers/SentinelsConfigurationList';

type Props = {
  location?: {
    pathname?: string;
  };
  hideWizard?: boolean;
  equipmentIds?: number[];
};

type State = {
  sentinels: Sentinel[];
  hasError: boolean;
  showExitModal: boolean;
  siteCountBySentinelIds: Object;
};

class SentinelListContainer extends React.Component<Props, State> {
  state = {
    sentinels: [],
    hasError: false,
    showExitModal: false,
    siteCountBySentinelIds: {},
  };

  componentDidMount = () => {
    this.handleFetchSentinels();
  };

  onDeleteSentinel = (sentinel: Sentinel) => {
    deleteSentinel(sentinel.id)
      .then(() =>
        this.setState(prevState => ({
          sentinels: prevState.sentinels.filter(s => s.id !== sentinel.id),
        }))
      )
      .catch(() => this.setState({ hasError: true }));
  };

  onMuteSentinel = ({
    sentinelId,
    reason,
    mutedUntil,
  }: {
    sentinelId: string;
    reason: string;
    mutedUntil: string | undefined;
  }) => {
    muteSentinel(sentinelId, reason, mutedUntil)
      .then(() => {
        const { sentinels }: { sentinels: Sentinel[] } = this.state;
        const updatedSentinel = sentinels.find(
          sentinel => sentinel.id === sentinelId
        );

        if (!updatedSentinel) {
          throw new Error('sentinel not found');
        }

        updatedSentinel.muted = true;
        updatedSentinel.reason = reason;

        this.setState({
          sentinels: [...sentinels],
        });
      })
      .catch(() => this.setState({ hasError: true }));
  };

  onResumeSentinel = ({ sentinelId }: { sentinelId: string }) => {
    resumeSentinel(sentinelId)
      .then(() => {
        const { sentinels }: { sentinels: Sentinel[] } = this.state;
        const updatedSentinel = sentinels.find(item => item.id === sentinelId);

        if (!updatedSentinel) {
          throw new Error('sentinel not found');
        }

        updatedSentinel.muted = false;
        updatedSentinel.reason = '';

        this.setState({
          sentinels: [...sentinels],
        });
      })
      .catch(() => this.setState({ hasError: true }));
  };

  onSentinelCreateOrUpdate = () =>
    this.handleFetchSentinels().then(() => this.navigateToNotifications());

  onExitSubmit = () => {
    this.setState({ showExitModal: !this.state.showExitModal });
    navigate(Routes.NOTIFICATIONS_CONFIGURATION);
  };

  handleExitWizard = () =>
    this.setState({ showExitModal: !this.state.showExitModal });

  navigateToNotifications = () => navigate(Routes.NOTIFICATIONS_CONFIGURATION);

  handleErrorClick = () => this.setState(() => ({ hasError: false }));

  handleFetchSentinels = async () => {
    try {
      const filter: SentinelsApiFilter = {};

      if (this.props.equipmentIds) {
        filter.equipmentIds = this.props.equipmentIds;
      }

      const sentinels = await fetchSentinels(filter);
      this.setState({ sentinels, hasError: false });
      // This crushes IAM need to remove for now
      // await this.handleGetSitesByResource(sentinels);
    } catch (err) {
      this.setState({ hasError: true });
    }
  };

  handleGetSitesByResource = (sentinels: Sentinel[] = []) => {
    const resourceIds = ([] as number[]).concat(
      ...sentinels.map(sentinel =>
        sentinel.targets.map(target => target.targetId)
      )
    );

    return Promise.all(resourceIds.map(id => fetchSiteByResourceId(id))).then(
      sites => {
        const resourcesToSite = resourceIds.reduce((acc, cur, index) => {
          acc[cur] = sites[index];
          return acc;
        }, {});

        const siteCountBySentinelIds = sentinels.reduce((acc, sentinel) => {
          let items = sentinel.targets
            .map(target => resourcesToSite[target.targetId])
            .filter(site => site !== undefined)
            .map(site => site.id);
          // Make it an array of unique items;
          items = [...new Set(items)];

          acc[sentinel.id] = items.length;

          return acc;
        }, {});

        this.setState({
          siteCountBySentinelIds,
        });
      }
    );
  };

  render() {
    const { location = {}, hideWizard } = this.props;
    const { pathname = '' } = location;

    const { sentinels, hasError, siteCountBySentinelIds } = this.state;

    const wizardIsActive = pathname.includes('wizard');
    return (
      <div className={styles.root}>
        {this.state.showExitModal && (
          <ExitWizardModal
            onSubmit={this.onExitSubmit}
            onClose={this.handleExitWizard}
          />
        )}

        {!hideWizard && (
          <div className={styles.wrapper}>
            {!wizardIsActive && (
              <div
                className={styles.hero}
                style={{ backgroundImage: `url(${backgroundPattern})` }}
              >
                <div className={styles.heroContent}>
                  <div className={styles.icon}>
                    <IconUiFactory size={80} id="bell-config" />
                  </div>
                  <h3 className={styles.title}>Add Configuration</h3>

                  <Button onClick={() => navigate('configuration/wizard')}>
                    Add new
                  </Button>
                </div>
              </div>
            )}

            <Router>
              <SentinelWizard
                path="wizard"
                onExit={this.handleExitWizard}
                onUpdateOrCreate={this.onSentinelCreateOrUpdate}
              />
              <SentinelWizard
                path="wizard/:sentinelId"
                onExit={this.handleExitWizard}
                onUpdateOrCreate={this.onSentinelCreateOrUpdate}
              />
            </Router>
          </div>
        )}

        <SentinelsConfigurationList
          siteCountBySentinelIds={siteCountBySentinelIds}
          data={sentinels}
          hasError={hasError}
          onExit={this.navigateToNotifications}
          onUpdateOrCreate={this.onSentinelCreateOrUpdate}
          onMuteSentinel={this.onMuteSentinel}
          onDeleteSentinel={this.onDeleteSentinel}
          onResumeSentinel={this.onResumeSentinel}
          onDismissError={this.handleErrorClick}
        />
      </div>
    );
  }
}

export default SentinelListContainer;
