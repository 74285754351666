import { Space } from '../types/space';
import { Actions } from '../actions/spaces';

const initialState: { [key: string]: Space } = {};

export default (
  state: { [key: string]: Space } = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case Actions.SPACES_SUCCESS: {
      return { ...state, ...action.payload };
    }

    default: {
      return state;
    }
  }
};
