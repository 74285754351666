import * as React from 'react';
import {
  defaultBinaryCounterParameters,
  ParametersBinaryCounter,
} from '../../../../types/sentinel';

import StepConditionItem from './StepConditionItem';

import FormText from '../../../ui/FormText';
import StepConditionItemInput from './StepConditionItemInput';
import TextField from '../../../ui/TextField';

type Props = {
  onChange: (arg0: string, arg1: string) => void;
  parameters?: ParametersBinaryCounter;
};

const ConditionBinaryCounter = ({
  onChange,
  parameters = defaultBinaryCounterParameters,
}: Props) => (
  <StepConditionItem
    label={<FormText>Openings:</FormText>}
    condition={
      <StepConditionItemInput
        value={
          <TextField
            type="number"
            value={
              parameters.maxOpeningCount
                ? parameters.maxOpeningCount.toString()
                : '5'
            }
            onChange={ev => onChange('maxOpeningCount', ev.currentTarget.value)}
          />
        }
        unit={<FormText>Times</FormText>}
      />
    }
  />
);

export default ConditionBinaryCounter;
