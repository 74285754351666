import * as React from 'react';
import classNames from '../../../../utils/classNames';
import styles from './ConditionCustomerComfort.module.css';
import GasStationIcon from '../../../icons/sites/GasStation';
import IconFoodProcessing from '@energybox/react-ui/core/Icons/Equipment/FoodProcessing';
import IconAccessTransportationElevator from '@energybox/react-ui/core/Icons/Equipment/AccessTransportationElevator';
import IconFoodHolding from '@energybox/react-ui/core/Icons/Equipment/FoodHolding';
import { ParametersCustomerComfort } from '../../../../types/sentinel';
import StepTitle from '../StepTitle';

type MetabolicRate = {
  rate: number;
  name: string;
  description: string;
};

type ComfortSpaceCardProps = {
  metabolicRate: MetabolicRate;
  onChange: (name: string, value: number) => void;
  currentRate: number;
};

const metabolicRates: MetabolicRate[] = [
  {
    rate: 1.1,
    name: 'Seated',
    description: 'Restaurant dining area, School, Office',
  },
  {
    rate: 1.3,
    name: 'Walking',
    description: 'Convenience Store, Grocery Store, Mall',
  },
  {
    rate: 1.8,
    name: 'Medium Activity',
    description: 'Restaurant kitchen',
  },
  {
    rate: 2.1,
    name: 'Heavy Activity',
    description: 'Store room',
  },
];

const activityIcon = (metabolicRate: MetabolicRate) => {
  switch (metabolicRate.rate) {
    case 1.1:
      return <IconFoodHolding classes={styles.icon} size={80} />;
    case 1.3:
      return <GasStationIcon classes={styles.icon} size={80} />;
    case 1.8:
      return <IconFoodProcessing classes={styles.icon} size={80} />;
    case 2.1:
      return (
        <IconAccessTransportationElevator classes={styles.icon} size={80} />
      );
  }
};

const ComfortSpaceCard = ({
  metabolicRate,
  onChange,
  currentRate,
}: ComfortSpaceCardProps) => (
  <div
    key={metabolicRate.rate}
    onClick={() => onChange('metabolicRate', metabolicRate.rate)}
    onKeyPress={() => onChange('metabolicRate', metabolicRate.rate)}
    className={classNames(
      styles.card,
      currentRate == metabolicRate.rate ? styles.cardSelected : undefined
    )}
    role="button"
  >
    <div className={styles.iconWrapper}>{activityIcon(metabolicRate)}</div>
    <h3 className={styles.cardTitle}>{metabolicRate.name}</h3>
    <span className={styles.cardDescription}>
      {' '}
      <b>Examples:</b> <p> {metabolicRate.description} </p>{' '}
    </span>
  </div>
);

type ConditionComfortProps = {
  onChange: (name: string, value: number) => void;
  parameters: ParametersCustomerComfort;
};

const ConditionCustomerComfort = ({
  onChange,
  parameters,
}: ConditionComfortProps) => (
  <>
    <StepTitle>Select the activity level of the space(s)</StepTitle>
    <div className={styles.metabolicRates}>
      {metabolicRates.map(s => (
        <ComfortSpaceCard
          metabolicRate={s}
          onChange={onChange}
          currentRate={parameters.metabolicRate}
        />
      ))}
    </div>
  </>
);

export default ConditionCustomerComfort;
