import * as React from 'react';
import TextField from '../ui/TextField';
import styles from './SentinelMetaInput.module.css';

type Props = {
  handleNameChange: any;
  title: string;
  description: string;
  nextIsClicked: boolean;
};

const SentinelMetaInput = ({
  handleNameChange,
  title,
  description,
  nextIsClicked,
}: Props) => (
  <div className={styles.container}>
    <div>
      <TextField
        isMainTitle
        value={title}
        placeholder="Title"
        onChange={handleNameChange('title')}
        error={nextIsClicked && title === ''}
        autoFocus={true}
      />
      <TextField
        value={description}
        placeholder="Your description"
        onChange={handleNameChange('description')}
        extraClassName={styles.descriptionField}
      />
    </div>
  </div>
);

export default SentinelMetaInput;
