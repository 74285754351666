import * as React from 'react';

export default function getHighlightedText(
  text: string = '',
  highlight: string,
  className: string
): any {
  if (!text) {
    return '';
  }

  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  if (!parts) {
    return '';
  }

  return parts.map((part: string, index: number) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      // eslint-disable-next-line react/no-array-index-key
      <span key={`${part}${index}`} className={className}>
        {part}
      </span>
    ) : (
      part
    )
  );
}
