import * as React from 'react';
import styles from './FilterMenuItem.module.css';
import Checkbox from '../../../ui/Checkbox';
import getHighlightedText from '../../../../utils/getHighlightedText';

type Props = {
  checked?: boolean;
  onClick: (id: number) => void;
  id: number;
  title: string;
  description?: string;
  highlight?: string;
};

const FilterMenuItem: React.FunctionComponent<Props> = ({
  id,
  title,
  onClick,
  description = '',
  checked = false,
  highlight = '',
}) => (
  <li className={styles.listWrapper}>
    <Checkbox
      checked={checked}
      className={styles.checkbox}
      size={16}
      onChange={() => onClick(id)}
      fullWidth
    >
      <div className={styles.textWrapper}>
        <span className={styles.title}>
          {getHighlightedText(title, highlight, styles.highlightedText)}
        </span>
        {description && (
          <span className={styles.description}>
            {getHighlightedText(description, highlight, styles.highlightedText)}
          </span>
        )}
      </div>
    </Checkbox>
  </li>
);

export default FilterMenuItem;
