import React from 'react';
import styles from './ResumeSentinelModal.module.css';
import Button from '../ui/Button';
import BaseModal from './BaseModal';
import { Sentinel } from '../../types/sentinel';
import { deriveDateValue } from '../../utils/dates';

type Props = {
  configurationName?: string;
  sentinelResumeDate?: string;
  onSubmit: any;
  onClose: any;
  sentinel: Sentinel;
};

export default ({ onSubmit, onClose, sentinel }: Props) => (
  <BaseModal
    onClose={onClose}
    title={`Would you like to resume the Notifications for ${sentinel.title} ?`}
  >
    {sentinel.mutedUntil ? (
      <div className={styles.infoText}>
        The notifications will automatically resume at{' '}
        {deriveDateValue(sentinel.mutedUntil, false)}
      </div>
    ) : null}
    <div className={styles.buttonGroup}>
      <Button onClick={() => onSubmit(sentinel.id)}>Yes</Button>
      <Button onClick={onClose} secondary>
        Cancel
      </Button>
    </div>
  </BaseModal>
);
