import * as React from 'react';

import FilterDropdown from '../FilterDropdown';
import SearchBox from '../../search/SearchBox';
import FilterMenuItem from '../FilterItems/FilterMenuItem';
import { fetchSites } from '../../../actions/sites';
import { Site } from '../../../types/site';
import { filterSites } from '../../../utils/textFilters';
import Button from '../../ui/Button/Button';

type Props = {
  setFilter: (filter: number[]) => void;
  siteIds: number[];
  initiallySelectedSites?: number[];
};

type State = {
  query: string;
  sites: Site[];
  isLoading: boolean;
  selectedSiteIds: Set<number>;
};

class SitePicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      query: '',
      sites: [],
      selectedSiteIds: new Set(props.siteIds),
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.loadSites();
  };

  handleSearch = (value: string) =>
    this.setState({
      query: value,
    });

  async loadSites() {
    this.setState({ isLoading: true });
    try {
      const sites = await fetchSites();
      this.setState({ sites, isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  handleSelectSite = (siteId: number) => {
    const { selectedSiteIds } = this.state;

    if (selectedSiteIds.has(siteId)) {
      selectedSiteIds.delete(siteId);
    } else {
      selectedSiteIds.add(siteId);
    }

    this.setState({ selectedSiteIds });
  };

  toggleSelectAll = () => {
    const { selectedSiteIds, sites } = this.state;
    if (selectedSiteIds.size !== 0) {
      this.setState({ selectedSiteIds: new Set() });
    } else {
      sites.forEach(site => selectedSiteIds.add(site.id));
      this.setState({ selectedSiteIds });
    }
  };

  renderActions = (): React.ReactNode => {
    const { selectedSiteIds } = this.state;

    return (
      <Button
        size="small"
        onClick={() => this.props.setFilter([...selectedSiteIds])}
      >
        {`${selectedSiteIds.size} sites`}
      </Button>
    );
  };

  render() {
    const { query, sites, selectedSiteIds } = this.state;

    const lowerCaseFilterValue = query.toLowerCase();
    const renderItems = sites
      .filter(filterSites.bind(this, lowerCaseFilterValue))
      .map(site => (
        <FilterMenuItem
          key={site.id}
          id={site.id}
          title={site.title}
          description={site.address}
          onClick={() => this.handleSelectSite(site.id)}
          checked={selectedSiteIds.has(site.id)}
          highlight={query}
        >
          {site.title}
        </FilterMenuItem>
      ));

    return (
      <FilterDropdown
        title={
          this.props.siteIds.length
            ? `${this.props.siteIds.length} Sites`
            : 'Sites'
        }
        size="regular"
      >
        {
          <SearchBox
            placeholder="Sites, City, Zip..."
            query={query}
            onChange={this.handleSearch}
            onSelectAll={this.toggleSelectAll}
            items={renderItems}
            width="29.17rem"
            widthActive="29.17rem"
            withDropdown
            selectAllText={
              selectedSiteIds.size !== 0 ? 'Unselect all' : 'Select all'
            }
            actions={this.renderActions()}
            startOpen
            hasCloseButton
            error={renderItems.length === 0}
          />
        }
      </FilterDropdown>
    );
  }
}

export default SitePicker;
