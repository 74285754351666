import { Site } from '../types/site';
import { Equipment } from '../types/equipment';

export const filterSites = (textFilter: string = '', site: Site) => {
  // The api returns nulls which means the above line will not cast default values
  // The below however will override null or undefined as strings.

  const { title = '', externalId = '', address = '' } = site;

  const lowerCaseTextFilter = textFilter.toLowerCase();

  const lowerCaseTitle = (title || '').toLowerCase();
  const lowerCaseExternalId = (externalId || '').toLowerCase();
  const lowerCaseAddress = (address || '').toLowerCase();

  return (
    lowerCaseTitle.indexOf(lowerCaseTextFilter) > -1 ||
    lowerCaseAddress.indexOf(lowerCaseTextFilter) > -1 ||
    lowerCaseExternalId.indexOf(lowerCaseTextFilter) > -1
  );
};

export const filterEquipment = (
  textFilter: string = '',
  equipment: Equipment
) => {
  const { description = '', title = '' } = equipment;

  const lowerCaseTextFilter = textFilter.toLowerCase();

  const lowerCaseTitle = (title || '').toLowerCase();
  const lowerCaseDescription = (description || '').toLowerCase();

  return (
    lowerCaseTitle.indexOf(lowerCaseTextFilter) > -1 ||
    lowerCaseDescription.indexOf(lowerCaseTextFilter) > -1
  );
};
