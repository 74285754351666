import * as React from 'react';
import styles from './StepSentinelType.module.css';
import classNames from '../../../utils/classNames';
import IconSensorTypeFactory from '../../icons/IconSensorTypeFactory';
import { SentinelConfiguration, SentinelType } from '../../../types/sentinel';
import { UserRole } from '../../../types/user';

type StepSensorTypeCardProps = {
  onChange: (sentinelType: SentinelType) => void;
  sentinelType: SentinelType;
  sentinel: any;
};

const StepSensorTypeCard = ({
  sentinel,
  sentinelType,
  onChange,
}: StepSensorTypeCardProps) => (
  <div
    key={sentinel.type}
    onClick={() => onChange(sentinel.type)}
    onKeyPress={() => onChange(sentinel.type)}
    className={classNames(
      styles.card,
      sentinelType === sentinel.type ? styles.cardSelected : undefined
    )}
    role="button"
    tabIndex={0}
  >
    <div className={styles.iconWrapper}>
      {IconSensorTypeFactory({ id: sentinel.type, size: 80 })}
    </div>
    <h3 className={styles.cardTitle}>{sentinel.title}</h3>
    <p className={styles.cardDescription}>{sentinel.description}</p>
  </div>
);

type StepTypeProps = {
  onChange: (sentinelType: SentinelType) => void;
  sentinelType: SentinelType;
  userRole: UserRole;
  sentinelTypesList: SentinelConfiguration[];
};

const StepSentinelType = ({
  onChange,
  sentinelType,
  userRole,
  sentinelTypesList,
}: StepTypeProps) => (
  <div className={styles.root}>
    {sentinelTypesList
      .filter(sentinel =>
        sentinel.featureFlag ? userRole === UserRole.ADMIN : true
      )
      .map(s => (
        <StepSensorTypeCard
          key={`StepType${s.type}`}
          sentinelType={sentinelType}
          sentinel={s}
          onChange={onChange}
        />
      ))}
  </div>
);

export default StepSentinelType;
