import * as React from 'react';
import { SensorIcon } from '../../../types/icon';

const IconSize80 = () => (
  <g fill="currentColor">
    <g opacity=".1">
      <path d="m78.1 24.2c-2.4-1.4-5.5-1.4-7.8 0-.2.1-.3.4-.2.7.1.2.4.3.7.2 2.1-1.2 4.8-1.2 6.8 0 .1 0 .2.1.2.1.2 0 .3-.1.4-.2.2-.3.1-.6-.1-.8z" />
      <path d="m79.7 21.3c-3.4-2-7.8-2-11.2 0-.2.1-.3.4-.2.7.1.2.4.3.7.2 3.1-1.8 7.1-1.8 10.2 0 .1 0 .2.1.2.1.2 0 .3-.1.4-.2.3-.3.2-.6-.1-.8z" />
      <path d="m19.6 25.1c.1 0 .2.1.2.1.2 0 .3-.1.4-.2.1-.2.1-.5-.2-.7-2.4-1.4-5.5-1.4-7.8 0-.2.1-.3.4-.2.7.1.2.4.3.7.2 2.1-1.3 4.8-1.3 6.9-.1z" />
      <path d="m21.2 22.2c.1 0 .2.1.2.1.2 0 .3-.1.4-.2.1-.2.1-.5-.2-.7-3.4-2-7.8-2-11.2 0-.2.1-.3.4-.2.7.1.2.4.3.7.2 3.3-1.9 7.3-1.9 10.3-.1z" />
      <path d="m74.2 28.5c-.6 0-1 .4-1 1v19.5 2h-.1c0-.7.1-1.3.1-2 0-15.5-12.5-28-28-28s-28 12.5-28 28c0 .7 0 1.3.1 2h-.1v-2-19.5c0-.6-.4-1-1-1s-1 .4-1 1v28.5c0 .6.4 1 1 1s1-.4 1-1v-5h.3c1.9 13.6 13.6 24 27.7 24s25.8-10.4 27.7-24h.3v5c0 .6.4 1 1 1s1-.4 1-1v-28.5c0-.5-.5-1-1-1z" />
    </g>
    <path d="m69 19.5c-.6 0-1 .4-1 1v21.5h-.8c0-.5.1-1 .1-1.6 0-12.2-7.8-22.8-19.4-26.4h-.3c0-.3 0-.6.1-.9-2.4-.7-5-1.1-7.6-1.1s-5.2.4-7.6 1.1c0 .3 0 .6.1.9l-.3.1c-11.4 3.5-19.4 14.3-19.4 26.4 0 .5 0 1 .1 1.6h-1v-21.6c0-.6-.4-1-1-1s-1 .4-1 1v28.5c0 .6.4 1 1 1s1-.4 1-1v-5h.1c.9 7.3 4.5 13.8 9.8 18.3.2-.2.5-.4.7-.6l.2.2c4.8 4 10.9 6.1 17.1 6.1 6.3 0 12.1-2.1 17-6.1l.3-.3.3.3c.3-.2.4-.5.5-1-5.1-4.1-11.5-6.3-18-6.3s-12.9 2.2-18 6.3c-4.9-4.4-8.1-10.5-8.9-17.1 12-3.8 20.4-15.2 20.4-28 0-.6 0-1.3-.1-1.9 2.2-.6 4.4-.8 6.6-.8s4.4.3 6.6.8c0 .7-.1 1.3-.1 1.9 0 12.8 8.4 24.3 20.4 28.1-.2 1.8-.6 3.6-1.2 5.3.3.1.5.2.7.4.6-1.8 1.1-3.7 1.3-5.7h.3v5c0 .6.4 1 1 1s1-.4 1-1v-28.4c0-.5-.4-1-1-1zm-29 36.2c5.8 0 11.2 1.8 15.7 4.8-4.5 3.6-9.9 5.5-15.7 5.5s-11.2-1.9-15.7-5.5c4.5-3 9.9-4.8 15.7-4.8zm-25.2-13.5c0-.6-.1-1.2-.1-1.7 0-11.2 7.3-21.1 17.8-24.4-.1 11.8-7.4 21.9-17.7 26.1zm50.4-.1c-10.3-4.1-17.6-14.2-17.7-26 10.5 3.3 17.8 13.3 17.8 24.3 0 .6 0 1.2-.1 1.7z" />
    <path d="m42 39h-4c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1h4c.6 0 1-.4 1-1v-4c0-.6-.4-1-1-1zm0 5h-4v-4h4z" />
    <path d="m72.9 15.2c-2.4-1.4-5.5-1.4-7.8 0-.2.1-.3.4-.2.7.1.2.4.3.7.2 2.1-1.2 4.8-1.2 6.8 0 .1 0 .2.1.2.1.2 0 .3-.1.4-.2.2-.3.2-.6-.1-.8z" />
    <path d="m74.6 12.3c-3.4-2-7.8-2-11.2 0-.2.1-.3.4-.2.7.1.2.4.3.7.2 3.1-1.8 7.1-1.8 10.2 0 .1 0 .2.1.2.1.2 0 .3-.1.4-.2.2-.3.1-.6-.1-.8z" />
    <path d="m76.2 9.4c-4.4-2.5-10.1-2.5-14.5 0-.2.1-.3.4-.2.7.1.2.4.3.7.2 4.1-2.4 9.4-2.4 13.5 0 .1 0 .2.1.2.1.2 0 .3-.1.4-.2.3-.3.2-.6-.1-.8z" />
    <path d="m14.4 16.1c.1 0 .2.1.2.1.2 0 .3-.1.4-.2.1-.2.1-.5-.2-.7-2.4-1.4-5.5-1.4-7.8 0-.2.1-.3.4-.2.7.1.2.4.3.7.2 2.2-1.3 4.8-1.3 6.9-.1z" />
    <path d="m16.1 13.2c.1 0 .2.1.2.1.2 0 .3-.1.4-.2.1-.2.1-.5-.2-.7-3.4-2-7.8-2-11.2 0-.2.1-.3.4-.2.7s.4.3.7.2c3.2-1.9 7.2-1.9 10.3-.1z" />
    <path d="m17.8 10.3c.1 0 .2.1.2.1.2 0 .3-.1.4-.2.1-.2.1-.5-.2-.7-4.4-2.5-10.1-2.5-14.5 0-.2.1-.3.4-.2.7s.4.3.7.2c4.1-2.5 9.5-2.5 13.6-.1z" />
    <path d="m64.6 43.4c-6.6 0-11.9 5.3-11.9 11.9s5.3 11.8 11.9 11.8 11.9-5.3 11.9-11.9-5.3-11.8-11.9-11.8zm2.7 14.3c-.1.1-.2.1-.4.1s-.3 0-.4-.1c-.2-.2-.2-.5 0-.7.7-.7 1-1.6 1-2.5l.9-.9c.1.3.1.6.1.9.1 1.2-.4 2.4-1.2 3.2zm2.2 2.3c-.1.1-.2.1-.4.1s-.3 0-.4-.1c-.2-.2-.2-.5 0-.7 1.3-1.3 2-3 2-4.8 0-.9-.2-1.9-.6-2.7l.8-.8c.5 1.1.8 2.2.8 3.4.1 2.2-.7 4.2-2.2 5.6zm2.6-11.3-6.9 6.9-6.9 6.9c-.1.1-.2.1-.4.1s-.3 0-.4-.1c-.2-.2-.2-.5 0-.7l1.8-1.8c-.1 0-.2-.1-.3-.1-1.4-1.4-2.3-3.4-2.3-5.5s.8-4 2.3-5.5c.2-.2.5-.2.7 0s.2.5 0 .7c-1.3 1.3-2 3-2 4.8s.7 3.5 2 4.8c.1.1.1.2.1.3l1.7-1.7c-.1 0-.2-.1-.3-.1-.8-.8-1.3-2-1.3-3.2s.5-2.3 1.3-3.2c.2-.2.5-.2.7 0s.2.5 0 .7c-.7.7-1 1.6-1 2.5s.4 1.8 1 2.5c.1.1.1.2.1.3l1.4-1.4c-.4-.2-.7-.6-.7-1.1 0-.7.6-1.3 1.3-1.3.5 0 .9.3 1.1.7l6-6c.2-.2.5-.2.7 0s.5.3.3.5z" />
  </g>
);

const getIconBySize = () => {
  switch (true) {
    default:
      return { viewBox: '0 0 80 80', icon: <IconSize80 /> };
  }
};

export default ({ classes = {}, size = 80 }: SensorIcon) => {
  const { viewBox, icon } = getIconBySize();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
