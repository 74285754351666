import * as React from 'react';
import { featureHiddenSentinels, Sentinel } from '../../../types/sentinel';
import BaseList from '../BaseList';
import Button from '../../ui/Button/Button';
import ListHeader from '../ListHeader';
import styles from './SentinelsConfigurationList.module.css';
import classNames from '../../../utils/classNames';
import DeleteSentinelModal from '../../Modals/DeleteSentinelModal';
import { AlertChip, NotificationConfigurationChip, UiChip } from '../Cells';
import SentinelWizard from '../../../containers/SentinelWizard/SentinelWizard';
import ListRow from '../ListRow/ListRow';
import ListCell from '../ListCell';
import ListContainer from '../ListContainer';
import SentinelConfigurationDetailsChip from '../Cells/SentinelConfigurationDetailsChip';
import SystemMessage from '../../ui/SystemMessage/SystemMessage';
import MuteSentinelModal from '../../Modals/MuteSentinelModal';
import ResumeSentinelModal from '../../Modals/ResumeSentinelModal';
import IconUiFactory from '../../icons/IconUiFactory';
import { UserRole } from '../../../types/user';

type Props = {
  data: Sentinel[];
  hasError?: boolean;
  siteCountBySentinelIds?: {};
  onDismissError: Function;
  onUpdateOrCreate: (s: Sentinel) => Promise<void>;
  onResumeSentinel: (s: { sentinelId: string }) => void;
  onMuteSentinel: (s: {
    sentinelId: string;
    reason: string;
    mutedUntil: string | undefined;
  }) => void;
  onDeleteSentinel: (s: Sentinel) => void;
  onExit: () => void;
  userRole: UserRole;
};

type State = {
  expanded: Set<string>;
  deleteModalOpen: boolean;
  sentinelPrimedForDelete?: Sentinel;
  muteModalOpen: boolean;
  sentinelToMuteOrUnmute?: Sentinel;
};

const extraStyles = {
  gridTemplateColumns: '8fr 5fr 4fr 1fr',
  gridAutoFlow: 'column',
};

class SentinelsConfigurationList extends React.Component<Props, State> {
  state = {
    expanded: new Set(),
    deleteModalOpen: false,
    sentinelPrimedForDelete: undefined,
    sentinelToMuteOrUnmute: undefined,
    muteModalOpen: false,
  };

  handleExpandRow = (sentinelId: string) => {
    const { expanded } = this.state;
    expanded.add(sentinelId);
    return this.setState({ expanded });
  };

  handleCloseRow = async (sentinelId: string) => {
    const { expanded } = this.state;
    expanded.delete(sentinelId);
    this.setState({ expanded });
  };

  handleDeleteSentinel = (sentinel: Sentinel) =>
    this.setState({
      deleteModalOpen: true,
      sentinelPrimedForDelete: sentinel,
    });

  handleMuteSentinel = ({
    sentinelId,
    reason,
    mutedUntil,
  }: {
    sentinelId: string;
    reason: string;
    mutedUntil: string | undefined;
  }) => {
    this.props.onMuteSentinel({ sentinelId, reason, mutedUntil });
    this.setState({ muteModalOpen: false, sentinelToMuteOrUnmute: undefined });
  };

  handleResumeSentinel = (sentinelId: string) => {
    this.props.onResumeSentinel({ sentinelId });
    this.setState({ muteModalOpen: false, sentinelToMuteOrUnmute: undefined });
  };

  handleStatusClick = (e: any, sentinel: Sentinel) => {
    // The row also has a click event so we must prevent that from firing as well.
    e.stopPropagation();
    this.setState({ muteModalOpen: true, sentinelToMuteOrUnmute: sentinel });
  };

  renderDetailRow = (item: Sentinel) => (
    <ListContainer
      editMode
      key={`expandedSentinelList${item.id}`}
      disabled={item.valid === false}
    >
      <ListRow autoHeight expanded extraStyles={{ gridTemplateColumns: '1fr' }}>
        <SentinelWizard
          sentinel={item}
          wizardIsActive={item.valid === false}
          onExit={() => this.handleCloseRow(item.id)}
          onUpdateOrCreate={() =>
            this.props
              .onUpdateOrCreate(item)
              .then(() => this.handleCloseRow(item.id))
          }
          onDelete={() => this.handleDeleteSentinel(item)}
        />
      </ListRow>
    </ListContainer>
  );

  renderValidConditionalCells = (item: Sentinel) => {
    const rolledOutOnSitesCount = this.props.siteCountBySentinelIds
      ? this.props.siteCountBySentinelIds[item.id]
      : 0;

    if (item.valid === true) {
      return (
        <>
          <ListCell
            key={`${item.id}SentinelListSitesCount`}
            extraClassNames={[classNames(styles.col2)]}
          >
           
          </ListCell>
          <ListCell centered extraClassNames={[classNames(styles.col)]}>
            <Button
              icon
              secondary
              onClick={e => this.handleStatusClick(e, item)}
            >
              <AlertChip status={item.muted ? 'inactive' : 'active'} />
            </Button>
          </ListCell>
        </>
      );
    }

    return (
      <>
        <ListCell>
          <Button onClick={() => this.handleExpandRow(item.id)}>
            Finish configuration
          </Button>
        </ListCell>
        <ListCell centered onClick={() => this.handleDeleteSentinel(item)}>
          <Button extraClassName={styles.redIcon} icon>
            <IconUiFactory id="trash" />
          </Button>
        </ListCell>
      </>
    );
  };

  renderRow = (item: Sentinel) => (
    <ListContainer
      key={`SentinelList${item.id}`}
      disabled={item.valid === false}
    >
      <ListRow
        onClick={item.valid ? () => this.handleExpandRow(item.id) : () => {}}
        size="large"
        extraClassNames={[item.valid === false ? styles.invalidSentinel : '']}
        extraStyles={{
          ...extraStyles,
          paddingTop: '0.9375rem',
          paddingBottom: '0.9375rem',
        }}
      >
        <ListCell extraClassNames={[classNames(styles.col4)]}>
          <div className={styles.contentInner}>
            <NotificationConfigurationChip
              title={item.title}
              description={item.description}
              sentinelType={item.sentinelType}
            />
          </div>
        </ListCell>
        <ListCell extraClassNames={[classNames(styles.col2)]}>
          <SentinelConfigurationDetailsChip
            isValid={item.valid}
            sentinel={item}
          />
        </ListCell>
        {this.renderValidConditionalCells(item)}
      </ListRow>
    </ListContainer>
  );

  render() {
    const { hasError, data = [], onDismissError } = this.props;
    const {
      expanded,
      deleteModalOpen,
      sentinelPrimedForDelete,
      sentinelToMuteOrUnmute,
      muteModalOpen,
    } = this.state;

    if (hasError && !data.length) {
      return (
        <SystemMessage
          title="Error"
          message="Error during API request while loading sentinels"
          type="error"
          onClick={onDismissError}
        />
      );
    }

    return (
      <BaseList>
        {data.length ? (
          <ListHeader extraStyles={extraStyles}>
            <ListCell>Configurations</ListCell>
            <ListCell>Details</ListCell>
            <ListCell></ListCell>
            <ListCell centered>Status</ListCell>
          </ListHeader>
        ) : null}

        {data
          .filter(s =>
            featureHiddenSentinels.includes(s.sentinelType)
              ? this.props.userRole === UserRole.ADMIN
              : true
          )
          .map(item =>
            expanded.has(item.id)
              ? this.renderDetailRow(item)
              : this.renderRow(item)
          )}

        {deleteModalOpen && sentinelPrimedForDelete && (
          <DeleteSentinelModal
            sentinelPrimedForDelete={sentinelPrimedForDelete}
            onSubmit={() => {
              this.setState({ deleteModalOpen: false });
              // @ts-ignore todo: ts-fix
              this.props.onDeleteSentinel(sentinelPrimedForDelete);
            }}
            onClose={() =>
              this.setState({
                deleteModalOpen: false,
                sentinelPrimedForDelete: undefined,
              })
            }
          />
        )}

        {muteModalOpen &&
          sentinelToMuteOrUnmute &&
          // @ts-ignore todo: ts-fix
          (sentinelToMuteOrUnmute.muted ? (
            <ResumeSentinelModal
              onSubmit={this.handleResumeSentinel}
              sentinel={sentinelToMuteOrUnmute}
              onClose={() =>
                this.setState({
                  muteModalOpen: false,
                  sentinelToMuteOrUnmute: undefined,
                })
              }
            />
          ) : (
            <MuteSentinelModal
              onSubmit={this.handleMuteSentinel}
              sentinel={sentinelToMuteOrUnmute}
              onClose={() =>
                this.setState({
                  muteModalOpen: false,
                  sentinelToMuteOrUnmute: undefined,
                })
              }
            />
          ))}
      </BaseList>
    );
  }
}

export default SentinelsConfigurationList;
