import * as React from 'react';

import FilterDropdown from '../FilterDropdown';
import FilterDropdownTextItem from '../FilterDropdownItems/FilterDropdownTextItem';
import notificationDateFilter from '../../../utils/notificationDateFilter';
import {
  FnSetFilterTimePeriod,
  FilterTimePeriod,
} from '../../../types/notification';
import BasePicker from '../../DatePicker/BasePicker';

type Props = {
  setFilter: FnSetFilterTimePeriod;
  value: FilterTimePeriod;
  disableCustomRange?: boolean;
  size?: 'small' | 'large';
};

type State = {
  customPickerOpen: boolean;
};

class DateFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      customPickerOpen: false,
    };
  }

  toggleCustomPicker = () => {
    this.setState({
      customPickerOpen: !this.state.customPickerOpen,
    });
  };

  render() {
    const { customPickerOpen } = this.state;

    const {
      value: { fromDate, toDate, title },
      disableCustomRange,
    } = this.props;

    const defaultSelectedDays =
      fromDate && toDate
        ? {
            from: new Date(`${fromDate}T00:00:00Z`),
            to: new Date(`${toDate}T00:00:00Z`),
          }
        : undefined;

    return (
      <FilterDropdown title={title || ''}>
        {notificationDateFilter.map(f => (
          <FilterDropdownTextItem
            key={f.title}
            title={f.title}
            onClick={() =>
              this.props.setFilter({
                title: f.title,
                fromDate: f.from,
                toDate: f.to,
                isCustomRange: false,
              })
            }
          />
        ))}

        {!disableCustomRange && (
          <FilterDropdownTextItem
            title="Custom date range"
            onClick={this.toggleCustomPicker}
          />
        )}

        {customPickerOpen && (
          <BasePicker
            setFilter={this.props.setFilter}
            togglePicker={this.toggleCustomPicker}
            defaultSelectedDays={defaultSelectedDays}
          />
        )}
      </FilterDropdown>
    );
  }
}

export default DateFilter;
