import { Notification } from '../types/notification';
import { Actions } from '../actions/notifications';

const initialState: Notification[] = [];

function reverseHandlingLogs(notification: Notification): Notification {
  const { handlingLogs, ...rest } = notification;
  return {
    ...rest,
    handlingLogs: [...handlingLogs].reverse(),
  };
}

function sortByDateDescending(a: Notification, b: Notification): number {
  return (
    new Date(b.handlingLogs[0].at).valueOf() -
    new Date(a.handlingLogs[0].at).valueOf()
  );
}

function sortByDateByUnseen(a: Notification, b: Notification): number {
  if (a.dismissedBy) {
    if (b.dismissedBy) {
      return sortByDateDescending(a, b);
    }
    return 1;
  } else if (b.dismissedBy) {
    return -1;
  }
  return sortByDateDescending(a, b);
}

export default (
  state: Notification[] = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case Actions.NOTIFICATIONS_SUCCESS: {
      // todo: add sort method to action
      return action.payload
        .map(n => reverseHandlingLogs(n))
        .sort(sortByDateDescending);
    }

    case Actions.DISMISS_SUCCESS: {
      const notificationIndex = state.findIndex(
        e => e.id === action.payload.id
      );

      return [
        ...state.slice(0, notificationIndex),
        reverseHandlingLogs(action.payload),
        ...state.slice(notificationIndex + 1),
      ];
    }

    case Actions.NOTIFICATIONS_CLEAR: {
      return initialState;
    }

    case Actions.ORDER_UNSEEN: {
      return [...state].sort(sortByDateByUnseen);
    }

    case Actions.ORDER_DATE: {
      return [...state].sort(sortByDateDescending);
    }

    default: {
      return state;
    }
  }
};
