import { FilterTimePeriod } from '../types/notification';

export enum Actions {
  SITES = '@@notificationFilter/SITES',
  STATUS = '@@notificationFilter/STATUS',
  DATE = '@@notificationFilter/DATE',
  EQUIPMENT = '@@notificationFilter/EQUIPMENT',
}

export interface SetNotificationSitesFilter {
  (siteIds: number[]): any;
}
export const setNotificationSitesFilter: SetNotificationSitesFilter = siteIds => ({
  type: Actions.SITES,
  payload: siteIds,
});

export interface SetNotificationStatusFilter {
  (status: string[]): any;
}
export const setNotificationStatusFilter = (statuses: string[]) => ({
  type: Actions.STATUS,
  payload: statuses,
});

export const setNotificationDateFilter = (dateFilter: FilterTimePeriod) => ({
  type: Actions.DATE,
  payload: dateFilter,
});

export const setNotificationEquipmentFilter = (equipmentIds: number[]) => ({
  type: Actions.EQUIPMENT,
  payload: equipmentIds,
});
