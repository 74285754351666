import * as React from 'react';
import IconUiFactory from '../../../icons/IconUiFactory';
import styles from './RolloutSiteInfo.module.css';
import { Site } from '../../../../types/site';
import Button from '../../../ui/Button/Button';
import CloseIcon from '../../../icons/ui/Close';
import DialogButton from '../../../../containers/dialog/DialogButton';
import { DialogTypes } from '../../../../types/dialog';

type Props = {
  site: Site;
  isCollapsed: boolean;
  handleCollapseSite: (siteId: number) => void;
  handleRemoveSite: (siteId: number) => void;
};

function RolloutSiteInfo(props: Props) {
  const { site, isCollapsed, handleCollapseSite, handleRemoveSite } = props;

  return (
    <div className={styles.root}>
      <Button
        icon
        onClick={() => handleCollapseSite(site.id)}
        extraClassName={styles.toggleButton}
      >
        <IconUiFactory
          size={20}
          id={isCollapsed ? 'chevronDown' : 'chevronRight'}
          classes={{ icon: styles.icon }}
        />
        <div className={styles.title}>{site.title}</div>
      </Button>

      {isCollapsed && (
        <div>
          <div className={styles.address}>{site.address}</div>

          <DialogButton
            size="small"
            secondary
            extraClassName={styles.removeButton}
            dialog={{
              isOpen: true,
              title: 'Remove site',
              content: `Are you sure you would like to remove the site "${
                site.title
              }" from this configuration?`,
              actionFn: () => handleRemoveSite(site.id),
            }}
          >
            <CloseIcon classes={{ icon: styles.removeIcon }} />
            Remove Site
          </DialogButton>
        </div>
      )}
    </div>
  );
}

export default RolloutSiteInfo;
