/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */

import * as React from 'react';
import { navigate } from '@reach/router';

import DropDown from '../DropDown';
import { Site } from '../../types/site';
import { Equipment } from '../../types/equipment';

import styles from './MenuTopbar.module.css';

import IconUiFactory from '../icons/IconUiFactory';

import Tabs from '../Tabs/Tabs';
import TabLink from '../Tabs/TabLink';

type PageInfo = {
  pageIcon: string;
  pageTitle: string;
  pageType: string;
  pageSubtitle: string;
};

type Props = {
  pageInfo: PageInfo;
  pageType: 'string';
  sites: Site[];
  equipment: Equipment[];
};

// TODO create case where a site is selected and enable switch next to page title. for EN-207
// MATCH ROUTE ID TO SITE ID IN ARRAY, USE TO DETERMINE WHICH SITE IS SELECTED
// BLOCKED BECAUSE SITE API IS DOWN ATM AND REQUIRES AUTH.

const originLocMapping = {
  sites: 'monitoring/sites',
  // equipment: 'monitoring/equipment',
};

const renderDropdown = (pageInfo: PageInfo, collectionObject: any) => {
  const { pageType, pageTitle } = pageInfo;
  let items = collectionObject[pageType] || [];
  items = items.sort((a, b) => a.title.localeCompare(b.title));
  const basePath = originLocMapping[pageType];

  if (!basePath || items.length < 1) return undefined;

  return (
    <DropDown
      onChange={id => {
        // TODO AS THE ROUTES GET MORE COMPLEX, WE WILL NEED A MAPPING TABLE TO CREATE THE NAV URL.
        const loc = window.location.href;

        const currentPosition = loc.match('[^/]+$');

        if (currentPosition === null) {
          return;
        }

        navigate(
          `/${originLocMapping[pageType]}/${id.toString()}/${
            currentPosition[0]
          }`
        );
      }}
      customSelectedText="change"
      sortItems={items.map(item => ({ value: item.id, text: item.title }))}
      initiallySelectedText={pageTitle}
    />
  );
};
// TODO Chevron is the wrong direction.
const renderBackButton = (pageType: string) => {
  if (!originLocMapping[pageType]) return undefined;

  return (
    <div
      onClick={() => navigate(`/${originLocMapping[pageType]}`)}
      className={styles.backButton}
    >
      <IconUiFactory
        size={20}
        classes={[styles.iconColor]}
        id="chevronBreadCrumb"
      />
    </div>
  );
};

const renderPageSubtitle = (subtitle: string) => (
  <div className={styles.subtitle}> {subtitle} </div>
);

const renderNotificationsSelectBar = () => (
  <Tabs>
    <TabLink secondary to="/notifications">
      Notifications
    </TabLink>
    <TabLink secondary to="/notifications/configuration" activeOnNested>
      Configuration
    </TabLink>
  </Tabs>
);

const renderTitleContent = (pageInfo, sites, equipment) => {
  switch (pageInfo.pageType) {
    case 'notifications':
      return renderNotificationsSelectBar();
    default: {
      return (
        <div>
          <div className={styles.horizontalFlexWrap}>
            <div className={styles.title}>{pageInfo.pageTitle}</div>
            {renderDropdown(pageInfo, { sites, equipment })}
          </div>
          {pageInfo.pageSubtitle && renderPageSubtitle(pageInfo.pageSubtitle)}
        </div>
      );
    }
  }
};

// Extend checks and logic to equipment page type as well.
const MenuTopbar = ({
  pageInfo = { pageIcon: '', pageTitle: '', pageType: '', pageSubtitle: '' },
  sites,
  equipment,
}: Props) => (
  <div className={styles.wrapper}>
    {renderBackButton(pageInfo.pageType)}
    <div className={styles.titleWrapper}>
      {renderTitleContent(pageInfo, sites, equipment)}
    </div>
  </div>
);
export default MenuTopbar;
