import * as React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { connect } from 'react-redux';
import dateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import BaseContentContainer from '../layout/BaseContentContainer';
import ViewNotificationIndex from './notifications/ViewNotificationIndex';
import ViewNotificationConfiguration from './notifications/ViewNotificationConfiguration';
import MenuNotificationFilterBar from '../Filters/MenuNotificationFilterBar';
import {
  FilterTimePeriod,
  Notification,
  NotificationFilter,
  NotificationStatus,
  SortOptionValues,
} from '../../types/notification';
import {
  ClearNotifications,
  clearNotifications,
  getNotifications,
  OrderNotificationsByDate,
  orderNotificationsByDate,
  OrderNotificationsByUnseen,
  orderNotificationsByUnseen,
} from '../../actions/notifications';
import {
  setNotificationDateFilter,
  SetNotificationSitesFilter,
  setNotificationSitesFilter,
  SetNotificationStatusFilter,
  setNotificationStatusFilter,
} from '../../actions/notificationFilter';
import { datePickerDateFormatMap } from '../../reducers/app';
import { Locale, MeasurementSystem } from '../../types/shared';
import {
  setPageIcon,
  setPageSubtitle,
  setPageTitle,
  setPageType,
} from '../../actions/pageInfo';

type State = {
  sortType: SortOptionValues;
  hasError: boolean;
};

type Props = {
  setPageTitle: (t: string) => void;
  setPageIcon: (i: string) => void;
  setPageType: (t: string) => void;
  setNotificationDateFilter: (dateFilter: FilterTimePeriod) => void;
  setNotificationSitesFilter: SetNotificationSitesFilter;
  setNotificationStatusFilter: SetNotificationStatusFilter;
  getNotifications: (notificationFilter: string) => void;
  orderNotificationsByUnseen: OrderNotificationsByUnseen;
  orderNotificationsByDate: OrderNotificationsByDate;
  clearNotifications: ClearNotifications;
  isLoading: boolean;
  locale: Locale;
  notifications: Notification[];
  notificationFilter: NotificationFilter;
} & RouteComponentProps;

class ViewNotifications extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      sortType: 'date',
    };
  }

  componentDidMount() {
    this.props.setPageTitle('Notifications');
    this.props.setPageIcon('notification');
    this.props.setPageType('notifications');
    this.loadNotifications();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevFilters = JSON.stringify(prevProps.notificationFilter);
    const filters = JSON.stringify(this.props.notificationFilter);

    if (prevFilters !== filters) {
      this.loadNotifications();
    }
  }

  componentWillUnmount() {
    this.props.setPageType('');
  }

  setTimePeriod = ({
    title,
    fromDate,
    toDate,
    isCustomRange,
  }: FilterTimePeriod) => {
    const { locale } = this.props;
    const format = locale.measurementSystem || MeasurementSystem.metric;

    let formattedTitle = title;

    if (!title && isCustomRange && fromDate && toDate) {
      formattedTitle = `${dateFnsFormat(
        parseISO(fromDate),
        datePickerDateFormatMap[format]
      )} - ${dateFnsFormat(parseISO(toDate), datePickerDateFormatMap[format])}`;
    }

    this.props.setNotificationDateFilter({
      title: formattedTitle,
      fromDate,
      toDate,
      isCustomRange,
    });
  };

  setStatusFilter = (statusFilter: NotificationStatus) => {
    const filterAlreadySet = this.props.notificationFilter.status.includes(
      statusFilter
    );
    const activeStatusFilters = this.props.notificationFilter.status;

    const status = filterAlreadySet
      ? activeStatusFilters.filter(t => t !== statusFilter)
      : [...activeStatusFilters, statusFilter];
    this.props.setNotificationStatusFilter(status);
  };

  setSiteFilter = (siteIds: number[]) => {
    this.props.setNotificationSitesFilter(siteIds);
  };

  handleSort = (type: SortOptionValues) => {
    switch (type) {
      case 'unseen': {
        this.props.orderNotificationsByUnseen();
        break;
      }
      case 'date': {
        this.props.orderNotificationsByDate();
        break;
      }
      default:
        break;
    }
    this.setState({ sortType: type });
  };

  loadNotifications = () => {
    const {
      timePeriod: { fromDate, toDate },
      status,
      siteIds = [],
      equipmentIds = [],
    } = this.props.notificationFilter;

    if (status.length === 0) {
      this.props.clearNotifications();
      return;
    }

    const queryParams = new URLSearchParams();

    queryParams.set('state', status.join(','));

    if (fromDate) queryParams.set('fromDate', fromDate);
    if (toDate) queryParams.set('toDate', toDate);

    queryParams.set('limit', '1000');

    if (siteIds && siteIds.length > 0) {
      queryParams.set('siteIds', siteIds.join(','));
    }
    if (equipmentIds && equipmentIds.length > 0) {
      queryParams.set('equipmentIds', equipmentIds.join(','));
    }

    this.props.getNotifications(queryParams.toString());
  };

  render() {
    const { notificationFilter } = this.props;
    return (
      <>
        <Router primary={false}>
          <MenuNotificationFilterBar
            notificationStatusFilters={notificationFilter.status}
            notificationTypeFilters={notificationFilter.notificationTypes}
            notificationSiteIdsFilters={notificationFilter.siteIds}
            setStatusFilter={this.setStatusFilter}
            setDateFilter={this.setTimePeriod}
            timePeriodFilter={notificationFilter.timePeriod}
            setSiteFilter={this.setSiteFilter}
            path="/"
          />
        </Router>

        <BaseContentContainer isLoading={this.props.isLoading}>
          <Router primary={false}>
            <ViewNotificationIndex
              handleSort={this.handleSort}
              hasError={this.state.hasError}
              isLoading={this.props.isLoading}
              path="/"
            />
            <ViewNotificationConfiguration path="configuration/*" />
          </Router>
        </BaseContentContainer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isLoading: state.loading.notifications,
  locale: state.app.locale,
  notifications: state.notifications,
  notificationFilter: state.notificationFilter,
});

const mapDispatchToProps = {
  getNotifications,
  clearNotifications,
  orderNotificationsByUnseen,
  orderNotificationsByDate,
  setNotificationSitesFilter,
  setNotificationStatusFilter,
  setNotificationDateFilter,
  setPageTitle,
  setPageIcon,
  setPageType,
  setPageSubtitle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewNotifications);
