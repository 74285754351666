import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import formatDistance from 'date-fns/formatDistance';
import { store } from '..';

export const toUnixTimestamp = (value: string): number =>
  parseInt(format(parseISO(value), 't'), 10);

export const deriveDateValue = (
  value: string | null | void,
  isTimeFrom: boolean
) => {
  if (value === '' || value === null || value === undefined) {
    return '';
  }

  if (isTimeFrom) {
    return formatDistance(parseISO(value), new Date(), { addSuffix: true });
  }

  const dateFormat = store.getState().app.locale.dateTimeFormat;

  return format(parseISO(value), dateFormat);
};
