import connect from 'react-redux/es/connect/connect';
import SentinelWizard from '../../components/sentinelWizard';
import { openDialog } from '../../actions/dialog';

const mapStateToProps = state => ({
  userRole: state.app.currentUser.role,
  measurementSystem: state.app.locale.measurementSystem,
});

const mapDispatchToProps = {
  openDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SentinelWizard);
