import * as React from 'react';
import StepConditionItem, { StepConditionTitle } from './StepConditionItem';
import classNames from '../../../../utils/classNames';
import styles from './ConditionDoor.module.css';
import IconGatewayFactory from '../../../icons/IconGatewayFactory';

function ConditionBatteryLevelCheck() {
  return (
    <React.Fragment>
      <StepConditionItem
        label={
          <span className={classNames(styles.icon)}>
            <IconGatewayFactory size={80} id="gateway_low" />
          </span>
        }
        condition={
          <div>
            <StepConditionTitle>Low Battery</StepConditionTitle>
            <p>The sensor may stop sending signals any moment</p>
          </div>
        }
        conditionAlignedCenter
      />
      {/** 
          <StepConditionItem
          label={
            <span className={classNames(styles.doorIcon)}>
              <IconGatewayFactory
                size={80}
                id="gateway_empty"
              />
            </span>
          }
          condition={
            <div className={styles.switch}>
              <Switch id="lowBattery" onChange={() => {}} checked disabled />
              <span className={styles.switchLabel}>Battery Empty</span>
            </div>
          }
          conditionAlignedCenter
          description="The sensor is not sending any signals"
        />
    * */}
    </React.Fragment>
  );
}

export default ConditionBatteryLevelCheck;
