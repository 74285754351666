import * as React from 'react';
import Loadable from 'react-loadable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconFactory } from '../../types/icon';

const Loading = () => <CircularProgress size={40} />;

const IconGateway = Loadable({
  loader: () => import('./gateways/IconGateway'),
  loading: Loading,
});

const IconGatewayLowBattery = Loadable({
  loader: () => import('./gateways/IconGatewayLowBattery'),
  loading: Loading,
});

const IconGatewayBatteryEmpty = Loadable({
  loader: () => import('./gateways/IconGatewayBatteryEmpty'),
  loading: Loading,
});

const IconGatewayOffline = Loadable({
  loader: () => import('./gateways/IconGatewayOffline'),
  loading: Loading,
});

export default ({ classes, size, id }: IconFactory) => {
  switch (id) {
    case 'offline':
      return <IconGatewayOffline classes={classes} size={size} />;
    case 'gateway_low':
      return <IconGatewayLowBattery classes={classes} size={size} />;
    case 'gateway_empty':
      return <IconGatewayBatteryEmpty classes={classes} size={size} />;
    case 'gateway':
    default:
      return <IconGateway classes={classes} size={size} />;
  }
};
