import React from 'react';

import IconNotificationFactory from '../../icons/IconNotificationFactory';
import styles from './SentinelConfigurationDetailsChip.module.css';
import {
  Parameters,
  Sentinel,
  SentinelEscalation,
  SentinelType,
} from '../../../types/sentinel';
import {
  ComparisonType,
  comparisonTypesToSymbol,
} from '../../../types/sentinel';
import classNames from '../../../utils/classNames';
import formatTemperature from '../../../containers/helper/formatTemperature';

function paramToText(paramObject: Parameters, sentinelType: SentinelType) {
  switch (sentinelType) {
    case SentinelType.TEMPERATURE: {
      if (
        paramObject.comparisonType === ComparisonType.inner ||
        paramObject.comparisonType === ComparisonType.outer
      ) {
        return `${formatTemperature(
          paramObject.rangeLowerBound
        )} - ${formatTemperature(paramObject.rangeUpperBound)}`;
      }

      return `${
        comparisonTypesToSymbol[paramObject.comparisonType || 'default']
      } ${formatTemperature(paramObject.threshold)}`;
    }

    case SentinelType.DOOR: {
      let str = '';

      if (paramObject.openClosed) {
        str = paramObject.openClosed;
      }

      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    default:
      return '';
  }
}
function escalationsToText(escalations: SentinelEscalation[] = []) {
  const size = (escalations === null ? [] : escalations).reduce(
    (size, next: SentinelEscalation) => size + next.recipients.length,
    0
  );

  const isPlural = size !== 1;
  return `${size} Recipient${isPlural ? 's' : ''}`;
}

type Props = {
  sentinel: Sentinel;
  isValid?: boolean;
};

const SentinelConfigurationDetailsChip = ({ sentinel, isValid }: Props) => (
  <div className={styles.horizontalGrouping}>
    <div className={styles.textAndIconGroupingVertical}>
      <span>{paramToText(sentinel.parameters, sentinel.sentinelType)}</span>
      <span
        className={classNames([
          !isValid ? styles.invalidIconStyling : styles.validIconStyling,
        ])}
      >
        <IconNotificationFactory size={16} id="condition" />
      </span>
    </div>

    <div className={styles.textAndIconGroupingVertical}>
      <span>{`${'24'} hr`}</span>
      <span
        className={classNames([
          !isValid ? styles.invalidIconStyling : styles.validIconStyling,
        ])}
      >
        <IconNotificationFactory size={16} id="time" />
      </span>
    </div>

    <div className={styles.textAndIconGroupingVertical}>
      <span>{escalationsToText(sentinel.escalations)}</span>
      <span
        className={classNames([
          !isValid ? styles.invalidIconStyling : styles.validIconStyling,
        ])}
      >
        <IconNotificationFactory size={16} id="rollout" />
      </span>
    </div>
  </div>
);

export default SentinelConfigurationDetailsChip;
