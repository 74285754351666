import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import withTheme from './withTheme';
import { loadState } from './middleware/localStorage';
import { unregister } from './registerServiceWorker';
import App from './containers/App';
import { initApp, initAppWithToken } from './actions/app';
import './styles';
import './root.css';

const preloadedState = loadState();

// eslint-disable-next-line import/prefer-default-export
export const store = configureStore(preloadedState);

// Enable this line to persist the redux-store in localStorage
// store.subscribe(() => saveState(store.getState()));

try {
  const fragment = window.location.hash.substr(1);
  const params = new URLSearchParams(fragment);
  if (params.has('accessToken')) {
    const accessToken = params.get('accessToken');
    store.dispatch(initAppWithToken({ accessToken }));
  } else {
    store.dispatch(initApp());
  }
} catch (err) {
  // access to window is blocked?
}

const AppWithTheme = withTheme(App);

ReactDOM.render(
  <Provider store={store}>
    <AppWithTheme />
  </Provider>,
  document.getElementById('root')
);

unregister();
