import * as React from 'react';
import { SensorIcon } from '../../../types/icon';

const IconSize16 = () => (
  <g>
    <path
      fill="currentColor"
      d="M11.7.2c0-.1-.1-.2-.2-.2s-.2.1-.2.2c-.5 1.2-1.5 4-2.3 6.4v3.6c.2-1.2 1.1-4.1 2.5-7.7C13 6.6 14 9.7 14 10.6c0 1.3-1.1 2.4-2.5 2.4-.6 0-1.1-.2-1.5-.5 0 .4-.1.7-.2 1.1.5.3 1.1.4 1.7.4 1.9 0 3.5-1.5 3.5-3.4C15 9 12.5 2.3 11.7.2z"
    />
    <path
      fill="currentColor"
      d="M10 12.5c0-.9-.4-1.8-1-2.4V3.5C9 2.1 7.9 1 6.5 1S4 2.1 4 3.5v6.6c-.6.6-1 1.5-1 2.4C3 14.4 4.6 16 6.5 16c1.6 0 2.9-1 3.3-2.4.1-.4.2-.7.2-1.1zM6.5 15C5.1 15 4 13.9 4 12.5c0-.7.3-1.3.7-1.7l.3-.3v-7C5 2.7 5.7 2 6.5 2S8 2.7 8 3.5v7l.3.3c.4.4.7 1 .7 1.7v.4C8.8 14.1 7.7 15 6.5 15z"
    />
    <path fill="currentColor" d="M2 4h1v1H2z" />
    <path fill="currentColor" d="M3 4H2v1h1V4z" />
    <path fill="currentColor" d="M2 6h1v1H2z" />
    <path fill="currentColor" d="M3 6H2v1h1V6z" />
    <g>
      <path fill="currentColor" d="M1 8h2v1H1z" />
      <path fill="currentColor" d="M3 8H1v1h2V8z" />
    </g>
    <g>
      <path
        fill="currentColor"
        d="M7 7H6v4.7c-.3.2-.5.5-.5.8 0 .6.4 1 1 1s1-.4 1-1c0-.4-.2-.7-.5-.8V7z"
      />
    </g>
  </g>
);

const IconSize32 = () => (
  <g>
    <path
      fill="currentColor"
      d="M6.7 29c-.2 0-.4-.1-.5-.4-.1-.5-.2-1-.2-1.6s.1-1.1.3-1.6c.1-.3.4-.4.6-.3.3.1.4.4.3.7-.1.3-.2.8-.2 1.2 0 .4.1.9.2 1.3.1.3 0 .6-.3.7h-.2zM3.9 30c-.2 0-.4-.1-.5-.3-.2-.9-.4-1.8-.4-2.7s.2-1.8.5-2.7c.1-.3.4-.4.6-.3.3.1.4.4.3.7-.3.7-.4 1.5-.4 2.3 0 .8.1 1.6.4 2.3.1.3 0 .6-.3.7h-.2zM21.5 27.5c-.5 0-1.1-.1-1.6-.2-.3-.1-.4-.3-.4-.6.1-.3.3-.4.6-.4.4.1.9.2 1.4.2 3.3 0 6-2.6 6-5.9 0-2.9-4.6-15.4-6-19.1-.7 2-2.2 5.9-3.4 9.7-.1.3-.4.4-.6.3-.3-.1-.4-.4-.3-.6 1.3-3.8 2.7-7.7 3.5-9.7.1-.4.5-.6.9-.6s.8.3.9.6c1.8 4.9 6.1 16.5 6.1 19.5-.1 3.7-3.2 6.8-7.1 6.8z"
    />
    <path
      fill="currentColor"
      d="M24 22.5c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7.6-.6.9-1.3.9-2.1 0-.2 0-.6-.3-1.4-.1-.3.1-.5.4-.6.3-.1.5.1.6.4.2.8.3 1.3.3 1.7 0 1-.4 2-1.1 2.8H24zM14.5 2.5C15.9 2.5 17 3.6 17 5v17.8l.4.3c1 .9 1.6 2.1 1.6 3.5 0 2.5-2 4.5-4.5 4.5S10 29 10 26.5c0-1.3.6-2.6 1.6-3.5l.4-.3V5c0-1.4 1.1-2.5 2.5-2.5m0-1C12.6 1.5 11 3.1 11 5v17.3c-1.2 1-2 2.5-2 4.2 0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5c0-1.7-.8-3.2-2-4.2V5c0-1.9-1.6-3.5-3.5-3.5z"
    />
    <path fill="currentColor" d="M15 11h-1v14h1V11z" />
    <path
      fill="currentColor"
      d="M14.5 25c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.7-1.5 1.5-1.5m0-1c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z"
    />
    <g>
      <path fill="currentColor" d="M7 11h2v1H7z" />
      <path
        fill="currentColor"
        transform="rotate(90 8 14.5)"
        d="M7.5 13.5h1v2h-1z"
      />
      <path
        fill="currentColor"
        transform="rotate(90 8 17.5)"
        d="M7.5 16.5h1v2h-1z"
      />
      <path fill="currentColor" d="M5 8h4v1H5z" />
    </g>
  </g>
);

const IconSize40 = () => (
  <g>
    <path
      fill="currentColor"
      d="M10.7 32c-.2 0-.4-.1-.5-.4-.1-.5-.2-1-.2-1.6s.1-1.1.3-1.6c.1-.3.4-.4.6-.3.3.1.4.4.3.7-.1.3-.2.8-.2 1.2 0 .4.1.9.2 1.3.1.3 0 .6-.3.7h-.2zM7.9 33c-.2 0-.4-.1-.5-.3-.2-.9-.4-1.8-.4-2.7s.2-1.8.5-2.7c.1-.3.4-.4.6-.3.3.1.4.4.3.7-.3.7-.4 1.5-.4 2.3 0 .8.1 1.6.4 2.3.1.3 0 .6-.3.7h-.2zM25.5 30.5c-.5 0-1.1-.1-1.6-.2-.3-.1-.4-.3-.4-.6.1-.3.3-.4.6-.4.4.1.9.2 1.4.2 3.3 0 6-2.6 6-5.9 0-2.9-4.6-15.4-6-19.1-.7 2-2.2 5.9-3.4 9.7-.1.3-.4.4-.6.3-.3-.1-.4-.4-.3-.6 1.3-3.8 2.7-7.7 3.5-9.7.1-.4.5-.6.9-.6s.8.3.9.6c1.8 4.9 6.1 16.5 6.1 19.5-.1 3.7-3.2 6.8-7.1 6.8z"
    />
    <path
      fill="currentColor"
      d="M28 25.5c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7.6-.6.9-1.3.9-2.1 0-.2 0-.6-.3-1.4-.1-.3.1-.5.4-.6.3-.1.5.1.6.4.2.8.3 1.3.3 1.7 0 1-.4 2-1.1 2.8H28zM18.5 5.5C19.9 5.5 21 6.6 21 8v17.8l.4.3c1 .9 1.6 2.1 1.6 3.5 0 2.5-2 4.5-4.5 4.5S14 32 14 29.5c0-1.3.6-2.6 1.6-3.5l.4-.3V8c0-1.4 1.1-2.5 2.5-2.5m0-1C16.6 4.5 15 6.1 15 8v17.3c-1.2 1-2 2.5-2 4.2 0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5c0-1.7-.8-3.2-2-4.2V8c0-1.9-1.6-3.5-3.5-3.5z"
    />
    <path fill="currentColor" d="M19 14h-1v14h1V14z" />
    <path
      fill="currentColor"
      d="M18.5 28c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.7-1.5 1.5-1.5m0-1c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z"
    />
    <g opacity=".1">
      <path
        fill="currentColor"
        d="M30.9 8.6c-.1-.3-.5-.6-.9-.6s-.8.3-.9.6c-.6 1.5-1.6 4.2-2.6 7.1v-3.2c0-1.9-1.6-3.5-3.5-3.5s-3.5 1.6-3.5 3.5v17.3c-1.2 1-2 2.5-2 4.2 0 3 2.5 5.5 5.5 5.5 2.8 0 5-2 5.4-4.7.5.1 1 .2 1.6.2 3.9 0 7-3.1 7-6.9 0-3-4.2-14.6-6.1-19.5zM23 38.5c-2.5 0-4.5-2-4.5-4.5 0-1.3.6-2.6 1.6-3.5l.4-.3V12.5c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5v17.8l.4.3c1 .9 1.6 2.1 1.6 3.5 0 2.4-2 4.4-4.5 4.4z"
      />
      <path
        fill="currentColor"
        d="M23.5 31.6V18.5h-1v13.1c-1.1.2-2 1.2-2 2.4 0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5c0-1.2-.9-2.2-2-2.4zm-.5 3.9c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5z"
      />
    </g>
    <g>
      <path fill="currentColor" d="M11 14h2v1h-2z" />
      <path
        transform="rotate(90 12 17.5)"
        fill="currentColor"
        d="M11.5 16.5h1v2h-1z"
      />
      <path
        transform="rotate(90 12 20.5)"
        fill="currentColor"
        d="M11.5 19.5h1v2h-1z"
      />
      <path fill="currentColor" d="M9 11h4v1H9z" />
    </g>
  </g>
);

const IconSize80 = () => (
  <g>
    <path
      fill="currentColor"
      d="M70 17l-2.1-2.1c-.2-.2-.5-.2-.7 0L65 17c-.2.2-.2.5 0 .7s.5.2.7 0l1.3-1.3v6.3c0 .3.2.5.5.5s.5-.2.5-.5v-6.3l1.3 1.3c.1.1.2.1.4.1s.3 0 .4-.1c.1-.2.1-.5-.1-.7zM70.5 23.2c-.3 0-.5.2-.5.5v4c0 .3.2.5.5.5s.5-.2.5-.5v-4c0-.2-.2-.5-.5-.5zM32.5 58.2c-.8 0-1.6.2-2.2.6-1.3.8-2.3 2.2-2.3 3.9 0 2.5 2 4.5 4.5 4.5.8 0 1.6-.2 2.2-.6 1.3-.8 2.3-2.2 2.3-3.9 0-2.4-2-4.5-4.5-4.5zm1.7 7.6c-.5.3-1.1.5-1.7.5-.9 0-1.8-.4-2.5-1-.7-.7-1-1.5-1-2.5 0-1.2.7-2.4 1.8-3 .5-.3 1.1-.5 1.7-.5.9 0 1.8.4 2.5 1 .7.7 1 1.5 1 2.5 0 1.2-.7 2.4-1.8 3zM33 21.2h-1v37h1zM24 30.2l-5.1.1v1l5.1-.1zM22 27.2h2v1h-2zM22 24.2h2v1h-2zM22 21.2h2v1h-2zM22 18.2h2v1h-2zM24 45.2l-5.1.1v1l5.1-.1zM22 42.2h2v1h-2zM22 39.2h2v1h-2zM22 36.2h2v1h-2zM22 33.2h2v1h-2zM68.5 29.2H68v-.5c0-.3-.2-.5-.5-.5s-.5.3-.5.5v.5h-.5c-.3 0-.5.2-.5.5s.2.5.5.5h.5v.5c0 .3.2.5.5.5s.5-.2.5-.5v-.5h.5c.3 0 .5-.2.5-.5 0-.2-.2-.5-.5-.5zM18.2 59.4c-.2-.1-.5-.1-.7.2-.7 1.2-1 2.5-1 3.9s.4 2.7 1 3.9c.1.2.3.2.4.2.1 0 .2 0 .2-.1.2-.1.3-.4.2-.7-.6-1-.9-2.2-.9-3.4s.3-2.4.9-3.4c.3-.1.2-.4-.1-.6zM15.4 57.8c-.2-.1-.5-.1-.7.2-1 1.7-1.5 3.6-1.5 5.6s.5 3.9 1.5 5.6c.1.2.3.2.4.2.1 0 .2 0 .2-.1.2-.1.3-.4.2-.7-.9-1.5-1.4-3.3-1.4-5.1 0-1.8.5-3.5 1.4-5.1.2-.2.1-.5-.1-.6zM12.5 56.1c-.2-.1-.5-.1-.7.2-1.3 2.2-1.9 4.7-1.9 7.2s.7 5 1.9 7.2c.1.2.3.2.4.2.1 0 .2 0 .2-.1.2-.1.3-.4.2-.7-1.2-2-1.8-4.4-1.8-6.8 0-2.4.6-4.7 1.8-6.8.2 0 .1-.3-.1-.4z"
    />
    <path
      fill="currentColor"
      d="M49.5 2c-.9 0-1.7.5-2 1.4-1.6 4.5-5.4 14.7-8.5 24.3V11.2c0-3.6-2.9-6.5-6.5-6.5-1.2 0-2.3.3-3.2.9-2 1.1-3.3 3.2-3.3 5.6v43.3c-2.4 1.9-4 4.9-4 8.2 0 5.8 4.7 10.5 10.5 10.5 1.9 0 3.7-.5 5.2-1.4 3.1-1.8 5.3-5.2 5.3-9.1v-.8c2 .9 4.3 1.4 6.5 1.4 8.6 0 15.6-7 15.6-15.6 0-6.8-9.5-33.3-13.7-44.4-.2-.8-1-1.3-1.9-1.3zM36.7 70.1c-1.3.7-2.8 1.1-4.2 1.1-2.3 0-4.4-.9-6-2.5S24 65 24 62.7c0-2.6 1.2-5 3.2-6.7l.8-.6V11.2c0-1.6.9-3.1 2.3-3.9.7-.4 1.5-.6 2.2-.6 2.5 0 4.5 2 4.5 4.5v44.3l.8.6c2.1 1.6 3.2 4.1 3.2 6.7 0 3-1.6 5.8-4.3 7.3zm12.8-8.7c-2.5 0-4.9-.7-7.1-2-.7-1.9-1.9-3.6-3.4-4.9V33.9c.1-.1.2-.2.2-.4C42.5 22.7 47.4 9.3 49.4 4c0-.1.2-.1.2 0 3.2 8.6 13.5 37.1 13.5 43.7 0 7.6-6.1 13.7-13.6 13.7z"
    />
    <path
      fill="currentColor"
      d="M55.3 53.9c.1 0 .3 0 .4-.1 1.9-1.9 2.9-4.4 2.9-7.1 0-.9-.3-2.4-.8-4.4-.1-.3-.3-.4-.6-.4-.3.1-.4.3-.4.6.5 2 .8 3.4.8 4.2 0 2.4-.9 4.6-2.6 6.3-.2.2-.2.5 0 .7.1.1.2.2.3.2z"
    />
    <g opacity=".1">
      <path
        fill="currentColor"
        d="M41.6 64.2c-.8 0-1.6.2-2.2.6-1.3.8-2.3 2.2-2.3 3.9 0 2.5 2 4.5 4.5 4.5.8 0 1.6-.2 2.2-.6 1.3-.8 2.3-2.2 2.3-3.9 0-2.4-2.1-4.5-4.5-4.5zm1.7 7.6c-.5.3-1.1.5-1.7.5-.9 0-1.8-.4-2.5-1-.7-.7-1-1.5-1-2.5 0-1.2.7-2.4 1.8-3 .5-.3 1.1-.5 1.7-.5.9 0 1.8.4 2.5 1 .7.7 1 1.5 1 2.5 0 1.2-.7 2.4-1.8 3zM42.1 27.2h-1v37h1zM33.1 36.2l-5.1.1v1l5.1-.1zM31.1 33.2h2v1h-2zM31.1 30.2h2v1h-2zM31.1 27.2h2v1h-2zM31.1 24.2h2v1h-2zM33.1 51.2l-5.1.1v1l5.1-.1zM31.1 48.2h2v1h-2zM31.1 45.2h2v1h-2zM31.1 42.2h2v1h-2zM31.1 39.2h2v1h-2z"
      />
      <path
        fill="currentColor"
        d="M59.6 9.7c-.4-1-1.7-1-2.1 0-1.9 5.2-6.2 17-9.5 27.3V17.2c0-3.6-2.9-6.5-6.5-6.5-1.2 0-2.3.3-3.2.9-1.9 1.1-3.3 3.2-3.3 5.6v43.3c-2.4 1.9-4 4.9-4 8.2 0 5.8 4.7 10.5 10.5 10.5 1.9 0 3.7-.5 5.2-1.4 3.1-1.8 5.3-5.2 5.3-9.1 0-.7-.1-1.4-.2-2 2 1 4.3 1.6 6.7 1.6 8.1 0 14.6-6.5 14.6-14.6.1-6.7-10.2-35-13.5-44zM45.8 76.1c-1.3.7-2.8 1.1-4.2 1.1-2.3 0-4.4-.9-6-2.5-1.6-1.6-2.5-3.7-2.5-6 0-2.6 1.2-5 3.2-6.7l.8-.6V17.1c0-1.6.9-3.1 2.3-3.9.7-.4 1.5-.6 2.2-.6 2.5 0 4.5 2 4.5 4.5v44.3l.8.6c2.1 1.6 3.2 4.1 3.2 6.7 0 3.1-1.7 5.9-4.3 7.4z"
      />
    </g>
  </g>
);

const getIconBySize = size => {
  switch (true) {
    case size < 32:
      return { viewBox: '0 0 16 16', icon: <IconSize16 /> };

    case size < 40:
      return { viewBox: '0 0 32 32', icon: <IconSize32 /> };

    case size === 40:
      return { viewBox: '0 0 40 40', icon: <IconSize40 /> };

    default:
      return { viewBox: '0 0 80 80', icon: <IconSize80 /> };
  }
};

export default ({ classes = {}, size = 40 }: SensorIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
