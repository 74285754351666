import * as React from 'react';
import styles from './ListRow.module.css';
import classNames from '../../../utils/classNames';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  autoHeight?: boolean;
  extraClassNames?: string[];
  size?: 'small' | 'medium' | 'large' | 'auto';
  onClick?: () => void;
  extraStyles?: any;
  fullWidth?: boolean;
  expanded?: boolean;
};

const ListRow = ({
  children,
  onClick,
  disabled = false,
  extraClassNames = [],
  size = 'medium',
  autoHeight = false,
  extraStyles = {},
  fullWidth = false,
  expanded = false,
}: Props) => (
  <div
    onClick={typeof onClick === 'function' ? onClick : undefined}
    className={classNames(
      styles.row,
      fullWidth ? undefined : styles.grid,
      autoHeight ? undefined : styles[size],
      disabled ? styles.disabled : undefined,
      expanded ? styles.expanded : undefined,
      ...extraClassNames
    )}
    style={extraStyles}
  >
    {children}
  </div>
);

export default ListRow;
