import * as React from 'react';
import FormText from '../../../ui/FormText/FormText';

type Props = {
  label: string;
  value?: number;
};

export default function(props: Props) {
  if (!props.value) {
    return null;
  }

  return (
    <FormText>
      {props.label} {props.value / 60 / 1000} minutes
    </FormText>
  );
}
