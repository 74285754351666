import { Actions } from '../actions/sentinels';
import { Sentinel } from '../types/sentinel';

const initialState: Sentinel[] = [];

function muteSentinel(s: Sentinel, reason) {
  return {
    ...s,
    muted: true,
    reason,
  };
}

function resumeSentinel(s: Sentinel) {
  return {
    ...s,
    muted: undefined,
    reason: undefined,
  };
}

export default (
  state: Sentinel[] = initialState,
  action: { type: string; payload: any; extraPayload: any; sentinelId: string }
) => {
  switch (action.type) {
    case Actions.SENTINELS_SUCCESS:
      return [...action.payload];

    case Actions.DELETE_SUCCESS:
      return state.filter(s => s.id !== action.sentinelId);

    case Actions.MUTE_SUCCESS:
      return state.map(s =>
        s.id === action.extraPayload.sentinelId
          ? muteSentinel(s, action.extraPayload.reason)
          : s
      );

    case Actions.RESUME_SUCCESS:
      return state.map(s =>
        s.id === action.extraPayload.sentinelId ? resumeSentinel(s) : s
      );

    default: {
      return state;
    }
  }
};
