import * as React from 'react';
import styles from './Menu.module.css';

type Props = {
  children: React.ReactNode;
};

const Menu = ({ children, ...rest }: Props) => (
  <div className={styles.root} {...rest}>
    {children}
  </div>
);

export default Menu;
