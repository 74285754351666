import { getTinyPathByResourceId } from '../../actions/resources';
import { connect } from 'react-redux';
import Path from '../../components/Path';

const mapStateToProps = (state: any, ownProps: { resourceId: number }) => ({
  path: state.resources[ownProps.resourceId] || [],
});

const mapDispatchToProps = {
  getTinyPathByResourceId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Path);
