import connect from 'react-redux/es/connect/connect';
import SentinelsConfigurationList from '../../components/Tables/SentinelsConfigurationList';

const mapStateToProps = state => ({
  userRole: state.app.currentUser.role,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SentinelsConfigurationList);
