import React from 'react';
import styles from './ExitWizardModal.module.css';
import Button from '../ui/Button';
import BaseModal from './BaseModal';

type Props = {
  onClose: any;
  onSubmit: any;
};

export default ({ onClose, onSubmit }: Props) => (
  <BaseModal onClose={onClose} title="Exit configuration">
    <div className={styles.infoText}>
      Would you like to exit the Configuration? Please note, that all changes
      will be lost.
    </div>
    <div className={styles.buttonGroup}>
      <Button onClick={onSubmit}>Exit</Button>
      <Button onClick={onSubmit} secondary>
        Cancel
      </Button>
    </div>
  </BaseModal>
);
