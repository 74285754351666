import { Equipment } from './equipment';
import { ResourceType } from './resource';
import { Space } from './space';
import { OperationalSample } from './samples';

export type SensorStatus = {
  _entity: 'SensorStatus';
  id: number;
  batteryLevel?: any;
  batteryVoltage?: number;
  interval: number;
  signalStrength: number;
  signalStrengthLevel?: number;
  state: SensorState;
  temperature?: number;
  createdAt: string;
  updatedAt?: string;
  processedAt: string;
  receivedAt: string;
};

export enum SensorState {
  ACTIVE = 'Online',
  INACTIVE = 'Offline',
}

type SensorInfo = {
  _entity: 'SensorInfo';
  id: number;
  _path?: any;
  firmwareVersion: string;
  hardwareVersion: string;
  location: string;
  signalStrength: number;
  createdAt: string;
  updatedAt?: string;
  processedAt: string;
  receivedAt: string;
};

export enum SensorType {
  TEMPERATURE = 'TEMPERATURE',
  HUMIDITY = 'HUMIDITY',
  BINARY = 'BINARY',
}

export enum SensorTarget {
  SPACE = 'SPACE',
  EQUIPMENT = 'EQUIPMENT',
}

export type Sensor = {
  _entity: ResourceType.SENSOR;
  id: number;
  title: string;
  description: string;

  modelId?: number;
  resourceId: number;
  uuid: string;
  vendor?: string;
  productId?: number;

  sensorStatus?: SensorStatus;
  sensorInfo?: SensorInfo;

  firmwareVersion: string;
  hardwareVersion: string;

  resource?: Equipment | Space;

  location: string;

  createdAt: string;
  updatedAt?: string;

  types: SensorType[];
  status?: any;
  gateway?: any;
};

export const getOperationalValue = (
  type: string,
  sample?: OperationalSample | void
) => {
  if (!sample) {
    return;
  }

  switch (sample.type) {
    case SensorType.HUMIDITY:
      return sample.humidity;
    case SensorType.TEMPERATURE:
      return sample.temperature;
    case SensorType.BINARY:
      return sample.state.length > 0 && sample.state[0] ? 'Open' : 'Closed';
    default:
      throw new Error('unknown sensor type');
  }
};

export type SensorApiFilter = {
  siteIds?: number[];
  ids?: number[];
  limit?: number;
  skip?: number;
  sensorTypes?: SensorType[];
  withPath?: boolean;
};
