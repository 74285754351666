export enum Actions {
  INIT = '@@app/INIT',

  INIT_WITH_TOKEN = '@@app/INIT_WITH_TOKEN',
  ACCESS_TOKEN_UPDATED = '@@app/ACCESS_TOKEN_UPDATED',
  ACCESS_TOKEN_CLEARED = '@@app/ACCESS_TOKEN_CLEARED',

  LOGIN_SUCCESS = '@@app/LOGIN_SUCCESS',
  LOGIN_LOADING = '@@app/LOGIN_LOADING',
  LOGIN_ERROR = '@@app/LOGIN_ERROR',

  LOGIN_FROM_LOCALSTORAGE = '@@LOGIN_FROM_LOCALSTORAGE',

  LOGOUT = '@@app/LOGOUT',

  CURRENT_USER_SUCCESS = '@@app/CURRENT_USER_SUCCESS',
  CURRENT_USER_ERROR = '@@app/CURRENT_USER_ERROR',
  CURRENT_USER_LOADING = '@@app/CURRENT_USER_LOADING',

  CHANGE_ORGANIZATION_SUCCESS = '@@app/CHANGE_ORGANIZATION_SUCCESS',
  CHANGE_ORGANIZATION_ERROR = '@@app/CHANGE_ORGANIZATION_ERROR',
  CHANGE_ORGANIZATION_LOADING = '@@app/CHANGE_ORGANIZATION_LOADING',
}

export const initApp = () => ({
  type: Actions.INIT,
});

export const initAppWithToken = ({ accessToken }) => ({
  type: Actions.INIT_WITH_TOKEN,
  payload: { accessToken },
});

export const logout = () => ({
  type: Actions.LOGOUT,
});

export interface FnLogin {
  (email: string, password: string): void;
}
export const login: FnLogin = (email, password) => ({
  type: 'API_POST',
  path: '/api/v1/auth/login',
  payload: { email, password },
  loading: Actions.LOGIN_LOADING,
  success: Actions.LOGIN_SUCCESS,
  error: Actions.LOGIN_ERROR,
});

export const loginFromLocalStorage = () => ({
  type: Actions.LOGIN_FROM_LOCALSTORAGE,
});

export const getCurrentUser = () => ({
  type: 'API_GET',
  path: '/api/v1/auth/user',
  loading: Actions.CURRENT_USER_LOADING,
  success: Actions.CURRENT_USER_SUCCESS,
  error: Actions.CURRENT_USER_ERROR,
});

export const switchOrganization = (id: number) => ({
  type: 'API_POST',
  path: `/api/v1/auth/switch/organization/${id}`,
  loading: Actions.CHANGE_ORGANIZATION_LOADING,
  success: Actions.CHANGE_ORGANIZATION_SUCCESS,
  error: Actions.CHANGE_ORGANIZATION_ERROR,
});
