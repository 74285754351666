export function toCelsius(fahrenheit: number): number {
  return ((fahrenheit - 32) * 5) / 9;
}

export function toFahrenheit(celsius: number): number {
  return (celsius * 9) / 5 + 32;
}

export default function convertTemp(
  value: number | string,
  from: 'c' | 'f',
  decimals: number = 2
) {
  let valCopy = value;
  /* eslint-disable */
  if (isNaN(Number(valCopy))) {
    return 'n/a';
  }
  /* eslint-enable */

  if (typeof valCopy !== 'number') valCopy = Number(valCopy);

  if (from === 'c') {
    return +toFahrenheit(valCopy).toFixed(decimals);
  }

  return +toCelsius(valCopy).toFixed(decimals);
}
