import * as React from 'react';
import styles from './RolloutSelector.module.css';

type Props = {
  isCollapsed: boolean;
  selected: number;
  totalSelected: number;
  onSelect: () => void;
  onDelete: () => void;
};

const RolloutSelector: React.FunctionComponent<Props> = ({
  isCollapsed,
  selected,
  totalSelected,
  onSelect,
  onDelete,
}) => {
  if (isCollapsed) {
    return selected === totalSelected ? (
      <button className={styles.button} onClick={onDelete}>
        Deselect all
      </button>
    ) : (
      <button className={styles.button} onClick={onSelect}>
        Select all
      </button>
    );
  }

  return (
    <>
      <span className={styles.filterSelected}>{selected}</span>
      <span className={styles.filterTotal}>{totalSelected}</span>
    </>
  );
};

export default RolloutSelector;
