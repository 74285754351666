import * as React from 'react';
import { Link } from '@reach/router';
import * as routes from '../../routes';

import MenuSidebarItem from '../../components/MenuSidebarItem';
import Icon from '../../components/Icon';
import classNames from '../../utils/classNames';
import styles from './MenuSidebar.module.css';
import GlobalActivityChip from '../chips/GlobalActivityChip';
import { NotificationCountType } from '../../types/notification';
import { connect } from 'react-redux';
import { logout } from '../../actions/app';
import { openDialog } from '../../actions/dialog';
import { Dialog, DialogTypes } from '../../types/dialog';

const MenuItems = [
  {
    to: routes.NOTIFICATIONS,
    custom: (
      <GlobalActivityChip type={NotificationCountType.all} resourceId={0} />
    ),
    icon: undefined,
  },
  {
    to: routes.MONITORING_LIST_EQUIPMENT,
    icon: 'equipment',
    custom: undefined,
  },
  {
    to: routes.MONITORING_LIST_SITES,
    icon: 'site',
    custom: undefined,
  },
  {
    to: routes.USERS,
    icon: 'user',
    custom: undefined,
  },
];

type Props = {
  isFixed?: boolean;
  logout: () => void;
  openDialog: (dialog: Dialog) => void;
};

function MenuSidebar({ openDialog, isFixed, logout }: Props) {
  return (
    <ul
      className={classNames(styles.nav, isFixed ? styles.navFixed : undefined)}
    >
      <li className={styles.navItem}>
        <Link to="/" className={styles.logoLink}>
          <Icon size={25} icon="logo" />
        </Link>
      </li>
      {MenuItems.map(item => (
        <MenuSidebarItem
          custom={item.custom}
          key={item.to}
          to={item.to}
          icon={item.icon}
        />
      )).concat(
        <MenuSidebarItem
          key="MenuSidebarItemLogout"
          classes={[styles.topPadding]}
          icon="logout"
          onClick={() =>
            openDialog({
              actionFn: () => logout(),
              title: 'Logout',
              content: 'Are you sure you want to logout?',
              isOpen: true,
              type: DialogTypes.DEFAULT,
            })
          }
        />
      )}
    </ul>
  );
}

const mapDispatchToProps = {
  logout,
  openDialog,
};

export default connect(
  null,
  mapDispatchToProps
)(MenuSidebar);
