import { Actions as AppActions } from '../actions/app';
import { store } from '..';
import { getServiceUrl } from '../config';

const ALLOWED_METHODS = ['GET', 'POST', 'PUT', 'PATCH', 'UPDATE', 'DELETE'];

const matcher = new RegExp(`^API_(${ALLOWED_METHODS.join('|')})$`);

const publicPaths = ['/api/v1/auth/login'];

const requiresAuthorization = (path: string): boolean =>
  publicPaths.indexOf(path) === -1;

const genHeaders = (store, action) => {
  const { accessToken } = store.getState().app;
  const { path } = action;

  const headers = {
    'Content-Type': 'application/json',
  };

  if (requiresAuthorization(path) && accessToken && accessToken !== '') {
    headers['authorization'] = `Bearer ${accessToken}`;
  }

  return headers;
};

const dispatchAction = (store: any, action: any, data: any = {}) => {
  if (typeof action === 'string') {
    store.dispatch({ type: action, ...data });
  } else {
    store.dispatch({ ...action, ...data });
  }
};

const requestCache = new Set<string>();

const apiMiddleware = store => next => action => {
  const matchedGroup = action.type.match(matcher);
  if (matchedGroup) {
    const { path, payload, service } = action;

    const successAction = action.success || '@@API/UNHANDLED_SUCCESS_RESPONSE';
    const errorAction = action.error || '@@API/UNHANDLED_ERROR_RESPONSE';

    const method = matchedGroup[1];

    const url = getServiceUrl(service) + path;
    const urlKey = method + url;

    if (action.loading || (action.type && action.type.loading)) {
      if (requestCache.has(urlKey)) {
        return;
      }

      requestCache.add(urlKey);
      dispatchAction(store, action.loading);
    }

    fetch(url, {
      method,
      headers: genHeaders(store, action),
      body: JSON.stringify(payload),
    })
      .then(response => {
        // todo: use actions instead of URL
        if (response.status === 401 && url.includes('/login') === false) {
          store.dispatch({ type: AppActions.LOGOUT });
        }
        return response.json();
      })
      .then(response => {
        dispatchAction(store, response.error ? errorAction : successAction, {
          payload: response,
        });
        requestCache.delete(urlKey);
      })
      .catch(error => {
        dispatchAction(store, errorAction, { data: error });
        requestCache.delete(urlKey);
      });
  } else {
    next(action);
  }
};

export async function fetchApi({
  endpoint = '',
  method = 'GET',
  payload = {},
  service,
}: {
  endpoint: string;
  method?: string;
  payload?: any;
  service?: string;
}): Promise<any> {
  const fullUrl = `${getServiceUrl(service)}${endpoint}`;
  const headers = genHeaders(store, '');

  const body = Object.keys(payload).length
    ? JSON.stringify(payload)
    : undefined;

  try {
    const response = await fetch(fullUrl, { method, headers, body });

    if (response.status === 401) {
      store.dispatch({ type: AppActions.LOGOUT });
      return Promise.reject(Error('Unauthorized'));
    }

    if (response.status === 403) {
      return Promise.reject(new Error('Forbidden'));
    }

    if (!response.ok) {
      return Promise.reject(new Error(`API request failed ${response.status}`));
    }

    if (response.status === 204) {
      return Promise.resolve();
    }

    const responseContentType = response.headers.get('content-type');

    if (responseContentType) {
      if (
        responseContentType.startsWith('application/json') ||
        responseContentType.startsWith('application/vnd.api+json')
      ) {
        return Promise.resolve(response.json());
      }
    }

    return Promise.resolve(response.text());
  } catch (err) {
    return Promise.reject(err);
  }
}

export default apiMiddleware;
