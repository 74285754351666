import * as React from 'react';
import styles from './InputConditionField.module.css';

type Props = {
  value: React.ReactNode;
  unit: React.ReactNode;
};

const StepConditionItemInput = ({ value, unit }: Props) => (
  <div className={styles.container}>
    <div className={styles.input}>{value}</div>
    {unit}
  </div>
);

export default StepConditionItemInput;
