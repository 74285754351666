import { Sensor } from '../types/sensors';
import { Actions } from '../actions/sensors';

const initialState: Sensor[] = [];

export default (
  state: Sensor[] = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case Actions.BY_ID_SUCCESS: {
      return [...state.filter(e => e.id !== action.payload.id), action.payload];
    }
    default: {
      return state;
    }
  }
};
