import { combineReducers } from 'redux';
import app from './app';
import dialog from './dialog';
import equipmentFilter from './equipmentFilter';
import equipmentGrouping from './equipmentGrouping';
import equipmentTags from './equipmentTags';
import equipment from './equipment';
import loading from './loading';
import pageInfo from './pageInfo';
import sites from './sites';
import siteFilter from './siteFilter';
import spaces from './spaces';
import sensors from './sensors';
import sentinels from './sentinels';
import notifications from './notifications';
import notificationsCount from './notificationsCount';
import notificationFilter from './notificationFilter';
import resources from './resources';

import { Actions } from '../actions/app';

const appReducer = combineReducers({
  app,
  dialog,
  equipment,
  equipmentFilter,
  equipmentGrouping,
  equipmentTags,
  loading,
  notifications,
  notificationsCount,
  notificationFilter,
  pageInfo,
  sites,
  siteFilter,
  spaces,
  sensors,
  sentinels,
  resources,
});

const rootReducer = (state, action) => {
  if (action.type === Actions.LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
