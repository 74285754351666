import * as React from 'react';
import styles from './AddEntry.module.css';
import Button from '../../../ui/Button/Button';
import PlusIcon from '../../../icons/ui/Plus';

type Props = {
  onClick: () => void;
  title?: string;
  disabled?: boolean;
};

function AddEntry(props: Props) {
  const { onClick, title = '' } = props;

  return (
    <div className={styles.root}>
      <Button secondary onClick={onClick}>
        <PlusIcon className={styles.editIcon} /> {title || 'Add new action'}
      </Button>
    </div>
  );
}

export default AddEntry;
