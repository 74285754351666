import { Actions } from '../actions/notifications';

const initialState: number = 0;

export default (
  state: number = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case Actions.COUNT_SUCCESS: {
      return action.payload.count;
    }

    case Actions.DISMISS_SUCCESS: {
      return state - 1;
    }

    default: {
      return state;
    }
  }
};
