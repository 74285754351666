import * as React from 'react';
import styles from './DropDownItem.module.css';

type Props = {
  children: React.ReactNode;
  onClick: any;
  rest?: any;
};

const dropDownItem = ({ children, onClick, ...rest }: Props) => (
  <li className={styles.wrapper} onClick={onClick} {...rest}>
    {children}
  </li>
);

export default dropDownItem;
