import * as React from 'react';
import styles from './Tooltip.module.css';
import className from '../utils/classNames';

type Props = {
  content?: React.ReactNode;
  arrowDirection?: 'top' | 'left' | 'bottom' | 'right';
  extraClassNames?: string[];
  hoverOverride?: boolean;
};

const Tooltip: React.FunctionComponent<Props> = ({
  content,
  extraClassNames = [],
  children,
  arrowDirection = 'left',
  hoverOverride = false,
}) => (
  <div
    className={className([
      styles.tooltip,
      styles[arrowDirection],
      hoverOverride ? styles.hoverOverride : '',
      ...extraClassNames,
    ])}
  >
    <div className={styles.tooltipComment}>{children}</div>
    {content && (
      <span className={className(styles.tooltipText, styles[arrowDirection])}>
        {content}
      </span>
    )}
  </div>
);

export default Tooltip;
