import * as React from 'react';
import styles from './StepHandling.module.css';
import {
  initialSentinelEscalationWithoutDelay,
  SentinelApiRecipient,
  SentinelEscalation,
  SentinelRecipientsChannel,
} from '../../../../types/sentinel';
import { fetchGroups } from '../../../../actions/groups';
import StepTitle from '../StepTitle';
import FormText from '../../../ui/FormText/FormText';
import StepHandlingItem from './StepHandlingItem';
import { fetchUsers } from '../../../../actions/user';
import AddEntry from '../StepActions/AddEntry';

type Props = {
  handleAddStepHandling: (escalation: SentinelEscalation) => void;
  handleRemoveStepHandling: (index: number) => () => void;
  handleUpdateStepHandling: (
    index: number
  ) => (escalation: SentinelEscalation) => void;
  escalations: SentinelEscalation[];
};

type State = {
  isLoading: boolean;
  data: any[];
};

export type Recipient = {
  title: string;
  channel: SentinelRecipientsChannel;
  identifier: string;
  id: number;
};

class StepHandling extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({ isLoading: true });

    Promise.all([fetchUsers(), fetchGroups()]).then(response => {
      const [usersResponse, groupsResponse] = response;
      this.setState({
        isLoading: false,
        data: [
          ...usersResponse.map((item: any) => ({
            id: item.id,
            shortTitle: 'U',
            title: item.email, // `${u.firstName.toLowerCase()}.${u.lastName.toLowerCase()}`,
            channel: SentinelRecipientsChannel.USER_EMAIL,
            identifier: item.id,
          })),
          ...groupsResponse.map((item: any) => ({
            id: item.id,
            shortTitle: 'G',
            title: `${item.title}`,
            channel: SentinelRecipientsChannel.USER_GROUP_EMAIL,
            identifier: item.id,
          })),
        ],
      });
    });
  }

  addTitleToEscalation = (item: SentinelApiRecipient): Recipient => {
    const { data } = this.state;
    const id = +item.identifier;

    return {
      title: (data.find(o => o.id === id) || { title: '...loading' }).title,
      channel: item.channel,
      identifier: item.identifier,
      id,
    };
  };

  handleAddEscalation = () =>
    this.props.handleAddStepHandling(initialSentinelEscalationWithoutDelay);

  render() {
    const { escalations = [] } = this.props;
    const { data, isLoading } = this.state;

    const lastEscalationIsValid =
      escalations[escalations.length - 1].recipients.length > 0 &&
      escalations[escalations.length - 1].name !== '';

    return (
      <div className={styles.stepWrapper}>
        <StepTitle>
          Please define the actions and the staff handling the triggered
          notifications.
        </StepTitle>

        <div className={styles.formHeading}>
          <div>
            <FormText>Action Name *</FormText>
          </div>
          <div>
            <FormText>Assign *</FormText>
          </div>
          <div>
            <FormText>Priority</FormText>
          </div>
          <div>
            <FormText>Channels</FormText>
          </div>
          <div>
            <FormText>Message</FormText>
          </div>
        </div>

        {escalations.map((escalation: SentinelEscalation, index) => {
          const escalationWithTitle = {
            ...escalation,
            recipients: escalation.recipients.map(this.addTitleToEscalation),
          };

          return (
            <div
              key={`StepHandlingElement${escalation.ix}`}
              className={styles.stepHandling}
            >
              <StepHandlingItem
                handleAddEscalation={this.handleAddEscalation}
                handleUpdateEscalation={this.props.handleUpdateStepHandling(
                  index
                )}
                handleDeleteEscalation={this.props.handleRemoveStepHandling(
                  index
                )}
                options={data}
                escalation={escalationWithTitle}
                isDeleteAble={index !== 0}
                index={index}
                isLoading={isLoading}
              />
            </div>
          );
        })}

        {escalations.length < 5 && (
          <AddEntry
            title="Add new action"
            onClick={this.handleAddEscalation}
            disabled={lastEscalationIsValid === false}
          />
        )}
      </div>
    );
  }
}

export default StepHandling;
