import * as React from 'react';
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';
import SearchWrapper from './SearchWrapper';
import Button from '../ui/Button/Button';
import styles from './SearchBox.module.css';

type Props = {
  query?: string;
  color?: 'ambient' | 'accent' | 'shade';
  width: string;
  widthActive: string;
  placeholder: string;
  items?: any;
  onChange?: (query: string) => void;
  withDropdown?: boolean;
  error?: boolean;
  onSelectAll?: (ev: any) => void;
  selectAllText?: string;
  actions?: React.ReactNode;
  startOpen?: boolean;
  // This is for cases where this is nested in a filter bar which has its own close handler,
  // but a close button is requested inside of the children elements.
  close?: any;
  hasCloseButton?: boolean;
  searchCloseText?: string;
};

type State = {
  isOpen: boolean;
  query: string;
  useLocalState: boolean;
};

class SearchBox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: props.startOpen || false,
      query: '',
      useLocalState: true,
    };
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClick);
    if (typeof this.props.onChange === 'function') {
      this.setState({ useLocalState: false });
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClick);
  };

  getQueryValue = () =>
    this.state.useLocalState ? this.state.query : this.props.query;

  setWrapperRef = (ref: any) => {
    this.container = ref;
  };

  container: any;

  handleQueryChange = (event: any) => {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      onChange(event.target.value);
      return;
    }

    this.setState({ query: event.target.value });
  };

  handleClick = (event: Event) => {
    if (!this.container.current.contains(event.target)) {
      this.handleClose();
    }
  };

  handleOpen = () => {
    this.setState({
      isOpen: true,
    });
  };

  handleClose = () => {
    if (this.props.close) {
      this.props.close();
      return;
    }

    this.setState({
      isOpen: false,
    });
  };

  render() {
    const query = this.getQueryValue();
    const {
      color = 'shade',
      width,
      widthActive,
      items = [],
      placeholder,
      withDropdown,
      error,
      searchCloseText = '',
      onSelectAll,
      selectAllText,
      actions,
      hasCloseButton,
    } = this.props;

    const hasItems = items.length > 0;

    return (
      <SearchWrapper
        wrapperRef={this.setWrapperRef}
        active={this.state.isOpen}
        width={width}
        widthActive={widthActive}
      >
        <SearchInput
          active={this.state.isOpen}
          color={color}
          value={query || ''}
          onFocus={this.handleOpen}
          onChange={this.handleQueryChange}
          placeholder={placeholder}
          error={error}
        />
        {this.state.isOpen &&
          (onSelectAll || withDropdown || actions || hasCloseButton) && (
            <div className={styles.openContainer}>
              {onSelectAll && hasItems && (
                <div className={styles.unselectAll}>
                  <span
                    tabIndex={0}
                    onKeyPress={onSelectAll}
                    role="button"
                    onClick={onSelectAll}
                  >
                    {selectAllText}
                  </span>
                </div>
              )}
              {withDropdown && (
                <SearchResults active={this.state.isOpen} color={color}>
                  {hasItems ? items : null}
                </SearchResults>
              )}
              {(hasCloseButton || actions) && (
                <div className={styles.actionsFooter}>
                  {hasItems && actions}
                  {hasItems && hasCloseButton && (
                    <Button secondary onClick={() => this.handleClose()}>
                      {searchCloseText || 'Cancel'}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
      </SearchWrapper>
    );
  }
}

export default SearchBox;
