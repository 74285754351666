import * as React from 'react';
import styles from './ScheduleWeekDayButton.module.css';
import classNames from '../../../../utils/classNames';
import Button from '../../../ui/Button/Button';
import { SentinelScheduleWeekDay } from '../../../../types/sentinel';

type Props = {
  day: SentinelScheduleWeekDay;
  onClick: any;
  isActive?: boolean;
};

function ScheduleWeekDayButton(props: Props) {
  const { day, onClick, isActive } = props;

  return (
    <Button onClick={() => onClick()} extraClassName={styles.button}>
      <span
        className={classNames(
          styles.root,
          isActive ? styles.active : undefined
        )}
      >
        {day.substr(0, 2).toLowerCase()}
      </span>
    </Button>
  );
}

export default ScheduleWeekDayButton;
