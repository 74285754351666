import * as React from 'react';
import { UiIcon } from '../../../types/icon';

const IconSize16 = () => (
  <g>
    <path
      d="M12.7 11.3l-.3.3-.9-.9c1-1.1 1.6-2.6 1.6-4.2C13 2.9 10.1 0 6.5 0S0 2.9 0 6.5 2.9 13 6.5 13c1.6 0 3.1-.6 4.2-1.6l.9.9-.3.3 3.3 3.3 1.4-1.4-3.3-3.2zm-6.2.7C3.5 12 1 9.5 1 6.5S3.5 1 6.5 1 12 3.5 12 6.5 9.5 12 6.5 12z"
      fill="currentColor"
    />
    <path
      d="M6.5 2.5c-.2 0-.5 0-.7.1-.3 0-.5.3-.4.6 0 .3.3.5.6.4h.5c1.7 0 3 1.3 3 3 0 .2.2.4.5.4s.5-.2.5-.5c0-2.2-1.8-4-4-4z"
      fill="currentColor"
    />
  </g>
);

const IconSize32 = () => (
  <g>
    <path
      d="M24.5 21.6L23 23.1l.3-.3-1.7-1.8c2.1-2.2 3.4-5.2 3.4-8.5C25 5.6 19.4 0 12.5 0S0 5.6 0 12.5 5.6 25 12.5 25c3.2 0 6.2-1.3 8.4-3.3l1.7 1.7-1.1 1.1L29 32l3-3-7.5-7.4zm-12 2.4C6.2 24 1 18.8 1 12.5S6.2 1 12.5 1 24 6.2 24 12.5 18.8 24 12.5 24zm10.4.6l1.6-1.6 6.1 6.1-1.6 1.5-6.1-6z"
      fill="currentColor"
    />
    <path
      d="M12.5 3.7c-.4 0-.9 0-1.3.1-.3 0-.5.3-.4.6 0 .3.3.5.6.4.4-.1.8-.1 1.2-.1 4.1 0 7.5 3.2 7.8 7.2 0 .3.2.5.5.5s.5-.3.5-.5c-.5-4.6-4.3-8.2-8.9-8.2z"
      fill="currentColor"
    />
  </g>
);

const getIconBySize = (size: number) => {
  switch (true) {
    case size <= 16:
      return { viewBox: '0 0 16 16', icon: <IconSize16 /> };

    default:
      return { viewBox: '0 0 32 32', icon: <IconSize32 /> };
  }
};

export default ({ classes = {}, size = 16 }: UiIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
