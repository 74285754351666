import React from 'react';
import styles from './SourceChip.module.css';
import IconGenericSpace from '../icons/spaces/IconGenericSpace';
import IconEquipmentFactory from '../icons/IconEquipmentFactory';
import { ResourcePath, ResourceType } from '../../types/resource';

type Props = {
  resourceId: number;
  path: ResourcePath[];
  getPathByResourceId: (resourceId: number) => void;
};

class SourceChip extends React.Component<Props> {
  componentDidMount(): void {
    const { resourceId, path } = this.props;

    if (resourceId === 0) {
      return;
    }

    if (path.length > 0) {
      return;
    }

    this.props.getPathByResourceId(resourceId);
  }

  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return this.props.path.length !== nextProps.path.length;
  }

  render() {
    const { children, path } = this.props;
    const target = [...path]
      .reverse()
      .find(
        p => p.type === ResourceType.EQUIPMENT || p.type === ResourceType.SPACE
      );

    return (
      <>
        <div className={styles.root}>
          {target && target.type === ResourceType.SPACE && (
            <IconGenericSpace size={16} />
          )}

          {target && target.type === ResourceType.EQUIPMENT && (
            <IconEquipmentFactory size={16} id={target ? target.typeId : ''} />
          )}

          <span className={styles.title}>{target && target.title}</span>
        </div>

        {children}
      </>
    );
  }
}

export default SourceChip;
