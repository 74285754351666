import React from 'react';
import classNames from '../../../utils/classNames';
import styles from './SystemMessage.module.css';
import Button from '../Button/Button';
import IconControlFactory from '../../icons/IconUiFactory';

type Props = {
  title?: string;
  message: string;
  type: string;
  onClick: any;
};

const SystemMessage = ({ title, message, type, onClick }: Props) => (
  <div className={classNames(styles.wrapper, styles[type])}>
    <div>
      {title ? <span className={styles.title}>{title}:</span> : null} {message}
    </div>
    <Button onClick={onClick} extraClassName={styles[type]} icon>
      {IconControlFactory({ id: 'close' })}
    </Button>
  </div>
);

export default SystemMessage;
