/* eslint-disable jsx-a11y/label-has-for */

import * as React from 'react';
import styles from './RangeField.module.css';
import { ComparisonType } from '../../../types/sentinel';

export function absoluteToPercentageValue(
  min: number,
  max: number,
  value: number
): number {
  return ((value - min) / (max - min)) * 100;
}
/*
export function percentageToAbsoluteValue(
  min: number,
  max: number,
  value: number
): number {
  return (value / 100) * (max - min) + min;
} */

type Props = {
  min: number;
  max: number;
  step: number;
  condition?: ComparisonType;
  valueFormatterFn: (value: number) => string;
  value?: number;
  rangeLowerBound?: number;
  rangeUpperBound?: number;
  onChange: (name: string, value: number) => void;
};

function RangeField(props: Props) {
  const {
    min,
    max,
    step,
    valueFormatterFn,
    condition = ComparisonType.lt,
    value,
    rangeLowerBound,
    rangeUpperBound,
  } = props;

  const rangeIsActive =
    condition === ComparisonType.inner || condition === ComparisonType.outer;

  return (
    <div className={styles.root}>
      <div className={styles.labelContainer}>
        {rangeIsActive === false && (
          <label className={styles.label} htmlFor="threshold">
            {typeof value === 'number'
              ? valueFormatterFn(value)
              : 'Invalid value'}
          </label>
        )}

        {rangeIsActive && (
          <>
            <label htmlFor="rangeLowerBound" className={styles.label}>
              {typeof rangeLowerBound === 'number'
                ? valueFormatterFn(rangeLowerBound)
                : 'invalid value'}
            </label>
            <label htmlFor="rangeUpperBound" className={styles.label}>
              {` to ${
                typeof rangeUpperBound === 'number'
                  ? valueFormatterFn(rangeUpperBound)
                  : 'invalid value'
              }`}
            </label>
          </>
        )}
      </div>

      <div className={styles.range}>
        <div className={styles.range__track} />

        {condition === ComparisonType.lt && typeof value === 'number' && (
          <div
            className={styles.range__active}
            style={{
              left: 0,
              right: `${100 - absoluteToPercentageValue(min, max, value)}%`,
            }}
          />
        )}

        {condition === ComparisonType.gt && typeof value === 'number' && (
          <div
            className={styles.range__active}
            style={{
              left: `${absoluteToPercentageValue(min, max, value)}%`,
              right: 0,
            }}
          />
        )}

        {condition === ComparisonType.inner &&
          typeof rangeLowerBound === 'number' &&
          typeof rangeUpperBound === 'number' && (
            <div
              className={styles.range__active}
              style={{
                left: `${absoluteToPercentageValue(
                  min,
                  max,
                  rangeLowerBound
                )}%`,
                right: `${100 -
                  absoluteToPercentageValue(min, max, rangeUpperBound)}%`,
              }}
            />
          )}

        {condition === ComparisonType.outer &&
          typeof rangeLowerBound === 'number' &&
          typeof rangeUpperBound === 'number' && (
            <>
              <div
                className={styles.range__active}
                style={{
                  left: 0,
                  right: `${100 -
                    absoluteToPercentageValue(min, max, rangeLowerBound)}%`,
                }}
              />
              <div
                className={styles.range__active}
                style={{
                  left: `${absoluteToPercentageValue(
                    min,
                    max,
                    rangeUpperBound
                  )}%`,
                  right: 0,
                }}
              />
            </>
          )}

        {!rangeIsActive && (
          <input
            className={styles.range__input}
            type="range"
            value={value}
            min={min}
            max={max}
            step={step}
            onChange={ev =>
              props.onChange('threshold', parseInt(ev.target.value, 10))
            }
            name="threshold"
            id="threshold"
          />
        )}

        {rangeIsActive && (
          <>
            <input
              className={styles.range__input}
              type="range"
              value={rangeLowerBound}
              onChange={ev =>
                props.onChange('rangeLowerBound', parseInt(ev.target.value, 10))
              }
              min={min}
              max={max}
              step={step}
              name="rangeLowerBound"
              id="rangeLowerBound"
            />
            <input
              className={styles.range__input}
              type="range"
              value={rangeUpperBound}
              onChange={ev =>
                props.onChange('rangeUpperBound', parseInt(ev.target.value, 10))
              }
              min={min}
              max={max}
              step={step}
              name="rangeUpperBound"
              id="rangeUpperBound"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default RangeField;
