import * as React from 'react';
import styles from './Tabs.module.css';

const Tabs: React.FunctionComponent = ({ children }) => (
  <div role="tablist" className={styles.root}>
    {children}
  </div>
);

export default Tabs;
