import * as React from 'react';
import styles from './Path.module.css';
import { ResourcePath, ResourceType } from '../../types/resource';

type Props = {
  path: ResourcePath[];
  resourceId: number;
  resourceType?: ResourceType;
  getTinyPathByResourceId: (resourceId: number) => void;
};

class Path extends React.Component<Props> {
  componentDidMount(): void {
    const { resourceId, path } = this.props;

    if (resourceId === 0) {
      return;
    }

    if (path.length > 0) {
      return;
    }

    this.props.getTinyPathByResourceId(resourceId);
  }

  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return this.props.path.length !== nextProps.path.length;
  }

  render() {
    const { resourceType } = this.props;
    let { path } = this.props;

    if (resourceType) {
      path = path.filter(p => p.type === resourceType);
    }

    return (
      <div className={styles.root}>
        {path.map(p => (
          <span className={styles.item} key={p.id}>
            {p.title}
          </span>
        ))}
      </div>
    );
  }

  static defaultProps = {
    resourceId: 0,
    path: [],
  };
}

export default Path;
