import * as React from 'react';
import styles from './StepRollout.module.css';
import classNames from '../../../../utils/classNames';
import { Space } from '../../../../types/space';
import Checkbox from '../../../ui/Checkbox/Checkbox';
import { Site } from '../../../../types/site';
import { SentinelTarget, TargetType } from '../../../../types/sentinel';
import { fetchSpaces } from '../../../../actions/spaces';
import { SensorType } from '../../../../types/sensors';
import { SpaceChip } from './TargetChips';
import Path from '../../../../containers/Path';
import { ResourceType } from '../../../../types/resource';
import RolloutSelector from './RolloutSelector';

type Props = {
  site: Site;
  sensorTypes: SensorType[];
  isCollapsed: boolean;
  selected: number[];
  handleTargetSelect: (sentinelTarget: SentinelTarget) => void;
  handleTargetsSelect: (sentinelTarget: SentinelTarget[]) => void;
  handleTargetDelete: (sentinelTargetIds: number[]) => void;
  handleSetResourceRelation: (arg: number, arg2: number[]) => void;
};

type State = {
  spaces: Space[];
  isLoading: boolean;
};

class StepRolloutSpaces extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      spaces: [],
      isLoading: true,
    };
  }

  componentDidMount(): void {
    this.onLoad();
  }

  onLoad = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation } = this.props;

    try {
      const { site, sensorTypes } = this.props;

      const spaces = await fetchSpaces({
        siteIds: [site.id],
        sensorTypes,
      });

      handleSetResourceRelation(site.id, spaces.map((e: Space) => e.id));

      this.setState({
        spaces,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  handleTargetSelectClick = (ev: any, item: any) => {
    const { handleTargetSelect } = this.props;
    ev.preventDefault();
    handleTargetSelect({
      targetId: item.id,
      targetType: TargetType.SPACE,
    });
  };

  render() {
    const { spaces = [], isLoading } = this.state;
    const {
      selected,
      site,
      isCollapsed,
      handleTargetDelete,
      handleTargetsSelect,
    } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    const selectedBySite = spaces
      ? spaces.reduce(
          (sum, item) => (selected.includes(item.id) ? sum + 1 : sum),
          0
        )
      : 0;

    const total = spaces.length;
    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Spaces</th>
              <th style={{ width: '50%' }}>{isCollapsed && 'Location'}</th>
              <th
                style={{ width: '20%' }}
                className={classNames([styles.actionHeader, styles.action])}
              >
                <RolloutSelector
                  isCollapsed={isCollapsed}
                  selected={selectedBySite}
                  totalSelected={total}
                  onDelete={() =>
                    handleTargetDelete(spaces.map(item => item.id))
                  }
                  onSelect={() =>
                    handleTargetsSelect(
                      spaces.map(item => ({
                        targetId: item.id,
                        targetType: TargetType.SPACE,
                      }))
                    )
                  }
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {spaces.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <div>This site has no spaces.</div>
                </td>
              </tr>
            ) : (
              isCollapsed &&
              spaces.map((item: Space) => (
                <tr
                  key={`renderSpaceRows${item.id}`}
                  onClick={ev => this.handleTargetSelectClick(ev, item)}
                  className={styles.filterRow}
                >
                  <td>
                    <SpaceChip id="generic" title={item.title} />
                  </td>
                  <td>
                    <Path
                      resourceId={item.id}
                      resourceType={ResourceType.SPACE}
                    />
                  </td>
                  <td className={styles.action}>
                    <Checkbox
                      checked={selected.includes(item.id)}
                      onChange={ev => this.handleTargetSelectClick(ev, item)}
                      id={`checkbox_${site.id}_${item.id}`}
                      size={16}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default StepRolloutSpaces;
