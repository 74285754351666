import { SensorType } from './sensors';

export enum SentinelType {
  NONE = '',
  TEMPERATURE = 'BasicTemperatureThreshold',
  HUMIDITY = 'BasicHumidityThreshold',
  DOOR = 'BasicDoorSwitch',
  BATTERY_LEVEL_CHECK = 'BatteryLevelCheck',
  CONNECTIVITY = 'OnlineStatusCheck',
  CAR_WASH = 'TemperatureAndDoorCheck',
  BINARY_COUNTER = 'DoorOpenCounter',
  CUSTOMER_COMFORT = 'CustomerComfortCheck',
}

export type SentinelConfiguration = {
  type: SentinelType;
  title: string;
  description: string;
  customSchedule: boolean;
  featureFlag: boolean;
  customDelay: boolean;
};

export type SentinelConfigurations = {
  [key: string]: SentinelConfiguration;
};

export const sentinelConfigurations: SentinelConfigurations = {
  [SentinelType.TEMPERATURE]: {
    type: SentinelType.TEMPERATURE,
    title: 'Temperature',
    description: 'Monitor the temperature of your equipment and spaces.',
    customSchedule: true,
    featureFlag: false,
    customDelay: true,
  },
  [SentinelType.DOOR]: {
    type: SentinelType.DOOR,
    title: 'Door',
    description: 'Monitor the doors of your equipment and spaces.',
    customSchedule: true,
    featureFlag: false,
    customDelay: true,
  },
  [SentinelType.BATTERY_LEVEL_CHECK]: {
    type: SentinelType.BATTERY_LEVEL_CHECK,
    title: 'BatteryLevelCheck',
    description: 'Battery status check for wireless sensors.',
    customSchedule: false,
    featureFlag: false,
    customDelay: true,
  },
  [SentinelType.CONNECTIVITY]: {
    type: SentinelType.CONNECTIVITY,
    title: 'Connectivity',
    description: 'Online status check for devices.',
    customSchedule: false,
    featureFlag: false,
    customDelay: true,
  },
  [SentinelType.CAR_WASH]: {
    type: SentinelType.CAR_WASH,
    title: 'Car Wash (Beta)',
    description: 'Monitor the doors and temperature of your spaces',
    customSchedule: true,
    featureFlag: false,
    customDelay: true,
  },
  [SentinelType.BINARY_COUNTER]: {
    type: SentinelType.BINARY_COUNTER,
    title: 'Door Counter (Beta)',
    description: 'Counts open and closes for a 24 hour period',
    customSchedule: false,
    featureFlag: false,
    customDelay: false,
  },
  [SentinelType.CUSTOMER_COMFORT]: {
    type: SentinelType.CUSTOMER_COMFORT,
    title: 'Customer Comfort (Beta)',
    description:
      'Monitor ambient temperature and humidity to optimize indoor temperature',
    customSchedule: true,
    featureFlag: false,
    customDelay: true,
  },
};

export const featureHiddenSentinels: SentinelType[] = Object.values(
  sentinelConfigurations
)
  .filter(s => s.featureFlag)
  .map(s => s.type);

export const customDelaySentinels: SentinelType[] = Object.values(
  sentinelConfigurations
)
  .filter(s => s.customDelay)
  .map(s => s.type);

export const sentinelTypesList: SentinelConfiguration[] = Object.values(
  sentinelConfigurations
);

// export const OPEN / CLOSED

export enum TargetType {
  SENSOR = 'SENSOR',
  EQUIPMENT = 'EQUIPMENT',
  SPACE = 'SPACE',
  DOOR = 'DOOR',
  GATEWAY = 'GATEWAY',
}

export enum ComparisonType {
  default = '',
  eq = 'eq',
  gt = 'gt',
  lt = 'lt',
  inner = 'inner',
  outer = 'outer',
}

export const comparisonTypesToSymbol = {
  default: '',
  [ComparisonType.eq]: '=',
  [ComparisonType.gt]: '>',
  [ComparisonType.lt]: '<',
  rg: '-',
  [ComparisonType.inner]: '-',
  [ComparisonType.outer]: '-',
};

export type SentinelTarget = {
  targetId: number;
  targetType: TargetType;
};

export type ParametersTemperature = {
  threshold?: number;
  comparisonType?: ComparisonType;
  rangeLowerBound?: number;
  rangeUpperBound?: number;
};

export type ParametersBinaryCounter = {
  maxOpeningCount?: number;
};

export type ParametersHumidity = {
  threshold?: number;
  comparisonType?: ComparisonType;
};

export type ParametersBatteryLevelCheck = {};

export type ParametersSwitch = {
  openClosed?: 'OPEN' | 'CLOSED';
};

export type ParametersCustomerComfort = {
  metabolicRate: number;
};

export type Parameters = {
  threshold?: number;
  comparisonType?: ComparisonType;
  openClosed?: 'OPEN' | 'CLOSED';
  rangeLowerBound?: number;
  rangeUpperBound?: number;
  maxOpeningCount?: number;
  metabolicRate?: number;
};

export const defaultTemperatureParams: ParametersTemperature = {
  comparisonType: ComparisonType.eq,
  threshold: 0,
};

export const defaultDoorParameters: ParametersSwitch = {
  openClosed: 'OPEN',
};

export const defaultCustomerComfortParameters: ParametersCustomerComfort = {
  metabolicRate: 1.1,
};

export const defaultBinaryCounterParameters: ParametersBinaryCounter = {
  maxOpeningCount: 1,
};

export const defaultBatteryCheckParameters: ParametersBatteryLevelCheck = {};

export enum SentinelRecipientsChannel {
  USER_EMAIL = 'USER_EMAIL',
  USER_GROUP_EMAIL = 'USER_GROUP_EMAIL',
}

export type SentinelApiRecipient = {
  channel: SentinelRecipientsChannel;
  identifier: string;
};

export type SentinelRecipient = {
  channel: SentinelRecipientsChannel;
  identifier: string;
  title: string;
};

export enum SentinelPriorityLevel {
  NORMAL = 'NORMAL',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  HIGHEST = 'HIGHEST',
}

export enum SentinelRecurType {
  NONE = 'NONE',
  UNTIL_RESOLVED = 'UNTIL_RESOLVED',
}

export type SentinelEscalation = {
  singleRunDelay?: SentinelInterval;
  singleRun: boolean;
  ix: number;
  recipients: SentinelApiRecipient[];
  priorityLevel: SentinelPriorityLevel;
  message?: string;
  name?: string;
  recurInterval?: SentinelInterval;
  recurType?: SentinelRecurType;
};

export enum SentinelScheduleWeekDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum SentinelScheduleType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export type ScheduleTimeRange = {
  begin: string;
  end: string;
};

export type SentinelSchedule = {
  scheduleType: SentinelScheduleType;
  timeRanges: ScheduleTimeRange[];
  weekDays: SentinelScheduleWeekDay[];
};

export enum IntervalUnit {
  DEVICEINTERVAL = 'DEVICEINTERVAL',
  MILLISECOND = 'MILLISECOND',
}

export type SentinelInterval = {
  unit: IntervalUnit;
  value: number;
};

export type Sentinel = {
  id: string;
  title: string;
  description: string;
  sentinelType: SentinelType;
  targets: SentinelTarget[];
  parameters: Parameters;
  escalations: SentinelEscalation[];
  schedules: SentinelSchedule[];
  reason?: string;
  mutedUntil?: string; // does not
  muteReason?: string;
  muted?: boolean;
  valid?: boolean;
  delay: SentinelInterval;
};

export type SentinelsApiFilter = {
  siteIds?: number[];
  equipmentIds?: number[];
  spaceIds?: number[];
  sensorIds?: number[];
};

export const sentinelTypeToSensorType = (type?: SentinelType): SensorType[] => {
  switch (type) {
    case SentinelType.DOOR:
    case SentinelType.BINARY_COUNTER:
      return [SensorType.BINARY];
    case SentinelType.TEMPERATURE:
      return [SensorType.TEMPERATURE];
    case SentinelType.HUMIDITY:
      return [SensorType.HUMIDITY];
    case SentinelType.CAR_WASH:
      return [SensorType.BINARY, SensorType.TEMPERATURE];
    case SentinelType.CONNECTIVITY:
    case SentinelType.BATTERY_LEVEL_CHECK:
      return [SensorType.BINARY, SensorType.TEMPERATURE, SensorType.HUMIDITY];
    case SentinelType.CUSTOMER_COMFORT:
      return [SensorType.TEMPERATURE, SensorType.HUMIDITY];
    default:
      return [];
  }
};

export const sentinelTypeToTargetType = (type?: SentinelType): TargetType[] => {
  switch (type) {
    case SentinelType.DOOR:
    case SentinelType.BINARY_COUNTER:
    case SentinelType.TEMPERATURE:
    case SentinelType.HUMIDITY:
      return [TargetType.EQUIPMENT, TargetType.SPACE];
    case SentinelType.CONNECTIVITY:
      return [TargetType.SENSOR, TargetType.GATEWAY];
    case SentinelType.BATTERY_LEVEL_CHECK:
      return [TargetType.SENSOR];
    case SentinelType.CAR_WASH:
      return [TargetType.SPACE, TargetType.EQUIPMENT, TargetType.SENSOR];
    case SentinelType.CUSTOMER_COMFORT:
      return [TargetType.SENSOR];
    default:
      return [];
  }
};

export const initialSentinelEscalation: SentinelEscalation = {
  ix: 0,
  recipients: [],
  name: '',
  message: '',
  priorityLevel: SentinelPriorityLevel.NORMAL,
  recurType: SentinelRecurType.NONE,
  // The first sentinel escalation should always be single run true according to Marcus
  singleRun: true,
  singleRunDelay: {
    unit: IntervalUnit.MILLISECOND,
    value: 0,
  },
};

export const initialSentinelEscalationWithoutDelay: SentinelEscalation = {
  ix: 0,
  recipients: [],
  name: '',
  message: '',
  priorityLevel: SentinelPriorityLevel.NORMAL,
  recurType: SentinelRecurType.NONE,
  singleRun: false,
  singleRunDelay: {
    unit: IntervalUnit.MILLISECOND,
    value: 0,
  },
};

export const initialSentinelTimeRange = {
  begin: '06:00',
  end: '18:00',
};

export const initialSentinelSchedule: SentinelSchedule = {
  scheduleType: SentinelScheduleType.DAILY,
  weekDays: [],
  timeRanges: [initialSentinelTimeRange],
};
