import * as React from 'react';
import dateFnsFormat from 'date-fns/format';
import dateIsAfter from 'date-fns/isAfter';
import DayPicker from 'react-day-picker';
import styles from './BasePicker.module.css';
import { FnSetFilterTimePeriod } from '../../types/notification';
import Button from '../ui/Button';

import ChevronRightTiny from '../icons/ui/ChevronRightTiny';
import ChevronLeftTiny from '../icons/ui/ChevronLeftTiny';

type Props = {
  numberOfMonths: number;
  defaultSelectedDays?: {
    from: Date;
    to: Date;
  };
  setFilter: FnSetFilterTimePeriod;
  togglePicker: any;
};

type State = {
  from?: Date;
  to?: Date;
};

function CalendarNav({ onPreviousClick, onNextClick, className }: any) {
  return (
    <div className={className}>
      <div className={styles.navButtonPrev}>
        <Button icon onClick={() => onPreviousClick()}>
          <span className={styles.navButtonColor}>
            <ChevronLeftTiny size={20} />
          </span>
        </Button>
      </div>
      <div className={styles.navButtonNext}>
        <Button icon onClick={() => onNextClick()}>
          <span className={styles.navButtonColor}>
            <ChevronRightTiny size={20} />
          </span>
        </Button>
      </div>
    </div>
  );
}

class BasePicker extends React.Component<Props, State> {
  static defaultProps = {
    numberOfMonths: 2,
  };

  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
    };
  }

  componentDidMount() {
    const { defaultSelectedDays } = this.props;
    if (defaultSelectedDays) {
      this.updateState(defaultSelectedDays);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { defaultSelectedDays } = this.props;

    if (
      defaultSelectedDays &&
      prevProps.defaultSelectedDays &&
      defaultSelectedDays.from !== prevProps.defaultSelectedDays.from &&
      defaultSelectedDays.to !== prevProps.defaultSelectedDays.to
    ) {
      this.updateState(defaultSelectedDays);
    }
  }

  updateState = (state: { from: Date; to: Date }) => this.setState(state);

  // handleResetClick = () => this.setState(this.getInitialState());

  handleDayClick = (day: Date) => {
    const { from, to } = this.state;

    // start a new date range or check if new to is after from date
    if ((from && to) || (from && dateIsAfter(from, day))) {
      this.setState({ from: day, to: undefined });
      return;
    }

    if (from && to === undefined) {
      this.setState({ from, to: day });
    }
  };

  handleUpdateFilter = () => {
    const { from, to } = this.state;

    if (from === undefined || to === undefined) {
      return;
    }

    const fromDate = dateFnsFormat(from, 'yyyy-MM-dd');
    const toDate = dateFnsFormat(to, 'yyyy-MM-dd');

    this.props.setFilter({
      fromDate,
      toDate,
      isCustomRange: true,
    });

    this.props.togglePicker();
  };

  render() {
    const { from, to } = this.state;
    const { numberOfMonths } = this.props;
    const formIsValid = typeof from === 'object' && typeof to === 'object';

    return (
      <React.Fragment>
        <DayPicker
          month={this.state.from}
          // @ts-ignore
          selectedDays={[this.state]}
          // @ts-ignore
          classNames={styles}
          numberOfMonths={numberOfMonths}
          modifiers={{
            [styles.selectedStart]: this.state.from,
            [styles.selectedEnd]: this.state.to,
          }}
          onDayClick={this.handleDayClick}
          navbarElement={<CalendarNav />}
        />

        <div className={styles.navFooter}>
          <Button size="small" secondary onClick={this.props.togglePicker}>
            Cancel
          </Button>
          <Button
            disabled={formIsValid === false}
            size="small"
            onClick={this.handleUpdateFilter}
          >
            Apply
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default BasePicker;
