import { fetchApi } from '../middleware/apiMiddleware';
import { Service } from '../config';
import {
  ApiOperationalSamplesResponse,
  OperationalSample,
} from '../types/samples';

function transformSamples(samples: ApiOperationalSamplesResponse): any {
  return samples.data.map(s => ({
    type: s.type.toUpperCase(),
    ...s.attributes,
  }));
}

export const fetchOperationalSamples = async (
  queryParams: string
): Promise<OperationalSample[]> =>
  fetchApi({
    endpoint: `/v3/operational_sensors?${queryParams}`,
    service: Service.tsd,
  }).then(transformSamples);
