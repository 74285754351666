import * as React from 'react';
import { SensorIcon } from '../../../types/icon';

const IconSize16 = () => (
  <g>
    <path
      d="M6.1 6.5c-.5 0-1-.2-1.4-.6-.4-.4-1-.4-1.3 0-.7.7-2 .7-2.7 0-.2-.2-.2-.5 0-.7.1-.2.5-.2.7 0 .4.4 1 .4 1.3 0 .8-.8 2-.8 2.7 0 .4.4 1 .4 1.3 0 .3-.2.6-.2.8 0s.2.5 0 .7c-.4.4-.9.6-1.4.6zM2 9.3c-.5 0-1-.2-1.4-.6-.2-.2-.2-.5 0-.7s.5-.2.7 0c.4.4 1 .4 1.3 0 .7-.7 2-.7 2.7 0 .3.3.7.4 1 .2.3-.1.5 0 .7.3.1.3 0 .5-.3.7-.6.3-1.4.1-2-.4-.4-.4-1-.4-1.3 0-.4.4-.9.5-1.4.5zM6 12.3c-.5 0-1-.2-1.4-.6-.4-.4-1-.4-1.3 0-.7.7-2 .7-2.7 0-.2-.2-.2-.5 0-.7s.5-.2.7 0c.4.4 1 .4 1.3 0 .7-.7 2-.7 2.7 0 .2.2.4.3.7.3.3 0 .5.2.5.5s-.2.5-.5.5zM11.5 3.4C13 7.6 14 10.7 14 11.6c0 1.3-1.1 2.4-2.5 2.4S9 12.9 9 11.6c0-.9 1-4 2.5-8.2m0-2.4c-.1 0-.2.1-.2.2C10.5 3.3 8 10 8 11.6c0 1.9 1.6 3.4 3.5 3.4s3.5-1.5 3.5-3.4c0-1.6-2.5-8.3-3.3-10.4 0-.1-.1-.2-.2-.2z"
      fill="currentColor"
    />
  </g>
);

const IconSize32 = () => (
  <g>
    <path
      d="M28.3 22h-.2c-.3.1-.4.4-.3.7.1.4.2.8.2 1.3 0 .4-.1.9-.2 1.3-.1.3 0 .6.3.7.3.1.5 0 .6-.3.2-.5.3-1.1.3-1.6 0-.6-.1-1.1-.3-1.6 0-.4-.2-.5-.4-.5zM31.5 21.3c-.1-.2-.3-.3-.5-.3h-.2c-.3.1-.4.4-.3.7.3.7.4 1.5.4 2.3s-.1 1.6-.4 2.3c-.1.3 0 .6.3.7.3.1.5 0 .6-.3.3-.9.5-1.8.5-2.7s-.1-1.8-.4-2.7zM5.7 12.3c1-1 2.6-1 3.6 0 .7.7 1.6 1.1 2.5 1.1 1 0 1.9-.4 2.5-1.1.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0-1 1-2.6 1-3.6 0-1.3-1.5-3.7-1.5-5.1 0-1 1-2.6 1-3.6 0-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7 1.4 1.5 3.8 1.5 5.1 0zM3.2 17.5c1 0 1.9-.4 2.5-1.1 1-1 2.6-1 3.6 0 1 1.1 2.6 1.4 4 .8.2-.1.4-.4.2-.7-.1-.2-.4-.4-.7-.2-1 .5-2.1.2-2.8-.6-1.3-1.5-3.7-1.5-5.1 0-1 1-2.6 1-3.6 0-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7.7.7 1.6 1.1 2.6 1.1zM11.8 20.5c-.7 0-1.3-.3-1.8-.8-1.3-1.5-3.7-1.5-5.1 0-1 1-2.6 1-3.6 0-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7 1.3 1.5 3.7 1.5 5.1 0 1-1 2.6-1 3.6 0 .7.7 1.6 1.1 2.5 1.1.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM19.5 4c-.2 0-.4.1-.5.3-1.5 3.9-6 16.3-6 19.3 0 3.5 2.9 6.4 6.5 6.4s6.5-2.9 6.5-6.4c0-3-4.6-15.3-6-19.3-.1-.2-.3-.3-.5-.3zm0 25c-3 0-5.5-2.4-5.5-5.4 0-2.1 2.8-10.3 5.5-17.7 2.7 7.4 5.5 15.5 5.5 17.7 0 3-2.5 5.4-5.5 5.4z"
      fill="currentColor"
    />
    <path
      d="M22 26.5c.1 0 .3 0 .4-.1.7-.7 1.1-1.7 1.1-2.8 0-.4-.1-.9-.3-1.7-.1-.3-.3-.4-.6-.4-.3.1-.4.3-.4.6.2.8.3 1.2.3 1.4 0 .8-.3 1.5-.9 2.1-.2.2-.2.5 0 .7.1.1.3.2.4.2z"
      fill="currentColor"
    />
  </g>
);

const IconSize40 = () => (
  <g>
    <g opacity=".1">
      <path
        d="M13.2 18.3c1-1 2.6-1 3.6 0 .7.7 1.6 1.1 2.5 1.1 1 0 1.9-.4 2.5-1.1.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0-1 1-2.6 1-3.6 0-1.3-1.5-3.7-1.5-5.1 0-1 1-2.6 1-3.6 0-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7 1.4 1.5 3.8 1.5 5.1 0zM10.7 23.5c1 0 1.9-.4 2.5-1.1 1-1 2.6-1 3.6 0 1 1.1 2.6 1.5 4 .8.2-.1.4-.4.2-.7-.1-.2-.4-.4-.7-.2-1 .5-2.1.2-2.8-.6-1.3-1.5-3.7-1.5-5.1 0-1 1-2.6 1-3.6 0-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7.7.7 1.6 1.1 2.6 1.1zM19.3 26.5c-.7 0-1.3-.3-1.8-.8-1.3-1.5-3.7-1.5-5.1 0-1 1-2.6 1-3.6 0-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7.7.7 1.6 1.1 2.5 1.1 1 0 1.9-.4 2.5-1.1 1-1 2.6-1 3.6 0 .7.7 1.6 1.1 2.5 1.1.3 0 .5-.2.5-.5s-.1-.5-.4-.5zM26.5 10.3c-1.5 3.9-6 16.3-6 19.3 0 3.5 2.9 6.4 6.5 6.4s6.5-2.9 6.5-6.4c0-3-4.6-15.3-6-19.3-.2-.4-.8-.4-1 0zM36.3 29h-.2c-.3.1-.4.4-.3.7.1.4.2.8.2 1.3 0 .4-.1.9-.2 1.3-.1.3 0 .6.3.7.3.1.5 0 .6-.3.2-.5.3-1.1.3-1.6 0-.6-.1-1.1-.3-1.6 0-.4-.2-.5-.4-.5zM39.5 28.3c-.1-.2-.3-.3-.5-.3h-.2c-.3.1-.4.4-.3.7.3.7.4 1.5.4 2.3s-.1 1.6-.4 2.3c-.1.3 0 .6.3.7.3.1.5 0 .6-.3.3-.9.5-1.8.5-2.7s-.1-1.8-.4-2.7z"
        fill="currentColor"
      />
    </g>
    <path
      d="M31.4 24c.2 0 .4.1.5.4.2.5.3 1.1.3 1.6s-.1 1.1-.3 1.6c-.1.3-.4.4-.6.3-.3-.1-.4-.4-.3-.7.1-.4.2-.8.2-1.3 0-.4-.1-.9-.2-1.3-.1-.3 0-.6.3-.7 0 .1.1.1.1.1zM34.3 23c.2 0 .4.1.5.3.3.9.5 1.8.5 2.7s-.2 1.8-.5 2.7c-.1.3-.4.4-.6.3-.3-.1-.4-.4-.3-.7.3-.7.4-1.5.4-2.3 0-.8-.1-1.6-.4-2.3-.1-.3 0-.6.3-.7h.1zM8.9 14.3c1-1 2.6-1 3.6 0 .7.7 1.6 1.1 2.5 1.1s1.9-.4 2.5-1.1c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0-1 1-2.6 1-3.6 0-1.3-1.5-3.7-1.5-5.1 0-1 1-2.6 1-3.6 0-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7 1.4 1.5 3.7 1.5 5.1 0zM6.3 19.5c1 0 1.9-.4 2.5-1.1 1-1 2.6-1 3.6 0 1 1.1 2.6 1.5 4 .8.2-.1.4-.4.2-.7-.1-.2-.4-.4-.7-.2-1 .5-2.1.2-2.8-.6-1.3-1.5-3.7-1.5-5.1 0-1 1-2.6 1-3.6 0-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7.8.7 1.7 1.1 2.6 1.1zM15 22.5c-.7 0-1.3-.3-1.8-.8-1.3-1.5-3.7-1.5-5.1 0-1 1-2.6 1-3.6 0-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7.7.7 1.6 1.1 2.5 1.1 1 0 1.9-.4 2.5-1.1 1-1 2.6-1 3.6 0 .7.7 1.6 1.1 2.5 1.1.3 0 .5-.2.5-.5s-.1-.5-.4-.5zM23.1 6.3c0-.2-.2-.3-.4-.3s-.4.1-.5.3c-1.5 3.9-6 16.3-6 19.3 0 3.5 2.9 6.4 6.5 6.4s6.5-2.9 6.5-6.4c0-3-4.6-15.3-6.1-19.3zM22.7 31c-3 0-5.5-2.4-5.5-5.4 0-2.1 2.8-10.3 5.5-17.7 2.7 7.4 5.5 15.5 5.5 17.7 0 3-2.5 5.4-5.5 5.4z"
      fill="currentColor"
    />
    <path
      d="M26.4 23.9c-.1-.3-.3-.4-.6-.4-.3.1-.4.3-.4.6.2.8.3 1.2.3 1.4 0 .8-.3 1.5-.9 2.1-.2.2-.2.5 0 .7.1.1.2.1.4.1s.3 0 .4-.1c.7-.7 1.1-1.7 1.1-2.8 0-.3-.1-.8-.3-1.6z"
      fill="currentColor"
    />
  </g>
);

const IconSize80 = () => (
  <g>
    <g opacity=".1">
      <path
        d="M15 39.9c2.1 0 4.2-.8 5.7-2.4 1.1-1.1 2.7-1.8 4.3-1.8s3.1.6 4.3 1.8c1.5 1.5 3.5 2.4 5.7 2.4 2.1 0 4.2-.8 5.7-2.4.4-.4.4-1 0-1.4s-1-.4-1.4 0c-1.1 1.1-2.7 1.8-4.3 1.8-1.6 0-3.1-.6-4.3-1.8-1.5-1.5-3.5-2.4-5.7-2.4s-4.2.8-5.7 2.4c-1.1 1.1-2.7 1.8-4.3 1.8-1.6 0-3.1-.6-4.3-1.8-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c1.5 1.6 3.5 2.4 5.7 2.4zM15 48.5c2.1 0 4.2-.8 5.7-2.4 1.1-1.1 2.7-1.8 4.3-1.8s3.1.6 4.3 1.8c2.3 2.3 5.9 3 9 1.7.5-.2.7-.8.5-1.3-.2-.5-.8-.7-1.3-.5-2.3 1-5 .5-6.7-1.2-1.5-1.5-3.5-2.4-5.7-2.4s-4.2.8-5.7 2.4c-1.1 1.1-2.7 1.8-4.3 1.8-1.6 0-3.1-.6-4.3-1.8-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c1.4 1.4 3.4 2.3 5.6 2.3zM34.9 54.9c-1.6 0-3.1-.6-4.3-1.8-1.5-1.5-3.5-2.4-5.7-2.4s-4.2.8-5.7 2.4c-1.1 1.1-2.7 1.8-4.3 1.8-1.6 0-3.1-.6-4.3-1.8-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c3.1 3.1 8.2 3.1 11.4 0 1.1-1.1 2.7-1.8 4.3-1.8s3.1.6 4.3 1.8c1.5 1.5 3.5 2.4 5.7 2.4.6 0 1-.4 1-1 0-.5-.5-1-1-1zM52.4 15.6c-.4 0-.9.2-1 .7-3.3 9-13.6 37.3-13.6 44 0 8.1 6.5 14.6 14.6 14.6C60.5 75 67 68.5 67 60.4c0-6.8-10.3-35-13.6-44-.2-.5-.6-.8-1-.8zM69.4 51.3c-.2.1-.3.4-.2.7 1.5 2.6 2.3 5.6 2.3 8.6s-.8 6-2.3 8.6c-.1.2-.1.5.2.7.1 0 .2.1.2.1.2 0 .3-.1.4-.2 1.6-2.8 2.4-5.9 2.4-9.1s-.8-6.4-2.4-9.1c-.1-.3-.4-.4-.6-.3zM74.3 48.8c-.1-.2-.4-.3-.7-.2-.2.1-.3.4-.2.7 2 3.5 3.1 7.4 3.1 11.4s-1.1 7.9-3.1 11.4c-.1.2-.1.5.2.7.1 0 .2.1.2.1.2 0 .3-.1.4-.2 2.1-3.6 3.2-7.7 3.2-11.9.1-4.3-1-8.4-3.1-12z"
        fill="currentColor"
      />
    </g>
    <path d="M5.4 14.1L7.5 12l2.1 2.1" stroke="currentColor" fill="none" />
    <path
      d="M7.5 20c-.3 0-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5s.5.2.5.5v7c0 .3-.2.5-.5.5zM10.5 25c-.3 0-.5-.2-.5-.5v-4c0-.3.2-.5.5-.5s.5.2.5.5v4c0 .3-.2.5-.5.5zM21.5 53c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5z"
      fill="currentColor"
    />
    <path d="M23.6 58.9L21.5 61l-2.1-2.1" stroke="currentColor" fill="none" />
    <path
      d="M18.5 48c.3 0 .5.2.5.5v4c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-4c0-.3.2-.5.5-.5z"
      fill="currentColor"
    />
    <g>
      <path
        d="M46.4 6.6c-.4 0-.9.2-1 .7-3.3 9-13.6 37.3-13.6 44 0 8.1 6.5 14.6 14.6 14.6S61 59.5 61 51.4c0-6.8-10.3-35-13.6-44-.2-.5-.6-.8-1-.8zm0 57.4c-7 0-12.6-5.7-12.6-12.6 0-4.9 6.4-24 12.6-41 6.3 17 12.6 36.1 12.6 41C59 58.3 53.3 64 46.4 64z"
        fill="currentColor"
      />
      <path
        d="M52.2 57.5c.1 0 .3 0 .4-.1 1.9-1.9 2.9-4.4 2.9-7.1 0-.9-.3-2.4-.8-4.4-.1-.3-.3-.4-.6-.4-.3.1-.4.3-.4.6.5 2 .8 3.4.8 4.2 0 2.4-.9 4.6-2.6 6.3-.2.2-.2.5 0 .7.1.2.2.2.3.2zM9 30.9c2.1 0 4.2-.8 5.7-2.4 1.1-1.1 2.7-1.8 4.3-1.8s3.1.6 4.3 1.8c1.5 1.5 3.5 2.4 5.7 2.4 2.1 0 4.2-.8 5.7-2.4.4-.4.4-1 0-1.4s-1-.4-1.4 0c-1.1 1.1-2.7 1.8-4.3 1.8-1.6 0-3.1-.6-4.3-1.8-1.5-1.5-3.5-2.4-5.7-2.4s-4.2.8-5.7 2.4c-1.1 1.1-2.7 1.8-4.3 1.8-1.6 0-3.1-.6-4.3-1.8-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c1.5 1.6 3.5 2.4 5.7 2.4zM9 39.5c2.1 0 4.2-.8 5.7-2.4 1.1-1.1 2.7-1.8 4.3-1.8s3.1.6 4.3 1.8c2.3 2.3 5.9 3 9 1.7.5-.2.7-.8.5-1.3-.2-.5-.8-.7-1.3-.5-2.3 1-5 .5-6.7-1.2-1.5-1.5-3.5-2.4-5.7-2.4s-4.2.8-5.7 2.4c-1.1 1.1-2.7 1.8-4.3 1.8-1.6 0-3.1-.6-4.3-1.8-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c1.4 1.4 3.4 2.3 5.6 2.3zM28.9 45.9c-1.6 0-3.1-.6-4.3-1.8-1.5-1.5-3.5-2.4-5.7-2.4s-4.2.8-5.7 2.4c-1.1 1.1-2.7 1.8-4.3 1.8-1.6 0-3.1-.6-4.3-1.8-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c3.1 3.1 8.2 3.1 11.4 0 1.1-1.1 2.7-1.8 4.3-1.8s3.1.6 4.3 1.8c1.5 1.5 3.5 2.4 5.7 2.4.6 0 1-.4 1-1 0-.5-.5-1-1-1zM65.1 45.5c-.2.1-.3.4-.2.7 1 1.8 1.6 3.8 1.6 5.9s-.5 4.1-1.6 5.9c-.1.2-.1.5.2.7.1 0 .2.1.2.1.2 0 .3-.1.4-.2 1.1-1.9 1.7-4.1 1.7-6.4s-.6-4.4-1.7-6.4c0-.4-.3-.5-.6-.3zM69.4 42.7c-.2.1-.3.4-.2.7 1.5 2.6 2.3 5.6 2.3 8.6s-.8 6-2.3 8.6c-.1.2-.1.5.2.7.1 0 .2.1.2.1.2 0 .3-.1.4-.2 1.6-2.8 2.4-5.9 2.4-9.1s-.8-6.4-2.4-9.1c-.1-.4-.4-.5-.6-.3zM74.3 40.1c-.1-.2-.4-.3-.7-.2-.2.1-.3.4-.2.7 2 3.5 3.1 7.4 3.1 11.4s-1.1 7.9-3.1 11.4c-.1.2-.1.5.2.7.1 0 .2.1.2.1.2 0 .3-.1.4-.2 2.1-3.6 3.2-7.7 3.2-11.9s-1-8.4-3.1-12z"
        fill="currentColor"
      />
    </g>
  </g>
);

const getIconBySize = (size: number) => {
  switch (true) {
    case size < 32:
      return { viewBox: '0 0 16 16', icon: <IconSize16 /> };

    case size < 40:
      return { viewBox: '0 0 32 32', icon: <IconSize32 /> };

    case size === 40:
      return { viewBox: '0 0 40 40', icon: <IconSize40 /> };

    default:
      return { viewBox: '0 0 80 80', icon: <IconSize80 /> };
  }
};

export default ({ classes = {}, size = 40 }: SensorIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
