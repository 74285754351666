import * as React from 'react';
import { SentinelType } from '../../types/sentinel';
import { SensorIcon } from '../../types/icon';
import { SensorType } from '../../types/sensors';
import IconGatewayLowBattery from './gateways/IconGatewayLowBattery';
import IconGatewayOffline from './gateways/IconGatewayOffline';
import IconTemperatureSensor from './sensors/IconTemperatureSensor';
import IconAmbientSensor from './sensors/IconAmbientSensor';
import IconHumiditySensor from './sensors/IconHumiditySensor';
import IconDoorSensor from './sensors/IconDoorSensor';
import GasStationIcon from './sites/GasStation';

export default ({ classes, size, id, parameters }: SensorIcon) => {
  switch (id) {
    case SentinelType.TEMPERATURE:
    case SensorType.TEMPERATURE:
      return <IconTemperatureSensor classes={classes} size={size} />;

    case SensorType.HUMIDITY:
    case SentinelType.HUMIDITY:
      return (
        <IconHumiditySensor
          classes={classes}
          size={size}
          parameters={parameters}
        />
      );

    case SensorType.BINARY:
    case SentinelType.BINARY_COUNTER:
    case SentinelType.DOOR:
      return (
        <IconDoorSensor classes={classes} size={size} parameters={parameters} />
      );

    case SentinelType.CONNECTIVITY:
      return (
        <IconGatewayOffline
          classes={classes}
          size={size}
          parameters={parameters}
        />
      );

    case SentinelType.BATTERY_LEVEL_CHECK:
      return (
        <IconGatewayLowBattery
          classes={classes}
          size={size}
          parameters={parameters}
        />
      );

    case SentinelType.CAR_WASH:
      return <GasStationIcon classes={classes} size={size} />;

    case SentinelType.CUSTOMER_COMFORT:
      return <IconAmbientSensor classes={classes} size={size} />;

    default:
      return null;
  }
};
