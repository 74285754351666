import React from 'react';
import PropTypes from 'prop-types';
import MenuTopbar from './MenuTopbar';
import classNames from '../utils/classNames';
import styles from './Header.module.css';

const Header = ({ isAuthenticated, classes, ...rest }: any) => {
  // For now we dont show the headerbar on the Equipment list page and Site list page,
  // because it doesnt add anything useful for now. This will be adjusted later
  if (
    rest.pageInfo.pageTitle !== 'Equipment' &&
    rest.pageInfo.pageTitle !== 'Sites'
  ) {
    return (
      <header className={classNames(styles.header)}>
        {isAuthenticated && <MenuTopbar {...rest} />}
      </header>
    );
  }

  return null;
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Header;
