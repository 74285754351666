import { Actions } from '../actions/pageInfo';

const initialState = {
  pageTitle: '',
  pageSubtitle: '',
  pageIcon: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_PAGE_TITLE: {
      return {
        ...state,
        pageTitle: action.payload,
      };
    }

    case Actions.SET_PAGE_SUBTITLE: {
      return {
        ...state,
        pageSubtitle: action.payload,
      };
    }

    case Actions.SET_PAGE_ICON: {
      return {
        ...state,
        pageIcon: action.payload,
      };
    }

    case Actions.SET_PAGE_TYPE: {
      return {
        ...state,
        pageType: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
