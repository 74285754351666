import { fetchApi } from '../middleware/apiMiddleware';
import { User } from '../types/user';

export enum Actions {
  USERS_SUCCESS = '@@user/USERS_SUCCESS',
  USERS_ERROR = '@@user/USERS_ERROR',
  USERS_LOADING = '@@user/USERS_LOADING',
  USER_INFO_SUCCESS = '@@user/USER_INFO_SUCCESS',
  USER_INFO_ERROR = '@@user/USER_INFO_ERROR',
  USER_INFO_LOADING = '@@user/USER_INFO_LOADING',
}

export const getUsers = () => ({
  type: 'API_GET',
  path: '/api/v1/users/',
  success: Actions.USERS_SUCCESS,
  error: Actions.USERS_ERROR,
  loading: Actions.USERS_LOADING,
});

export const getUserInfo = () => ({
  type: 'API_GET',
  path: '/api/v1/auth/user/?depth=0',
  success: Actions.USER_INFO_SUCCESS,
  error: Actions.USER_INFO_ERROR,
  loading: Actions.USER_INFO_LOADING,
});

export const fetchUsers = (): Promise<User[]> =>
  fetchApi({
    endpoint: '/api/v1/users/',
  });
