import * as React from 'react';
import styles from './FormTimeSelect.module.css';
import InputField from '../ui/InputField';
import FormText from '../ui/FormText';

type OnChangeHandler = (time: number) => void;

type Props = {
  value?: number;
  onChange: OnChangeHandler;
  disabled?: boolean;
};

type Time = {
  hours?: number;
  minutes?: number;
};

function splitTime(value?: number): Time {
  if (!value) {
    return { hours: undefined, minutes: undefined };
  }

  const minutes = value % 3600000;
  const hours = (value - minutes) / 3600000;

  return {
    hours: hours === 0 ? undefined : hours,
    minutes: minutes === 0 ? undefined : minutes / 60000,
  };
}

function composeTime(hours?: number, minutes?: number): number {
  // returns amount of time in milliseconds
  let time = 0;

  if (hours) {
    time += hours * 3600000;
  }

  if (minutes) {
    time += minutes * 60000;
  }

  return time;
}

function onHourChange(
  value: string,
  onChange: OnChangeHandler,
  timeValue?: number
) {
  const isNumber = /^[0-9]+$/.test(value);
  const number = isNumber ? parseInt(value, 10) : undefined;

  if (
    number !== undefined &&
    (number < 0 || number > 72 || number.toString().length > 2)
  ) {
    return;
  }

  const { minutes } = splitTime(timeValue);
  onChange(composeTime(number, minutes));
}

function onMinuteChange(
  value: string,
  onChange: OnChangeHandler,
  timeValue?: number
) {
  const isNumber = /^[0-9]+$/.test(value);
  const number = isNumber ? parseInt(value, 10) : undefined;

  if (
    number !== undefined &&
    (number < 0 || number > 60 || number.toString().length > 2)
  ) {
    return;
  }

  const { hours } = splitTime(timeValue);
  onChange(composeTime(hours, number));
}

function FormTimeSelect(props: Props) {
  const { disabled, value, onChange } = props;
  const { hours = '', minutes = '' } = splitTime(value);

  return (
    <div className={styles.root}>
      <div className={styles.item}>
        <InputField
          value={hours.toString()}
          placeholder="hh"
          onChange={ev => onHourChange(ev.target.value, onChange, value)}
          name="hours"
          disabled={disabled}
        />
      </div>
      <div className={styles.separator}>
        <FormText>:</FormText>
      </div>
      <div className={styles.item}>
        <InputField
          value={minutes.toString()}
          placeholder="mm"
          onChange={ev => onMinuteChange(ev.target.value, onChange, value)}
          name="minutes"
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default FormTimeSelect;
