import * as React from 'react';
import { UiIcon } from '../../../types/icon';

const IconSize16 = () => (
  <g>
    <path
      d="M12.12 14.38a.62.62 0 0 1-.56.62H4.44a.62.62 0 0 1-.55-.61L3 4H2l.88 10.46A1.61 1.61 0 0 0 4.44 16h7.13a1.61 1.61 0 0 0 1.55-1.54L14 4h-1z"
      fill="currentColor"
    />
    <path
      d="M9.5 14a.5.5 0 0 0 .5-.46l.68-8a.5.5 0 0 0-1-.08l-.68 8a.5.5 0 0 0 .46.54zM6.5 14a.5.5 0 0 0 .5-.54l-.68-8a.5.5 0 0 0-1 .08l.68 8a.5.5 0 0 0 .5.46z"
      fill="currentColor"
    />
    <path
      d="M15.85 4.14L14.71 3H10.5V2a2 2 0 0 0-2-2h-1a2 2 0 0 0-2 2v1H1.29L.15 4.14a.5.5 0 0 0 .71.71L1.71 4h12.58l.85.85a.5.5 0 1 0 .71-.71zM6.5 2a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1h-3z"
      fill="currentColor"
    />
  </g>
);

const IconSize32 = () => (
  <g>
    <path
      d="M22.89 32H9a3.87 3.87 0 0 1-3.73-3.67L3.47 6.54a.5.5 0 0 1 1-.08L6.3 28.25A2.87 2.87 0 0 0 9 31h13.89a2.87 2.87 0 0 0 2.74-2.74l1.84-21.8a.5.5 0 0 1 1 .08l-1.84 21.8A3.87 3.87 0 0 1 22.89 32z"
      fill="currentColor"
    />
    <path
      d="M31 6a.5.5 0 0 1-.35-.15L28.76 4H3.17L1.32 5.85a.5.5 0 0 1-.71-.71L2.76 3h26.41l2.15 2.15A.5.5 0 0 1 31 6zM19 29a.5.5 0 0 1-.46-.54l2-20a.5.5 0 0 1 1 .08l-2 20A.5.5 0 0 1 19 29zM13 29a.5.5 0 0 1-.5-.46l-2-20a.5.5 0 1 1 1-.08l2 20a.5.5 0 0 1-.5.54z"
      fill="currentColor"
    />
    <path
      d="M16.47 1a2 2 0 0 1 2 2h-5a2 2 0 0 1 2-2h1m0-1h-1a3 3 0 0 0-3 3v1h7V3a3 3 0 0 0-3-3z"
      fill="currentColor"
    />
  </g>
);

const getIconBySize = (size: number) => {
  switch (true) {
    case size <= 16:
      return { viewBox: '0 0 16 16', icon: <IconSize16 /> };

    default:
      return { viewBox: '0 0 32 32', icon: <IconSize32 /> };
  }
};

export default ({ size = 16, ...props }: UiIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      width={size}
      height={size}
      {...props}
    >
      {icon}
    </svg>
  );
};
