import * as React from 'react';
import { BatteryIcon } from '../../../types/icon';

const Battery1 = ({ size, classes = {} }: BatteryIcon) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    className={classes.icon}
    viewBox="0 0 16 16"
  >
    <path
      d="M15 6V5a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-1h1V6zm-1 5H1V5h13z"
      fill="currentColor"
    />
    <path d="M2 6h2v4H2z" fill="currentColor" />
  </svg>
);

export default Battery1;
