import * as React from 'react';
import { SensorIcon } from '../../../types/icon';

const IconSize16 = () => (
  <g>
    <path
      d="M9.3 4.8l-3.8 6.6c-.3 0-.7.2-.9.5-.2.5-.1 1.1.4 1.4.2.1.3.1.5.1.3 0 .7-.2.9-.5.2-.3.2-.7 0-1l3.8-6.6-.9-.5z"
      fill="currentColor"
    />
    <path
      d="M11.3 1c.3 0 .5.1.7.2.3.2.6.5.7.9s.1.8-.1 1.1L8 11.1l-.2.4.1.4c.2.6.1 1.3-.3 1.9-.4.7-1.2 1.2-2.1 1.2-.4 0-.9-.1-1.2-.3-.6-.3-1-.9-1.2-1.5-.2-.6-.1-1.3.2-1.9.3-.6.9-1 1.5-1.2l.4-.1.2-.4L10 1.8c.2-.5.7-.8 1.3-.8m0-1c-.9 0-1.7.4-2.2 1.3L4.6 9.1c-.8.2-1.6.8-2.1 1.6-1 1.7-.4 3.8 1.3 4.8.5.3 1.1.5 1.7.5 1.2 0 2.4-.6 3-1.8.5-.8.6-1.8.4-2.6l4.5-7.8c.7-1.2.3-2.7-.9-3.4-.4-.3-.8-.4-1.2-.4zM5.4 2.6l-.5.9.9.5.5-.9-.9-.5zM6.4.9l-.5.8.9.5.5-.8-.9-.5zM4.4 4.3l-.5.9.9.5.5-.9-.9-.5zM2.6 5.6l-.5.8 1.7 1 .5-.8-1.7-1z"
      fill="currentColor"
    />
    <g>
      <path
        d="M11 14.6h-.2c-.3-.1-.4-.4-.3-.6.1-.2.1-.5.1-.8 0-.3 0-.5-.1-.8-.1-.3 0-.5.3-.6.3-.1.5 0 .6.3.1.4.2.7.2 1.1 0 .4-.1.8-.2 1.1 0 .2-.2.3-.4.3z"
        fill="currentColor"
      />
    </g>
    <g>
      <path
        d="M13.1 15.3h-.2c-.3-.1-.4-.4-.3-.6.2-.5.3-1 .3-1.5s-.1-1-.3-1.5c-.1-.3 0-.5.3-.6.3-.1.5 0 .6.3.2.6.3 1.2.3 1.8 0 .6-.1 1.2-.3 1.8 0 .2-.2.3-.4.3z"
        fill="currentColor"
      />
    </g>
  </g>
);

const IconSize32 = () => (
  <g>
    <path
      d="M23.5 1.5c.4 0 .9.1 1.2.3.6.3 1 .9 1.2 1.5.2.7.1 1.4-.2 1.9L14.8 24.1l-.2.4.2.4c.5 1.3.4 2.7-.3 3.8-.8 1.4-2.3 2.3-3.9 2.3-.8 0-1.6-.2-2.2-.6-1-.6-1.8-1.6-2.1-2.7-.3-1.2-.2-2.4.4-3.4.7-1.2 1.8-2 3.2-2.2l.5-.1.2-.4L21.3 2.7c.5-.7 1.3-1.2 2.2-1.2m0-1c-1.2 0-2.4.6-3 1.8L9.6 21.1c-1.6.3-3 1.2-3.9 2.7-1.5 2.6-.6 6 2 7.5.9.5 1.8.7 2.7.7 1.9 0 3.7-1 4.8-2.8.9-1.5.9-3.2.4-4.7L26.5 5.7c1-1.7.4-3.8-1.3-4.8-.5-.3-1.1-.4-1.7-.4z"
      fill="currentColor"
    />
    <path d="M19.3 10.2L10.8 25l.9.5 8.5-14.8-.9-.5z" fill="currentColor" />
    <path
      d="M10.5 25c.3 0 .5.1.7.2.3.2.6.5.7.9.1.4.1.8-.1 1.1-.3.5-.8.8-1.3.8-.3 0-.5-.1-.7-.2-.3-.2-.6-.5-.7-.9-.1-.4-.1-.8.1-1.1.3-.5.8-.8 1.3-.8m0-1c-.9 0-1.7.4-2.2 1.3-.7 1.2-.3 2.7.9 3.4.4.2.8.3 1.2.3.9 0 1.7-.4 2.2-1.3.7-1.2.3-2.7-.9-3.4-.3-.2-.8-.3-1.2-.3z"
      fill="currentColor"
    />
    <path
      transform="rotate(30 11.737 13.356)"
      d="M10.7 12.9h2v1h-2z"
      fill="currentColor"
    />
    <path
      transform="rotate(120 10.237 15.955)"
      d="M9.7 15h1v2h-1z"
      fill="currentColor"
    />
    <path
      transform="rotate(30 12.37 10.258)"
      d="M10.4 9.8h4v1h-4z"
      fill="currentColor"
    />
    <path
      transform="rotate(30 16.236 5.562)"
      d="M15.2 5.1h2v1h-2z"
      fill="currentColor"
    />
    <path
      transform="rotate(120 14.736 8.16)"
      d="M14.2 7.2h1v2h-1z"
      fill="currentColor"
    />
    <path
      transform="rotate(30 16.87 2.464)"
      d="M14.9 2h4v1h-4z"
      fill="currentColor"
    />
    <path
      d="M18.8 28.7h-.2c-.3-.1-.4-.4-.3-.6.1-.4.2-.8.2-1.2s-.1-.8-.2-1.2c-.1-.3 0-.5.3-.6.3-.1.5 0 .6.3.2.5.3 1 .3 1.5s-.1 1-.3 1.5c0 .2-.2.3-.4.3zM21.6 29.8h-.2c-.3-.1-.4-.4-.3-.6.3-.7.4-1.5.4-2.2s-.1-1.5-.4-2.2c-.1-.3 0-.5.3-.6.3-.1.5 0 .6.3.3.8.5 1.7.5 2.6s-.2 1.7-.5 2.6c0-.1-.2.1-.4.1z"
      fill="currentColor"
    />
  </g>
);

const IconSize40 = () => (
  <g>
    <g opacity=".1">
      <path
        d="M21.7 32.6l10.9-18.8c1-1.7.4-3.8-1.3-4.8s-3.8-.4-4.8 1.3L15.6 29.1c-1.6.3-3 1.2-3.9 2.7-1.5 2.6-.6 6 2 7.5s6 .6 7.5-2c.9-1.5 1-3.2.5-4.7z"
        fill="currentColor"
      />
      <path
        transform="rotate(30 16.739 20.905)"
        d="M15.7 20.4h2v1h-2z"
        fill="currentColor"
      />
      <path
        transform="rotate(120 15.238 23.504)"
        d="M14.7 22.5h1v2h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(30 17.372 17.807)"
        d="M15.4 17.3h4v1h-4z"
        fill="currentColor"
      />
      <path
        transform="rotate(30 21.238 13.11)"
        d="M20.2 12.6h2v1h-2z"
        fill="currentColor"
      />
      <path
        transform="rotate(120 19.738 15.71)"
        d="M19.2 14.7h1v2h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(30 21.872 10.013)"
        d="M19.9 9.5h4v1h-4z"
        fill="currentColor"
      />
    </g>
    <path
      d="M24.5 5c.4 0 .9.1 1.2.3.6.3 1 .9 1.2 1.5.2.6.1 1.3-.2 1.9L15.8 27.6l-.2.4.2.4c.5 1.3.4 2.7-.3 3.8-.8 1.4-2.3 2.3-3.9 2.3-.8 0-1.6-.2-2.2-.6-1-.6-1.8-1.6-2.1-2.7-.5-1.2-.3-2.4.3-3.4.7-1.2 1.8-2 3.2-2.2l.5-.1.2-.4L22.3 6.2c.5-.7 1.3-1.2 2.2-1.2m0-1c-1.2 0-2.4.6-3 1.8L10.6 24.6c-1.6.3-3 1.2-3.9 2.7-1.5 2.6-.6 6 2 7.5.9.5 1.8.7 2.7.7 1.9 0 3.7-1 4.8-2.8.9-1.5.9-3.2.4-4.7L27.5 9.2c1-1.7.4-3.8-1.3-4.8-.5-.3-1.1-.4-1.7-.4z"
      fill="currentColor"
    />
    <path d="M20.3 13.7l-8.5 14.8.9.5 8.5-14.8-.9-.5z" fill="currentColor" />
    <path
      d="M11.5 28.5c.3 0 .5.1.7.2.3.2.6.5.7.9.1.4.1.8-.1 1.1-.3.5-.8.8-1.3.8-.3 0-.5-.1-.7-.2-.3-.2-.6-.5-.7-.9-.1-.4-.1-.8.1-1.1.3-.5.8-.8 1.3-.8m0-1c-.9 0-1.7.4-2.2 1.3-.7 1.2-.3 2.7.9 3.4.4.2.8.3 1.2.3.9 0 1.7-.4 2.2-1.3.7-1.2.3-2.7-.9-3.4-.3-.2-.8-.3-1.2-.3z"
      fill="currentColor"
    />
    <path
      transform="rotate(30 12.737 16.869)"
      d="M11.7 16.4h2v1h-2z"
      fill="currentColor"
    />
    <path
      transform="rotate(120 11.237 19.468)"
      d="M10.7 18.5h1v2h-1z"
      fill="currentColor"
    />
    <path
      transform="rotate(30 13.37 13.77)"
      d="M11.4 13.3h4v1h-4z"
      fill="currentColor"
    />
    <path
      transform="rotate(30 17.237 9.075)"
      d="M16.2 8.6h2v1h-2z"
      fill="currentColor"
    />
    <path
      transform="rotate(120 15.737 11.673)"
      d="M15.2 10.7h1v2h-1z"
      fill="currentColor"
    />
    <path
      transform="rotate(30 17.87 5.977)"
      d="M15.9 5.5h4v1h-4z"
      fill="currentColor"
    />
    <path
      d="M19.8 32.3h-.2c-.3-.1-.4-.4-.3-.6.1-.4.2-.8.2-1.2s-.1-.8-.2-1.2c-.1-.3 0-.5.3-.6.3-.1.5 0 .6.3.2.5.3 1 .3 1.5s-.1 1-.3 1.5c0 .1-.2.3-.4.3zM22.6 33.3h-.2c-.3-.1-.4-.4-.3-.6.3-.7.4-1.5.4-2.2 0-.8-.1-1.5-.4-2.2-.1-.3 0-.5.3-.6.3-.1.5 0 .6.3.3.8.5 1.7.5 2.6 0 .9-.2 1.7-.5 2.6 0 0-.2.1-.4.1z"
      fill="currentColor"
    />
  </g>
);

const IconSize80 = () => (
  <g>
    <path
      d="M24.6 58.7c.6 0 1.2.2 1.7.5.8.5 1.4 1.2 1.6 2.1s.1 1.8-.3 2.7c-.6 1.1-1.8 1.8-3 1.8-.6 0-1.2-.2-1.7-.5-.8-.5-1.4-1.2-1.6-2.1-.2-.9-.1-1.8.3-2.7.6-1.2 1.8-1.8 3-1.8m0-1c-1.6 0-3.1.8-3.9 2.3-1.2 2.2-.5 4.9 1.6 6.1.7.4 1.5.6 2.2.6 1.6 0 3.1-.8 3.9-2.3 1.2-2.2.5-4.9-1.6-6.1-.7-.4-1.4-.6-2.2-.6zM48.9 19L26.4 58l.9.5 22.5-39z"
      fill="currentColor"
    />
    <path
      d="M56.1 3.1c.8 0 1.6.2 2.2.6 2.1 1.2 2.9 4 1.6 6.1L32.6 57.3l-.5.8.4.9c1 2.4.8 5.1-.5 7.4-1.5 2.6-4.3 4.3-7.4 4.3-1.5 0-2.9-.4-4.2-1.1-2-1.1-3.4-3-4-5.2-.6-2.2-.3-4.5.8-6.4 1.3-2.3 3.5-3.8 6.1-4.2l1-.1.5-.8L52.2 5.4c.8-1.4 2.3-2.3 3.9-2.3m0-2c-2.2 0-4.4 1.2-5.6 3.3L23.1 51.8c-3.1.4-5.9 2.2-7.6 5.1-2.9 5-1.2 11.4 3.8 14.3 1.7 1 3.5 1.4 5.2 1.4 3.6 0 7.2-1.9 9.1-5.3 1.7-2.9 1.8-6.3.7-9.1l27.4-47.4c1.8-3.1.7-7.1-2.4-8.9-1-.5-2.1-.8-3.2-.8z"
      fill="currentColor"
    />
    <path
      d="M29.1 27.2l-.5.9 4.4 2.5.5-.8zM33.3 26.2l-.5.8 1.7 1 .5-.8zM34.8 23.6l-.5.8 1.7 1 .5-.8zM36.6 14.3l-.5.8 4.4 2.5.5-.8zM40.8 13.2l-.5.8 1.7 1 .5-.8zM42.3 10.6l-.5.8 1.7 1 .5-.8zM36.3 21l-.5.8 1.7 1 .5-.8zM37.8 18.4l-.5.8 1.7 1 .5-.8zM21.6 40.2l-.5.9 4.4 2.5.5-.8zM25.8 39.2l-.5.8 1.7 1 .5-.8zM27.3 36.6l-.5.8 1.7 1 .5-.8zM28.8 34l-.5.8 1.7 1 .5-.8zM30.3 31.4l-.5.8 1.7 1 .5-.8z"
      fill="currentColor"
    />
    <g opacity=".1">
      <path
        d="M68.8 9.4c-3.1-1.8-7.1-.7-8.9 2.4L32.6 59.2c-3.1.4-5.9 2.2-7.6 5.1-2.9 5-1.2 11.4 3.8 14.3s11.4 1.2 14.3-3.8c1.7-2.9 1.8-6.3.7-9.1l27.4-47.4c1.8-3.1.8-7.1-2.4-8.9z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 40.958 36.594)"
        d="M40.5 34.6h1v4h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 43.34 34.465)"
        d="M42.8 33.5h1v2h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 44.837 31.866)"
        d="M44.3 30.9h1v2h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 48.45 23.598)"
        d="M47.9 21.6h1v4h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 50.83 21.47)"
        d="M50.3 20.5h1v2h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 52.33 18.87)"
        d="M51.8 17.9h1v2h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 46.336 29.267)"
        d="M45.8 28.3h1v2h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 47.834 26.668)"
        d="M47.3 25.7h1v2h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 33.465 49.589)"
        d="M33 47.6h1v4h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 35.847 47.46)"
        d="M35.3 46.5h1v2h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 37.345 44.861)"
        d="M36.8 43.9h1v2h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 38.844 42.262)"
        d="M38.3 41.3h1v2h-1z"
        fill="currentColor"
      />
      <path
        transform="rotate(-60 40.342 39.663)"
        d="M39.8 38.7h1v2h-1z"
        fill="currentColor"
      />
    </g>
    <path
      d="M26.6 13.1c-.3 0-.5-.2-.5-.5v-6c0-.3.2-.5.5-.5s.5.2.5.5v6c0 .3-.2.5-.5.5z"
      fill="currentColor"
    />
    <path
      d="M29.6 10.1h-6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h6c.3 0 .5.2.5.5s-.2.5-.5.5zM16.6 27.1c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5s.5.2.5.5v2c0 .3-.2.5-.5.5z"
      fill="currentColor"
    />
    <path
      d="M17.6 26.1h-2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h2c.3 0 .5.2.5.5s-.2.5-.5.5zM18.6 16.1h-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1c.3 0 .5.2.5.5s-.2.5-.5.5zM63.6 37.1h-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1c.3 0 .5.2.5.5s-.2.5-.5.5zM59.6 43.1h-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1c.3 0 .5.2.5.5s-.2.5-.5.5zM16.6 19.1h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3c.3 0 .5.2.5.5s-.2.5-.5.5zM66.6 40.1h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3c.3 0 .5.2.5.5s-.2.5-.5.5zM24.6 19.1h-5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5c.3 0 .5.2.5.5s-.2.5-.5.5zM59.6 40.1h-5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5c.3 0 .5.2.5.5s-.2.5-.5.5zM38.5 66.6c-.1 0-.2 0-.2-.1-.2-.1-.3-.4-.2-.7.6-1 .9-2.2.9-3.4s-.3-2.4-.9-3.4c-.1-.2-.1-.5.2-.7.2-.1.5-.1.7.2.7 1.2 1 2.5 1 3.9s-.4 2.7-1 3.9c-.2.2-.4.3-.5.3zM41.3 68.2c-.1 0-.2 0-.2-.1-.2-.1-.3-.4-.2-.7.9-1.5 1.4-3.3 1.4-5.1s-.5-3.5-1.4-5.1c-.1-.2-.1-.5.2-.7.2-.1.5-.1.7.2 1 1.7 1.5 3.6 1.5 5.6s-.5 3.9-1.5 5.6c-.1.2-.3.3-.5.3zM44.2 69.9c-.1 0-.2 0-.2-.1-.2-.1-.3-.4-.2-.7 1.2-2 1.8-4.4 1.8-6.8 0-2.4-.6-4.7-1.8-6.8-.1-.2-.1-.5.2-.7.2-.1.5-.1.7.2 1.3 2.2 1.9 4.7 1.9 7.2s-.7 5-1.9 7.2c-.1.4-.3.5-.5.5z"
      fill="currentColor"
    />
  </g>
);

const getIconBySize = (size: number) => {
  switch (true) {
    case size < 32:
      return { viewBox: '0 0 16 16', icon: <IconSize16 /> };

    case size < 40:
      return { viewBox: '0 0 32 32', icon: <IconSize32 /> };

    case size === 40:
      return { viewBox: '0 0 40 40', icon: <IconSize40 /> };

    default:
      return { viewBox: '0 0 80 80', icon: <IconSize80 /> };
  }
};

export default ({ classes = {}, size = 40 }: SensorIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
