import * as React from 'react';
import styles from './StepHandlingItem.module.css';
import PriorityLevel from '../../../PriorityLevel';
import TextField from '../../../ui/TextField';
import Button from '../../../ui/Button';
import Selector from '../../../ui/Selector';
import FormText from '../../../ui/FormText';
import {
  initialSentinelEscalation,
  IntervalUnit,
  SentinelEscalation,
  SentinelRecurType,
} from '../../../../types/sentinel';
import { Recipient } from './StepHandling';
import TrashIcon from '../../../icons/ui/Trash';
import FormCheckbox from '../../../ui/FormCheckbox/FormCheckbox';
import StepHandlingItemConditionLabel from './StepHandlingItemConditionLabel';
import FormTimeSelect from '../../../FormTimeSelect';
import {
  DEFAULT_SENTINEL_ESCALATION_SINGLE_RUN_DELAY,
  DEFAULT_SENTINEL_RECUR_INTERVAL,
} from '../../../../constants/sentinels';
import NativeSelectField from '../../../ui/NativeSelectField';

type Props = {
  escalation: SentinelEscalation;
  isDeleteAble: boolean;
  options: any[];
  handleAddEscalation: (sentinelEscalation: SentinelEscalation) => void;
  handleUpdateEscalation: (sentinelEscalation: SentinelEscalation) => void;
  handleDeleteEscalation: () => void;
  index: number;
  isLoading: boolean;
};

type State = {
  isEditable: boolean;
  escalation: SentinelEscalation;
};

class StepHandlingItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditable: false,
      escalation: initialSentinelEscalation,
    };
  }

  componentDidMount() {
    this.setState({
      escalation: this.props.escalation,
    });
  }

  componentDidUpdate(prevProps: Props): void {
    const { isLoading } = this.props;
    if (prevProps.isLoading !== isLoading) {
      this.setState({
        escalation: this.props.escalation,
      });
    }
  }

  handleChange = (name: string) => (ev: any) =>
    this.setState({
      escalation: {
        ...this.state.escalation,
        [name]: ev.target.value,
      },
    });

  handleDelayChange = (name: string) => (value: number) => {
    this.setState({
      escalation: {
        ...this.state.escalation,
        [name]: {
          value,
          unit: IntervalUnit.MILLISECOND,
        },
      },
    });
  };

  handleEscalationChange = (sentinelRecipient: Recipient) =>
    this.setState({
      escalation: {
        ...this.state.escalation,
        recipients: this.state.escalation.recipients.find(
          e => +e.identifier === sentinelRecipient.id
        )
          ? this.state.escalation.recipients.filter(
              e => +e.identifier !== sentinelRecipient.id
            )
          : [...this.state.escalation.recipients, sentinelRecipient],
      },
    });

  handleOnSave = () => {
    this.props.handleUpdateEscalation({ ...this.state.escalation });
    this.setState({ isEditable: false });
  };

  handleOnCancel = () =>
    this.setState({ isEditable: false, escalation: this.props.escalation });

  handleSwitchEditableMode = () =>
    this.setState(prevState => ({ isEditable: !prevState.isEditable }));

  handleToggleDelayChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    const { checked } = ev.target;

    this.setState(prevState => ({
      escalation: {
        ...prevState.escalation,
        singleRun: checked,
        singleRunDelay: checked
          ? {
              unit: IntervalUnit.MILLISECOND,
              value: DEFAULT_SENTINEL_ESCALATION_SINGLE_RUN_DELAY,
            }
          : { unit: IntervalUnit.MILLISECOND, value: 0 },
      },
    }));
  };

  handleToggleRecurTypeChange = () =>
    this.setState(prevState => ({
      escalation: {
        ...prevState.escalation,
        recurType:
          prevState.escalation.recurType !== SentinelRecurType.UNTIL_RESOLVED
            ? SentinelRecurType.UNTIL_RESOLVED
            : SentinelRecurType.NONE,
        recurInterval:
          prevState.escalation.recurInterval &&
          typeof prevState.escalation.recurInterval.value === 'number'
            ? undefined
            : {
                unit: IntervalUnit.MILLISECOND,
                value: DEFAULT_SENTINEL_RECUR_INTERVAL,
              },
      },
    }));

  stateIsValid = () =>
    this.state.escalation.name !== '' &&
    this.state.escalation.recipients.length > 0;

  escalationItemIsValid = () =>
    this.props.escalation.name !== '' &&
    this.props.escalation.recipients.length > 0;

  render() {
    const { options, isDeleteAble, index, isLoading } = this.props;
    const { isEditable, escalation } = this.state;

    const isFirstEscalationLevel = index === 0;
    const isEditableOrInvalid =
      isEditable || this.escalationItemIsValid() === false;

    return (
      <div
        className={`${styles.root} ${
          styles[escalation.priorityLevel.toLowerCase()]
        }`}
      >
        <div className={styles.handling}>
          <div>
            {isEditableOrInvalid ? (
              <TextField
                isTitle
                placeholder="Action"
                value={escalation.name || ''}
                onChange={this.handleChange('name')}
              />
            ) : (
              <FormText isTitle>
                {this.props.escalation.name || 'Action'}
              </FormText>
            )}

            {isFirstEscalationLevel && (
              <FormText>Once the first notification is triggered.</FormText>
            )}
          </div>

          <div>
            {isEditableOrInvalid ? (
              <Selector
                isLoading={isLoading}
                setValue={this.handleEscalationChange}
                selectorField="title"
                fields={['user', 'groups']}
                selectedIds={escalation.recipients.map(
                  item => +item.identifier
                )}
                selected={escalation.recipients}
                options={options}
                placeholder="Type or click to select a recipient"
              />
            ) : (
              <FormText>
                {this.props.escalation.recipients.length} Recipients
              </FormText>
            )}
          </div>

          {isEditableOrInvalid ? (
            <NativeSelectField
              onChange={this.handleChange('priorityLevel')}
              value={escalation.priorityLevel}
            >
              <option value="NORMAL">Regular</option>
              <option value="MEDIUM">Medium</option>
              <option value="HIGH">High</option>
              <option value="HIGHEST">Highest</option>
            </NativeSelectField>
          ) : (
            <FormText>
              <PriorityLevel priority={this.props.escalation.priorityLevel}>
                {this.props.escalation.priorityLevel.toLowerCase()}
              </PriorityLevel>
            </FormText>
          )}

          <FormText>Email</FormText>
          <div>
            {isEditableOrInvalid ? (
              <TextField
                placeholder="Optional message"
                onChange={this.handleChange('message')}
                value={escalation.message || ''}
              />
            ) : (
              <FormText>{this.props.escalation.message || ''} </FormText>
            )}
          </div>

          {isFirstEscalationLevel === false && (
            <>
              <div>
                {isEditableOrInvalid ? (
                  <FormCheckbox
                    onChange={this.handleToggleDelayChange}
                    checked={escalation.singleRun}
                    label="Notify if no action taken in"
                  />
                ) : (
                  <StepHandlingItemConditionLabel
                    label="Notify if no action taken in"
                    value={
                      this.props.escalation.singleRunDelay
                        ? this.props.escalation.singleRunDelay.value
                        : 0
                    }
                  />
                )}
              </div>
              <div>
                {isEditableOrInvalid && (
                  <FormTimeSelect
                    disabled={escalation.singleRun === false}
                    onChange={this.handleDelayChange('singleRunDelay')}
                    value={
                      escalation.singleRunDelay
                        ? escalation.singleRunDelay.value
                        : 0
                    }
                  />
                )}
              </div>
              <div />
              <div />
              <div />

              <div>
                {isEditableOrInvalid ? (
                  <>
                    <FormCheckbox
                      onChange={this.handleToggleRecurTypeChange}
                      checked={
                        escalation.recurType ===
                        SentinelRecurType.UNTIL_RESOLVED
                      }
                      label="Notify repeatedly until resolved every"
                    />
                  </>
                ) : (
                  <StepHandlingItemConditionLabel
                    label="Notify repeatedly until resolved every"
                    value={
                      this.props.escalation.recurInterval
                        ? this.props.escalation.recurInterval.value
                        : 0
                    }
                  />
                )}
              </div>
              <div>
                {isEditableOrInvalid && (
                  <FormTimeSelect
                    disabled={escalation.recurInterval === undefined}
                    onChange={this.handleDelayChange('recurInterval')}
                    value={
                      escalation.recurInterval && escalation.recurInterval.value
                    }
                  />
                )}
              </div>
              <div />
              <div />
              <div />
            </>
          )}
        </div>

        <div
          className={`${styles.actions} ${
            isEditableOrInvalid
              ? styles.actionsEditable
              : styles.actionsPositionFixed
          }`}
        >
          {isEditableOrInvalid ? (
            <>
              {isDeleteAble ? (
                <>
                  <Button
                    size="small"
                    secondary
                    onClick={this.props.handleDeleteEscalation}
                  >
                    <TrashIcon className={styles.deleteIcon} />
                    <span className={styles.deleteText}>Remove action</span>
                  </Button>
                </>
              ) : (
                <div />
              )}

              <div>
                <Button size="small" secondary onClick={this.handleOnCancel}>
                  Cancel
                </Button>{' '}
                <Button
                  size="small"
                  onClick={this.handleOnSave}
                  disabled={this.stateIsValid() === false}
                >
                  Ok
                </Button>
              </div>
            </>
          ) : (
            <>
              <div />
              <Button size="small" onClick={this.handleSwitchEditableMode}>
                Edit
              </Button>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default StepHandlingItem;
