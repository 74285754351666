import * as React from 'react';
import { connect } from 'react-redux';

import { toUnixTimestamp } from '../../../utils/dates';

import styles from './ValueChip.module.css';
import temperature from '../../../utils/temperature';
import { SentinelType } from '../../../types/sentinel';
import { getOperationalValue, SensorType } from '../../../types/sensors';
import { fetchOperationalSamples } from '../../../actions/samples';
import { MeasurementSystem } from '../../../types/shared';
import { getLastSampleBySensorType } from '../../../utils/samples';
import {
  OperationalHumiditySample,
  OperationalSample,
  OperationalTemperatureSample,
} from '../../../types/samples';

type Props = {
  sensorId: number | string;
  updatedAt?: string;
  value?: any[];
  secondaryText?: string;
  measurementSystem: MeasurementSystem;
  type: SensorType;
};

type State = {
  value: any[];
  isLoading: boolean;
};

const createElectricityValue = (value: string) => <span>{`${value} kWh`}</span>;

const createBasicValue = (value: string) => <span>{`${value}`}</span>;

const createTemperatureValue = (
  value: number,
  measurementSystem: MeasurementSystem
) => (
  <span>
    {`${
      measurementSystem === MeasurementSystem.imperial
        ? `${temperature(Number(value), 'c', 0)} ˚F`
        : `${Number(value).toFixed(0)} ˚C`
    }`}
  </span>
);

const createHumidityValue = (
  valueArray: number[],
  measurementSystem: MeasurementSystem
) => (
  <div>
    <span> {`${valueArray[0]}%`} </span>
    <span className={styles.secondarySensorValueText}> {` @ `}</span>
    <span className={styles.secondarySensorValueText}>
      {' '}
      {createTemperatureValue(valueArray[1], measurementSystem)}{' '}
    </span>
  </div>
);

const createTextFromValueAndType = (
  value: any,
  type: string,
  measurementSystem: MeasurementSystem
) => {
  try {
    switch (type) {
      case 'sentinel_type_electricity': {
        return createElectricityValue(value);
      }

      case SentinelType.DOOR: {
        return createBasicValue(value);
      }

      case SensorType.TEMPERATURE: {
        return createTemperatureValue(value, measurementSystem);
      }

      case SentinelType.TEMPERATURE: {
        return createTemperatureValue(value, measurementSystem);
      }

      case SensorType.HUMIDITY: {
        return createHumidityValue(value, measurementSystem);
      }
      default: {
        return createBasicValue(value);
      }
    }
  } catch (e) {
    // log error
  }
  return createBasicValue(value);
};

class ValueChip extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const { sensorId, updatedAt, type, value } = this.props;
    if (value) {
      return;
    }

    if (!updatedAt) {
      return;
    }

    if (!sensorId) {
      return;
    }

    this.setState({ isLoading: true });
    const from = toUnixTimestamp(updatedAt);

    const queryParams = `filter[sensor]=${sensorId.toString()}&filter[from]=${from -
      3600}&filter[to]=${from + 3600}`;

    fetchOperationalSamples(queryParams)
      .then((r: OperationalSample[]) => {
        switch (type) {
          case SensorType.HUMIDITY:
            const humiditySample = getLastSampleBySensorType(
              r,
              type
            ) as OperationalHumiditySample;
            const temperatureSample = getLastSampleBySensorType(
              r,
              SensorType.TEMPERATURE
            ) as OperationalTemperatureSample;

            this.setState({
              value: [
                humiditySample && humiditySample.humidity,
                temperatureSample && temperatureSample.temperature,
              ].filter(x => x),
              isLoading: false,
            });

            break;

          default:
            const sample = getLastSampleBySensorType(r, type);
            this.setState({
              value: [getOperationalValue(type, sample)],
              isLoading: false,
            });
        }
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const { type, secondaryText, measurementSystem } = this.props;
    const { isLoading } = this.state;

    const value = this.props.value || this.state.value;

    if (isLoading) {
      return '..load';
    }

    if (value.length === 0) {
      return <span>n/a</span>;
    }

    return (
      <span className={styles.sensorIconAndTextGrouping}>
        <span className={styles.sensorTypeText}>
          {createTextFromValueAndType(value, type, measurementSystem)}
          {secondaryText && (
            <div className={styles.secondaryText}> {secondaryText}</div>
          )}
        </span>
      </span>
    );
  }
}

const mapStateToProps = (state: any) => ({
  measurementSystem: state.app.locale.measurementSystem,
});

export default connect(
  mapStateToProps,
  null
)(ValueChip);
