import { Sentinel } from '../types/sentinel';
import { StepType } from '../types/sentinelWizard';

export const determineWizardStep = (sentinel: Sentinel): StepType => {
  if (!sentinel.sentinelType) {
    return StepType.type;
  }

  if (!sentinel.parameters || Object.keys(sentinel.parameters).length === 0) {
    return StepType.condition;
  }

  // if(sentinel.schedule){
  //   return stepKeys.schedule
  // }

  if (
    (sentinel.escalations && sentinel.escalations.length === 0) ||
    // @ts-ignore todo: ts-fix
    [].concat(...sentinel.escalations.map(escalation => escalation.recipients))
      .length === 0
  ) {
    return StepType.handling;
  }

  if (sentinel.targets && sentinel.targets.length === 0) {
    return StepType.rollout;
  }

  return StepType.type;
};
