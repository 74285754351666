import { store } from '../..';
import convertTemp from '../../utils/temperature';
import { MeasurementSystem } from '../../types/shared';

export default function formatTemperature(
  value: number = 0,
  decimals: number = 0
) {
  // @ts-ignore todo: ts-fix
  const ms = store.getState().app.locale.measurementSystem;

  return ms === MeasurementSystem.imperial
    ? `${convertTemp(value, 'c', decimals)} °F`
    : `${value.toFixed(decimals)} °C`;
}
