import * as React from 'react';
import IconSensorTypeFactory from '../../../icons/IconSensorTypeFactory';
import Switch from '../../../Switch';
import classNames from '../../../../utils/classNames';
import {
  defaultDoorParameters,
  SentinelType,
} from '../../../../types/sentinel';
import { ParametersSwitch } from '../../../../types/sentinel';
import StepConditionItem from './StepConditionItem';
import styles from './ConditionDoor.module.css';

type Props = {
  onChange: (arg0: string, arg1: string) => void;
  parameters?: ParametersSwitch;
};

const ConditionDoor = ({
  onChange,
  parameters = defaultDoorParameters,
}: Props) => (
  <StepConditionItem
    label={
      <span
        className={classNames(
          styles.icon,
          parameters &&
            parameters.openClosed === 'CLOSED' &&
            styles.doorIconStateClosed
        )}
      >
        <IconSensorTypeFactory
          size={80}
          id={SentinelType.DOOR}
          parameters={parameters}
        />
      </span>
    }
    condition={
      <div className={styles.switch}>
        <Switch
          id="doorAccess"
          onChange={() =>
            onChange(
              'openClosed',
              parameters && parameters.openClosed === 'OPEN' ? 'CLOSED' : 'OPEN'
            )
          }
          checked={parameters && parameters.openClosed === 'OPEN'}
        />
        <span className={styles.switchLabel}>
          {`Door ${parameters.openClosed === 'OPEN' ? 'opened' : 'closed'}`}
        </span>
      </div>
    }
    description="The notifications will be triggered when the door is opened"
  />
);

export default ConditionDoor;
