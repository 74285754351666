import * as React from 'react';
import styles from './StepSchedule.module.css';
import StepTitle from '../StepTitle';
import {
  initialSentinelSchedule,
  sentinelConfigurations,
  SentinelSchedule,
  SentinelType,
} from '../../../../types/sentinel';
import StepScheduleItem from './StepScheduleItem';
import AddEntry from '../StepActions/AddEntry';

type Props = {
  schedules: SentinelSchedule[];
  handleAddSchedule: (schedule: SentinelSchedule) => void;
  handleUpdateSchedule: (index: number) => (schedule: SentinelSchedule) => void;
  handleRemoveSchedule: (index: number) => void;
  sentinelType: SentinelType;
};

function StepSchedule(props: Props) {
  const {
    schedules,
    handleAddSchedule,
    handleUpdateSchedule,
    handleRemoveSchedule,
    sentinelType,
  } = props;

  return (
    <div className={styles.root}>
      <StepTitle>
        Please setup a time and schedule for your configuration
      </StepTitle>

      {schedules.length > 0 && (
        <div className={styles.container}>
          {schedules.map((schedule, index) => (
            <StepScheduleItem
              key={`SentinelScheduleItem${index.toString()}`}
              schedule={schedule}
              handleUpdateSchedule={handleUpdateSchedule(index)}
              handleRemoveSchedule={() => handleRemoveSchedule(index)}
            />
          ))}
        </div>
      )}

      {schedules.length === 0 && (
        <div className={styles.centeredContainer}>
          This sentinel is valid for a daily period of 24 hours
        </div>
      )}

      {sentinelConfigurations[sentinelType].customSchedule &&
        schedules.length < 5 && (
          <AddEntry
            title="Add new time schedule"
            onClick={() => handleAddSchedule(initialSentinelSchedule)}
          />
        )}
    </div>
  );
}

export default StepSchedule;
