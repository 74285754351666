// import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import format from 'date-fns/format';

const today = startOfDay(new Date());
const startOfTomorrow = today; // todo: addDays(today, 1);

const notificationDateFilter = [
  {
    title: 'Today',
    from: format(today, 'yyyy-MM-dd'),
    to: format(startOfTomorrow, 'yyyy-MM-dd'),
  },
  {
    title: 'Yesterday',
    from: format(subDays(today, 1), 'yyyy-MM-dd'),
    to: format(subDays(startOfTomorrow, 1), 'yyyy-MM-dd'),
  },
  {
    title: 'Last 7 days',
    from: format(subDays(today, 7), 'yyyy-MM-dd'),
    to: format(startOfTomorrow, 'yyyy-MM-dd'),
  },
  {
    title: 'Last 30 days',
    from: format(subDays(today, 30), 'yyyy-MM-dd'),
    to: format(startOfTomorrow, 'yyyy-MM-dd'),
  },
];

export default notificationDateFilter;
