import * as React from 'react';
import classNames from '../../../utils/classNames';
import styles from './TextField.module.css';
import InputField from '../InputField/InputField';

type Props = {
  isMainTitle?: boolean;
  isTitle?: boolean;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  value: string;
  label?: string;
  fullWidth?: boolean;
  error?: boolean;
  extraClassName?: string;
  onChange?: any;
  onFocus?: any;
  autoFocus?: boolean;
};

const TextField = ({
  isMainTitle = false,
  isTitle = false,
  type = 'text',
  label,
  fullWidth,
  value,
  placeholder = '',
  disabled = false,
  error = false,
  extraClassName = '',
  autoFocus = false,
  ...rest
}: Props) => (
  <div className={classNames(fullWidth ? styles.fullWidth : null)}>
    {label && <span className={styles.label}> {label}</span>}
    <InputField
      type={type}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      error={error}
      extraClassName={extraClassName}
      isMainTitle={isMainTitle}
      isTitle={isTitle}
      autoFocus={autoFocus}
      {...rest}
    />
  </div>
);

export default TextField;
