export enum Actions {
  PATH_LOADING = '@@resources/PATH_LOADING',
  PATH_SUCCESS = '@@resources/PATH_SUCCESS',
  PATH_ERROR = '@@resources/PATH_ERROR',
}

export const getPathByResourceId = (resourceId: number) => ({
  type: 'API_GET',
  path: `/api/v1/resources/${resourceId}/path`,
  loading: { type: Actions.PATH_LOADING, resourceId },
  success: { type: Actions.PATH_SUCCESS, resourceId },
  error: { type: Actions.PATH_ERROR, resourceId },
});

export const getTinyPathByResourceId = (resourceId: number) => ({
  type: 'API_GET',
  path: `/api/v1/resources/${resourceId}/path-tiny`,
  loading: { type: Actions.PATH_LOADING, resourceId },
  success: { type: Actions.PATH_SUCCESS, resourceId },
  error: { type: Actions.PATH_ERROR, resourceId },
});
