import * as React from 'react';
import styles from './MenuItem.module.css';

type Props = {
  children: React.ReactNode;
  onClick?: (ev: any) => any;
};

const MenuItem = ({ children, ...rest }: Props) => (
  <div className={styles.root} {...rest}>
    {children}
  </div>
);

export default MenuItem;
