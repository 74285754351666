import { Space } from './space';
import { SensorType } from './sensors';
import { EquipmentType } from './tags';
import { ResourceType } from './resource';

const EQUIPMENT_STATUS_ON = 'EQUIPMENT_STATUS_ON';
const EQUIPMENT_STATUS_OFF = 'EQUIPMENT_STATUS_OFF';
const EQUIPMENT_STATUS_STANDBY = 'EQUIPMENT_STATUS_STANDBY';

// eslint-disable-next-line import/prefer-default-export
export const equipmentStatus = {
  on: EQUIPMENT_STATUS_ON,
  off: EQUIPMENT_STATUS_OFF,
  standby: EQUIPMENT_STATUS_STANDBY,
};

export const equipmentStatusToName = {
  [EQUIPMENT_STATUS_ON]: 'on',
  [EQUIPMENT_STATUS_OFF]: 'off',
  [EQUIPMENT_STATUS_STANDBY]: 'standby',
};

export type Equipment = {
  _entity: ResourceType.EQUIPMENT;
  id: number;
  title: string;
  description: string;
  spaceId?: number;
  space?: Space;
  type?: EquipmentType;
  typeId?: number;
  groupId?: number;
  createdAt: string;
  updatedAt?: string;
  _path?: Space[];
  pinned: boolean; // todo
  status:
    | 'EQUIPMENT_STATUS_ON'
    | 'EQUIPMENT_STATUS_OFF'
    | 'EQUIPMENT_STATUS_STANDBY';
  hasFullPath?: boolean;
};

export type EquipmentApiFilter = {
  equipmentTypeIds?: number[];
  siteIds?: number[];
  ids?: number[];
  limit?: number;
  skip?: number;
  sensorTypes?: SensorType[];
};
