import * as React from 'react';

import Checkbox from '../../ui/Checkbox';
import { NotificationStatus } from '../../../types/notification';

import FilterDropdown from '../FilterDropdown/FilterDropdown';
import FilterDropdownBasicItem from '../FilterDropdownItems/FilterDropdownBasicItem';

type Props = {
  setFilter: (notificationStatus: NotificationStatus) => void;
  notificationStatusFilters: NotificationStatus[];
};

type State = {};

class NotificationTypeFilter extends React.Component<Props, State> {
  getMenuFilterLabel = (notificationStatusFilters: NotificationStatus[]) => {
    if (
      notificationStatusFilters.length === 0 ||
      notificationStatusFilters.length ===
        Object.keys(NotificationStatus).length
    ) {
      return 'Status';
    }

    return notificationStatusFilters.join(', ');
  };

  render() {
    const { setFilter, notificationStatusFilters = [] } = this.props;

    return (
      <FilterDropdown
        title={this.getMenuFilterLabel(notificationStatusFilters)}
      >
        <FilterDropdownBasicItem>
          <Checkbox
            onChange={() => setFilter(NotificationStatus.unseen)}
            checked={notificationStatusFilters.includes(
              NotificationStatus.unseen
            )}
            label="Unseen Notifications"
          />
        </FilterDropdownBasicItem>
        <FilterDropdownBasicItem>
          <Checkbox
            onChange={() => setFilter(NotificationStatus.dismissed)}
            checked={notificationStatusFilters.includes(
              NotificationStatus.dismissed
            )}
            label="Dismissed Notifications"
          />
        </FilterDropdownBasicItem>
      </FilterDropdown>
    );
  }
}

export default NotificationTypeFilter;
