import * as React from 'react';
import Button from '../ui/Button';
import styles from './SentinelWizardNavigation.module.css';
import { StepType, Step, sentinelSteps } from '../../types/sentinelWizard';

type Props = {
  setStep: (stepType: StepType) => void;
  currentStep: StepType;
  stepsCompleted: { [key: string]: boolean };
  loading?: boolean;
  tipComponent?: any;
  handleSubmit: any;
  handleFinishLater: () => Promise<any>;
  handleValidation: (step: Step) => void;
};

const SentinelWizardNavigation = ({
  setStep,
  currentStep,
  stepsCompleted,
  loading = false,
  tipComponent,
  handleValidation,
  handleFinishLater,
}: Props) => {
  const stepsKeys = Object.keys(sentinelSteps);

  const currentStepIndex = stepsKeys.findIndex(
    s => s === currentStep.toString()
  );

  const prevStepKeyIndex = currentStepIndex - 1;
  const nextStepKeyIndex = currentStepIndex + 1;

  const prevStep = sentinelSteps[stepsKeys[prevStepKeyIndex]];
  const nextStep = sentinelSteps[stepsKeys[nextStepKeyIndex]];

  return (
    <div className={styles.root}>
      <div className={styles.tip}>{tipComponent}</div>
      <div className={styles.controls}>
        {prevStep ? (
          <Button
            fullWidth
            onClick={() => setStep(prevStep.key)}
            isLoading={loading}
          >
            Previous
          </Button>
        ) : (
          <span />
        )}

        <Button
          fullWidth
          onClick={() => handleValidation(nextStep)}
          disabled={stepsCompleted[currentStep] === false}
          isLoading={loading}
        >
          {nextStep ? `Next` : `Finish`}
        </Button>
      </div>

      <div className={styles.finishLaterButtonContainer}>
        <Button
          onClick={handleFinishLater}
          secondary
          extraClassName={styles.grayBackground}
        >
          Finish later
        </Button>
      </div>
    </div>
  );
};

export default SentinelWizardNavigation;
