import * as React from 'react';
import Loadable from 'react-loadable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconFactory } from '../../types/icon';

const Loading: any = () => <CircularProgress size={20} />;

const GenericSpace = Loadable({
  loader: () => import('./spaces/IconGenericSpace'),
  loading: Loading,
});

export default ({ classes, size, id }: IconFactory) => {
  switch (id) {
    case 'generic':
      return <GenericSpace classes={classes} size={size} />;
    default:
      return null;
  }
};
