import * as React from 'react';
import styles from './NotificationList.module.css';
import BaseList from '../BaseList';
import { Notification } from '../../../types/notification';
import ListCell from '../ListCell';
import NotificationListItem from '../../NotificationListItem';
import ListHeader from '../ListHeader/ListHeader';
import Button from '../../ui/Button';

type Props = {
  items: Notification[];
  onDismissClick: (notificationId: string) => void;
  isLoading?: boolean;
  hasError?: boolean;
};

type State = {
  renderCursor: number;
};

const extraStyles = {
  gridTemplateColumns: '3.75rem 1fr 1fr 3.75rem',
};

class NotificationList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      renderCursor: 50,
    };
  }

  loadMore = () => {
    const { renderCursor } = this.state;
    const { items } = this.props;

    if (items.length >= renderCursor) {
      this.setState({ renderCursor: renderCursor + 50 });
    }
  };

  render() {
    const { renderCursor } = this.state;

    let { items, onDismissClick } = this.props;
    return items.length === 0 ? (
      <div className={styles.messageContainer}>
        <div>
          <h5>
            Currently there are no notifications to be handled. <br /> Please
            come back later.
          </h5>
        </div>
        <div>
          <span>No notifications found</span>
        </div>
      </div>
    ) : (
      <BaseList>
        <ListHeader extraStyles={extraStyles} extraClassNames={[styles.header]}>
          <ListCell />
          <ListCell>Notification</ListCell>
          <ListCell>Source</ListCell>
          <ListCell centered>Dismiss</ListCell>
        </ListHeader>

        {items.slice(0, renderCursor).map((item: Notification) => (
          <NotificationListItem
            key={item.id}
            notification={item}
            onDismissClick={onDismissClick}
          />
        ))}

        {items.length >= renderCursor && (
          <div className={styles.actions}>
            <Button size="regular" onClick={this.loadMore}>
              Load more ({items.length - renderCursor})
            </Button>
          </div>
        )}
      </BaseList>
    );
  }

  static defaultProps = { items: [] };
}

export default NotificationList;
