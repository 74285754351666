export enum MeasurementSystem {
  metric = 'METRIC',
  imperial = 'IMPERIAL',
}

export const appMeasurementSystem = MeasurementSystem;

export type Locale = {
  measurementSystem: MeasurementSystem;
  region: string;
  temperatureUnit: string;
  temperatureFormat: string;
  timeFormat: string;
  dateFormat: string;
  dateTimeFormat: string;
  fullDateTimeFormat: string;
};

export const timeFormatWorld = 'hh:mm';
export const timeFormatUs = 'h:mm a';

export const dateFormatWorld = 'dd.MM.yyyy';
export const dateFormatUs = 'MM.dd.yyyy';

export const dateTimeWorldFormat = 'DD.MM.yyyy HH:mm';
export const dateTimeUsFormat = 'MM.DD.yyyy h:mm a';

export const fullDateTimeWorldFormat = 'dd.MM.yyyy HH:mm:ss';
export const fullDateTimeUsFormat = 'MM.dd.yyyy h:mm:ss a';

export const datePickerWorldFormat = 'yyyy-MM-dd';
export const datePickerUsFormat = 'MM-dd-yyyy';

export const datePickerDateFormatMap = {
  IMPERIAL: datePickerUsFormat,
  METRIC: datePickerWorldFormat,
};

export const locale: Locale = {
  measurementSystem: MeasurementSystem.metric,
  region: 'world',
  temperatureUnit: '°C',
  temperatureFormat: 'celsius',
  timeFormat: timeFormatWorld,
  dateFormat: dateFormatWorld,
  dateTimeFormat: `${dateFormatWorld} ${timeFormatWorld}`,
  fullDateTimeFormat: fullDateTimeWorldFormat,
};

export const localeUs: Locale = {
  measurementSystem: MeasurementSystem.imperial,
  region: 'us',
  temperatureUnit: '°F',
  temperatureFormat: 'fahrenheit',
  timeFormat: timeFormatUs,
  dateFormat: dateFormatUs,
  dateTimeFormat: `${dateFormatUs} ${timeFormatUs}`,
  fullDateTimeFormat: fullDateTimeUsFormat,
};
