import * as React from 'react';

import styles from './NotificationListItem.module.css';
import NotificationHandlingLog from '../NotificationHandlingLog/NotificationHandlingLog';
import { Notification } from '../../types/notification';
import { NotificationDismissedChip, ValueChip } from '../Tables/Cells';
import Button from '../ui/Button/Button';
import Checkbox from '../ui/Checkbox/Checkbox';

import IconSensorTypeFactory from '../icons/IconSensorTypeFactory';
import Badge from '../Badge';
import Path from '../../containers/Path/Path';
import SourceChip from '../../containers/SourceChip';

type Props = {
  notification: Notification;
  onDismissClick: (s: string) => void;
};

type State = {
  expanded: boolean;
};

function getButtonText(expanded: boolean, length: number): React.ReactNode {
  return expanded ? (
    'Show less'
  ) : (
    <>
      <Badge>{length}</Badge>
      <span className={styles.expandButtonText}>
        related notification
        {length === 1 ? '' : 's'}
      </span>
    </>
  );
}

class NotificationListItem extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  render() {
    const { notification } = this.props;
    const { handlingLogs = [] } = notification;
    const { priorityLevel = 'NORMAL' } = handlingLogs[0];

    return (
      <div
        className={`${styles.root} ${
          notification.dismissedAt ? styles.disabled : ''
        } ${priorityLevel && styles[priorityLevel.toLowerCase()]}`}
      >
        <div
          className={`${styles.icon} ${priorityLevel &&
            styles[`icon--${priorityLevel.toLowerCase()}`]}`}
        >
          <IconSensorTypeFactory size={20} id={notification.sentinelType} />
        </div>
        <div className={styles.logs}>
          {handlingLogs
            .slice(0, this.state.expanded ? handlingLogs.length : 1)
            .map((log, index) => (
              <NotificationHandlingLog
                key={`notificationElement${log.ix}${log.at}`}
                log={log}
                dismissedAt={notification.dismissedAt}
                value={log.sensorParams.map(param => (
                  <ValueChip
                    key={`${notification.id}}SensorNotification${
                      param.sensorId
                    }`}
                    value={[param.value]}
                    type={param.type}
                    sensorId={param.sensorId}
                  />
                ))}
                isFirstLog={index === 0}
                priorityLevel={priorityLevel && priorityLevel.toLowerCase()}
              />
            ))}

          {handlingLogs.length > 1 && (
            <Button
              secondary
              size="small"
              extraClassName={styles.button}
              onClick={() =>
                this.setState(prevState => ({
                  expanded: !prevState.expanded,
                }))
              }
            >
              {getButtonText(this.state.expanded, handlingLogs.length - 1)}
            </Button>
          )}
        </div>
        <div className={styles.location}>
          <div>
            <SourceChip
              resourceId={parseInt(notification.sensorParams[0].sensorId, 10)}
            />

            <Path
              resourceId={parseInt(notification.sensorParams[0].sensorId, 10)}
            />
          </div>

          {notification.dismissedAt && (
            <div>
              <NotificationDismissedChip
                dismissedComment={notification.dismissedComment}
                dismissedAt={notification.dismissedAt}
                dismissedBy={notification.dismissedBy}
              />
            </div>
          )}
        </div>
        <div className={styles.action}>
          <Checkbox
            id={notification.id}
            checked={notification.dismissedAt !== null}
            disabled={notification.dismissedAt !== null}
            size={20}
            onChange={
              notification.dismissedAt !== null
                ? () => {}
                : () => this.props.onDismissClick(notification.id)
            }
          />
        </div>
      </div>
    );
  }
}

export default NotificationListItem;
