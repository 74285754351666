import * as React from 'react';
import ConditionTemperature from './ConditionTemperature';
import ConditionDoor from './ConditionDoor';
import styles from './StepCondition.module.css';
import {
  IntervalUnit,
  Parameters,
  SentinelInterval,
  SentinelType,
} from '../../../../types/sentinel';
import StepConditionItemInput from './StepConditionItemInput';
import StepTitle from '../StepTitle';
import FormText from '../../../ui/FormText/FormText';
import TextField from '../../../ui/TextField/TextField';
import StepConditionItem from './StepConditionItem';
import ConditionBatteryLevelCheck from './ConditionBatteryLevelCheck';
import ConditionConnectivity from './ConditionConnectivity';
import ConditionCustomerComfort from './ConditionCustomerComfort';
import { MeasurementSystem } from '../../../../types/shared';
import NativeSelectField from '../../../ui/NativeSelectField';
import ConditionBinaryCounter from './ConditionBinaryCounter';

type DelayConditionProps = {
  onDelayChange: (intervalUnit: IntervalUnit, value: string) => void;
  onUnitChange: (unit: IntervalUnit) => void;
  delay: SentinelInterval;
};

const DelayCondition: React.FunctionComponent<DelayConditionProps> = ({
  delay,
  onDelayChange,
  onUnitChange,
}) => (
  <>
    <StepConditionItem
      label={<FormText>Delay Type:</FormText>}
      condition={
        <NativeSelectField
          onChange={ev => onUnitChange(ev.target.value)}
          value={delay.unit}
          name="delayType"
          ariaLabel="delayType"
        >
          <option value={IntervalUnit.MILLISECOND}>Time</option>
          <option value={IntervalUnit.DEVICEINTERVAL}>Device interval</option>
        </NativeSelectField>
      }
    />

    {delay.unit === IntervalUnit.MILLISECOND ? (
      <StepConditionItem
        label={<FormText>Notify After:</FormText>}
        condition={
          <StepConditionItemInput
            value={
              <TextField
                type="number"
                value={
                  delay.value > 0
                    ? (delay.value / 1000 / 60).toString()
                    : (0).toString()
                }
                onChange={(ev: any) =>
                  onDelayChange(delay.unit, ev.target.value)
                }
              />
            }
            unit={
              <FormText>{`minute${delay.value === 1 ? '' : 's'}`}</FormText>
            }
          />
        }
        description="Specify the time after which the first notification will be generated."
      />
    ) : (
      <StepConditionItem
        label={<FormText>Notify After:</FormText>}
        condition={
          <StepConditionItemInput
            value={
              <TextField
                type="number"
                value={delay.value.toString()}
                onChange={(ev: any) =>
                  onDelayChange(delay.unit, ev.target.value)
                }
              />
            }
            unit={
              <FormText>{`interval${delay.value === 1 ? '' : 's'}`}</FormText>
            }
          />
        }
        description="Specify the interval after which the first notification will be generated."
      />
    )}
  </>
);

type Props = {
  onChange: (arg0: string, arg1: string) => void;
  onDelayChange: (intervalUnit: IntervalUnit, value: string) => void;
  onUnitChange: (unit: IntervalUnit) => void;
  sentinelType: SentinelType;
  parameters?: Parameters;
  delay: SentinelInterval;
  measurementSystem: MeasurementSystem;
  customDelaySentinels: SentinelType[];
};

const getSentinelSpecificCondition = (
  sentinelType,
  parameters,
  onChange,
  measurementSystem
) => {
  switch (sentinelType) {
    case SentinelType.CAR_WASH:
    case SentinelType.TEMPERATURE:
      return (
        <ConditionTemperature
          parameters={parameters}
          onValueChange={onChange}
          onComparisonTypeChange={onChange}
          measurementSystem={measurementSystem}
        />
      );

    case SentinelType.BINARY_COUNTER:
      return (
        <ConditionBinaryCounter parameters={parameters} onChange={onChange} />
      );

    case SentinelType.DOOR:
      return <ConditionDoor parameters={parameters} onChange={onChange} />;

    case SentinelType.CONNECTIVITY:
      return <ConditionConnectivity />;

    case SentinelType.BATTERY_LEVEL_CHECK:
      return <ConditionBatteryLevelCheck />;

    case SentinelType.CUSTOMER_COMFORT:
      return (
        <ConditionCustomerComfort parameters={parameters} onChange={onChange} />
      );

    default:
      return null;
  }
};

const StepCondition = ({
  onChange,
  onDelayChange,
  onUnitChange,
  parameters,
  sentinelType,
  delay,
  measurementSystem,
  customDelaySentinels,
}: Props) => (
  <div>
    <StepTitle>
      Please define the condition for your configuration, as well as the
      reaction time, after which the first notification will be triggered.
    </StepTitle>
    <div className={styles.conditionalsWrapper}>
      {getSentinelSpecificCondition(
        sentinelType,
        parameters,
        onChange,
        measurementSystem
      )}

      {customDelaySentinels.includes(sentinelType) && (
        <DelayCondition
          delay={delay}
          onUnitChange={onUnitChange}
          onDelayChange={onDelayChange}
        />
      )}
    </div>
  </div>
);

export default StepCondition;
