import * as React from 'react';
import { UiIcon } from '../../../types/icon';

const IconSize16 = () => (
  <g>
    <path
      d="M8.71 8l5.15-5.15a.5.5 0 1 0-.71-.71L8 7.29 2.85 2.15a.5.5 0 0 0-.71.71L7.29 8l-5.14 5.15a.5.5 0 1 0 .71.71L8 8.71l5.15 5.15a.5.5 0 0 0 .71-.71z"
      fill="currentColor"
    />
  </g>
);

const IconSize32 = () => (
  <g>
    <path
      d="M16.71 16L28.85 3.85a.5.5 0 1 0-.71-.71L16 15.29 3.85 3.15a.5.5 0 0 0-.71.71L15.29 16 3.15 28.15a.5.5 0 1 0 .71.71L16 16.71l12.15 12.14a.5.5 0 0 0 .71-.71z"
      fill="currentColor"
    />
  </g>
);

const getIconBySize = (size: number) => {
  switch (true) {
    case size <= 16:
      return { viewBox: '0 0 16 16', icon: <IconSize16 /> };

    default:
      return { viewBox: '0 0 32 32', icon: <IconSize32 /> };
  }
};

export default ({ classes = {}, size = 16 }: UiIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
