import * as React from 'react';
import styles from './Selector.module.css';

import InputField from '../InputField';
import Menu from '../../Menu';

import Avatar from '../../Avatar';
import MenuItem from '../../MenuItem';

const defaultState = {
  isLoading: false,
  value: '',
  filteredOptions: [], // result typed something
  selectedIndex: null,
  resultListVisible: false,
};

type SelectorField = string;

type Props = {
  setValue: any;
  selectorField: string;
  fields: SelectorField[];
  selected: any[];
  selectedIds: number[];
  options: any[];
  isLoading: boolean;
  placeholder?: string;
};

type State = {
  isLoading: boolean;
  value: string;
  filteredOptions: any[];
  selectedIndex: number | null;
  resultListVisible: boolean;
};

class Selector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...defaultState,
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const { selectedIds } = this.props;
    this.filterOptionsBySelectedIds(selectedIds);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Props): void {
    const { selected, selectedIds, options, isLoading } = this.props;

    if (
      prevProps.selected.length !== selected.length ||
      prevProps.options.length !== options.length ||
      prevProps.isLoading !== isLoading
    ) {
      this.filterOptionsBySelectedIds(selectedIds);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  filterOptionsBySelectedIds = (selectedIds: number[]) =>
    this.setState({
      filteredOptions: this.props.options.filter(
        item => selectedIds.includes(item.id) === false
      ),
    });

  handleOnFocus = () =>
    this.setState(prevState => ({
      resultListVisible: !prevState.resultListVisible,
    }));

  /* handleUpdateItemBySelectedIndex = () => {
    const { selectedIndex } = this.state;
    const { options } = this.props;
    const result = options.find((u, index) => index === selectedIndex);
    this.setState({
      value: '',
      selectedIndex: null,
    });
    this.updateList(result);
  }; */

  handleUpdateItemById = (entryId: number) => {
    const { options } = this.props;
    const result = options.find(e => e.id === entryId);
    this.updateList(result);
  };

  updateList = (result: any) => {
    if (!result) {
      return;
    }
    this.props.setValue(result);
    this.setState({ value: '', resultListVisible: false });
  };

  handleKeyDown = (ev: any) => {
    const { key } = ev;
    const {
      value,
      // filteredOptions,
      // selectedIndex,
      // resultListVisible,
    } = this.state;
    const { selected } = this.props;

    /* if (resultListVisible && key === 'ArrowDown') {
      ev.preventDefault();
      this.setState({
        selectedIndex:
          selectedIndex === null
            ? 0
            : (selectedIndex + 1) % filteredOptions.length,
      });
      return;
    }

    if (resultListVisible && key === 'ArrowUp') {
      ev.preventDefault();

      this.setState({
        selectedIndex:
          ((selectedIndex || 0) - 1 + filteredOptions.length) %
          filteredOptions.length,
      });
      return;
    }

    if (key === 'Enter') {
      ev.preventDefault();
      this.handleUpdateItemBySelectedIndex();
      return;
    } */

    if (key === 'Backspace') {
      if (selected.length && value === '') {
        const lastElement = selected.pop();

        if (!lastElement) {
          return;
        }

        this.handleUpdateItemById(lastElement.id);
        this.setState({
          resultListVisible: false,
          // selectedIndex: null,
        });
      }
    }
  };

  handleInputChange = (ev: any) => {
    const { value } = ev.target;

    this.setState({
      value,
      resultListVisible: !!value.length,
      filteredOptions: this.props.options
        .filter(item => this.props.selectedIds.includes(item.id) === false)
        .filter(item =>
          item[this.props.selectorField]
            .toLowerCase()
            .includes(value.toLowerCase())
        ),
    });
  };

  wrapperRef: any;

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState(() => ({ resultListVisible: false, value: '' }));
    }
  };

  render() {
    const {
      value,
      // selectedIndex,
      filteredOptions,
      resultListVisible,
    } = this.state;
    const { selectorField, selected, placeholder = '' } = this.props;

    const filteredMenuItems = filteredOptions.map(item => (
      <MenuItem
        key={`filtered${item.id}`}
        // isActive={selectedIndex === index}
        // onMouseOver={() => this.setState({ selectedIndex: index })}
        onClick={() => this.handleUpdateItemById(item.id)}
      >
        <div className={styles.item}>
          <div className={styles.avatar}>
            <Avatar>{item.shortTitle}</Avatar>
          </div>
          {item[selectorField]}
        </div>
      </MenuItem>
    ));

    return (
      <div ref={this.wrapperRef} className={styles.root}>
        <div
          className={`${styles.selectField} ${
            resultListVisible ? styles.selectFieldFocus : ''
          }`}
        >
          {selected.map(item => (
            <span
              className={styles.selectFieldResultItem}
              key={`selectedItem${item.id}`}
              onClick={() => this.handleUpdateItemById(item.id)}
            >
              {item[selectorField]}
            </span>
          ))}

          <InputField
            value={value}
            onChange={this.handleInputChange}
            onKeyDown={this.handleKeyDown}
            onFocus={this.handleOnFocus}
            placeholder={placeholder}
            extraClassName={styles.selectFieldInput}
          />
        </div>

        {resultListVisible && (
          <Menu>
            {filteredMenuItems.length ? (
              filteredMenuItems
            ) : (
              <MenuItem>No results found</MenuItem>
            )}
          </Menu>
        )}
      </div>
    );
  }
}

export default Selector;
