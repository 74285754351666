import * as React from 'react';
import { SensorIcon } from '../../../types/icon';

const IconSize80 = () => (
  <g fill="currentColor">
    <path
      d="m75 66.3c.7-1.4 1-3.1 1-4.8 0-2-.5-3.9-1.4-5.5-.7-1.3-1.7-2.5-2.8-3.4-.3-.2-.6-.5-.9-.7-.6-.4-1.3-.8-2-1.1h-.1c0-.3.1-.6.1-.9-.3-.1-.5-.2-.8-.3v-.5c0-.5.1-1.1.1-1.7 0-.1 0-.3 0-.4h.8v1c0 .6.4 1 1 1s1-.4 1-1v-38c0-.6-.4-1-1-1s-1 .4-1 1v35h-.8c-.9-10.3-7.9-19-17.9-22.1l-.3-.1c0-.3 0-.6.1-.8-2.3-.6-4.6-1-7.1-1s-4.8.4-7.1 1c0 .3 0 .5.1.8l-.2.1c-10.6 3.2-18 13.3-18 24.5 0 .7 0 1.3.1 2v.2c-.3.1-.5.2-.8.3.7 7.1 4.1 13.4 9.2 17.7.2-.2.4-.3.7-.5l.2.2c2.4 2 5.2 3.4 8.1 4.4.3.1.7.2 1 .3.2.1.4.1.5.2.6.2 1.2.3 1.8.4.1 0 .2 0 .4.1.6.1 1.2.2 1.9.2h.3c.6.1 1.3.1 2 .1.6 0 1.3 0 1.9-.1h.5c.5-.1 1-.1 1.6-.2.3 0 .6-.1.9-.2.1 0 .3-.1.4-.1 2.7-.6 5.3-1.6 7.7-3.1.2.3.5.5.8.8l.2.2c.2.2.4.3.6.4.1.1.2.1.3.2.2.1.3.2.5.3.1.1.3.2.4.2.2.1.3.2.5.3s.3.1.5.2.3.1.5.2.4.1.5.2c.2.1.3.1.5.2s.4.1.6.2c.1 0 .3.1.4.1.2 0 .5.1.7.1.1 0 .2 0 .4.1.4 0 .7.1 1.1.1s.8 0 1.2-.1h.3c.3 0 .6-.1.9-.1.1 0 .2-.1.3-.1.3-.1.6-.1.9-.2.1 0 .1-.1.2-.1l.9-.3c.1 0 .1 0 .2-.1.3-.1.6-.3.9-.5h.1l.9-.6c1.3-1.4 2.6-2.9 3.5-4.7z"
      opacity=".1"
    />
    <path d="m29 46c0 .6.4 1 1 1h15c.6 0 1-.4 1-1v-5c0-.6-.4-1-1-1h-15c-.6 0-1 .4-1 1v1h-1v3h1zm1-5h15v5h-15v-1.6-1.7z" />
    <path d="m62.2 43.6v-.5c0-.5.1-1.1.1-1.7 0-.1 0-.3 0-.4h.7v1c0 .6.4 1 1 1s1-.4 1-1v-38c0-.6-.4-1-1-1s-1 .4-1 1v35h-.8c-.9-10.3-7.9-19-17.9-22.1l-.3-.1c0-.3 0-.6.1-.8-2.3-.6-4.6-1-7.1-1s-4.8.4-7.1 1c0 .3 0 .5.1.8l-.2.1c-10.6 3.2-18 13.3-18 24.5 0 .7 0 1.3.1 2v.2c-.3.1-.5.2-.8.3.7 7.1 4.1 13.4 9.2 17.7.2-.2.4-.3.7-.5l.2.2c4.4 3.7 10 5.7 15.8 5.7 4.9 0 9.6-1.4 13.7-4.1 2.1 1.9 4.8 3.1 7.8 3.1 6.4 0 11.5-5.1 11.5-11.5 0-5.1-3.3-9.3-7.8-10.9zm-1.7-2.2c0 .5 0 1.1-.1 1.6-9.6-3.9-16.3-13.2-16.4-24.2 9.7 3 16.5 12.3 16.5 22.6zm-47 0c0-10.3 6.8-19.6 16.5-22.6-.1 11-6.9 20.4-16.4 24.2 0-.5-.1-1.1-.1-1.6zm-1.4 3.2c11.2-3.5 18.9-14.2 18.9-26.1 0-.6 0-1.1-.1-1.8 2-.5 4-.8 6-.8s4 .3 6 .8c0 .6-.1 1.2-.1 1.8 0 10.5 6 19.9 15 24.5-6.1.3-11 5.3-11 11.5 0 .7.1 1.4.2 2-3.2-1.3-6.7-2-10.2-2-6.1 0-12 2.1-16.7 5.9-4.4-4.2-7.3-9.7-8-15.8zm48.1 3.4-.4 8h-2.6l-.4-8zm-37.7 12c4.2-2.8 9.2-4.5 14.5-4.5 3.7 0 7.3.8 10.5 2.2.4 1.4 1.1 2.7 1.9 3.8-3.7 2.4-7.9 3.6-12.4 3.6-5.3 0-10.4-1.8-14.5-5.1zm37.2.5c-.3.3-.7.5-1.2.5s-.9-.2-1.2-.5-.5-.8-.5-1.2c0-.5.2-.9.5-1.2s.8-.5 1.2-.5.9.2 1.2.5.5.8.5 1.2c.1.4-.1.9-.5 1.2z" />
  </g>
);

const getIconBySize = () => {
  switch (true) {
    default:
      return { viewBox: '0 0 80 80', icon: <IconSize80 /> };
  }
};

export default ({ classes = {}, size = 80 }: SensorIcon) => {
  const { viewBox, icon } = getIconBySize();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
