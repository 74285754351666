/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */

import * as React from 'react';
import styles from './ListContainer.module.css';
import classNames from '../../../utils/classNames';

type Props = {
  children: React.ReactNode;
  editMode?: boolean;
  disabled?: boolean;
};

const ListContainer = ({
  children,
  editMode = false,
  disabled = false,
}: Props) => (
  <div
    className={classNames(
      styles.container,
      editMode ? undefined : styles.listMode,
      disabled ? styles.disabled : undefined
    )}
  >
    {children}
  </div>
);

export default ListContainer;
