import * as React from 'react';
import StepConditionItem, { StepConditionTitle } from './StepConditionItem';
import classNames from '../../../../utils/classNames';
import styles from './ConditionDoor.module.css';
import IconGatewayFactory from '../../../icons/IconGatewayFactory';

const ConditionConnectivity: React.FunctionComponent = () => (
  <React.Fragment>
    <StepConditionItem
      label={
        <span className={classNames(styles.icon)}>
          <IconGatewayFactory size={80} id="offline" />
        </span>
      }
      condition={
        <div>
          <StepConditionTitle>Inactive Device</StepConditionTitle>
          <p>The device may have stopped sending signals</p>
        </div>
      }
      conditionAlignedCenter
    />
  </React.Fragment>
);

export default ConditionConnectivity;
